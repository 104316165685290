/* eslint-disable no-undef */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from 'lib/Helper';

import {
    getGameMDRecommend,
    getGameNewGameBox,
    getGameOfficalBox,
    getGameNewsBox,
    getGameReviewBox,
    getGameTopBannerBox,
    getGameFootBannerBox,
} from 'store/Actions';

import {
    DefaultPagingPhotoBox,
    GameNewGamePhotoBox,
    GameOfficalBoxPhotoBox,
    GameGameNewsPhotoBox,
    GameReviewBox,
    // DefaultBannerBox,
    GameBannerBox,
    GameFootBannerBox,
    InterWorksMediaBannerGame,
    MobionBanner
} from 'elements';


export class GameComponent extends Component {


    constructor(props) {

        super(props);

        this.state = {
            game_md_box_page : 1,
            game_md_box_total_page : 0,
        };
    }

    _getGameMDRecommend = () => {
        this.props.getGameMDRecommend();
    }

    _getGameNewGameBox = () => {
        this.props.getGameNewGameBox();
    }

    _getGameOfficalBox = () => {
        this.props.getGameOfficalBox();
    }

    _getGameNewsBox = () => {
        this.props.getGameNewsBox();
    }

    _getGameReviewBox = () => {
        this.props.getGameReviewBox();
    }

    _getGameTopBannerBox = () => {
        this.props.getGameTopBannerBox();
    }

    _getGameFootBannerBox = () => {
        this.props.getGameFootBannerBox();
    }

    _getGameBannerOne = () => {

        var obj = $("#gameBanner01"),
        obj_width = obj.width(),
        obj_height = obj_width * 0.3125;
        obj.css({'width':'100%', 'height':obj_height});

        var gameBanner01 = new ASUMSDK();
        var gameBanner01conf = {
            targetId : 'gameBanner01',
            adUnitId : 'ef89d32b-513f-4b5b-902f-1cb79bd6b50e',//ef89d32b-513f-4b5b-902f-1cb79bd6b50e
            callback : {
                onAdLoaded : function(isDefaultAd){
                    //    if(isDefaultAd==true){
                    //     $("#subnewsBanner01").css('height','auto');
                    //     $("#subnewsBanner01").html('<a href="https://stockletter.kr/event/register01?utm_source=asum&utm_medium=banner&utm_campaign=noad" target="_blank"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
                    // }
                  
                },
                onAdFailed : function(error){
                    $("#gameBanner01").css('height','auto');
                    //$("#gameBanner01").html('<a href="https://stockletter.kr/event/register04" target="_blank"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
                },
    
                onAdClicked : function(url){
                        return false;
                },
            }
        };

       gameBanner01.loadAd(gameBanner01conf);

                 
    }

    startPage = () => {
        this._getGameMDRecommend();
        this._getGameNewGameBox();
        this._getGameOfficalBox();
        this._getGameNewsBox();
        this._getGameReviewBox();

        this._getGameTopBannerBox();
        this._getGameFootBannerBox();

        //banner
        this._getGameBannerOne();

    }

    _handleClickGameMdPageing = (e) => {

        let nowPage = this.state.game_md_box_page;
        let pageValue;
        const pageType = e.target.getAttribute('page_type');

        if(pageType === '-') {

            if(nowPage-1 === 0) {
                pageValue = this.state.game_md_box_total_page;
            } else {
                pageValue = nowPage-1;
            }
            this.setState({
                game_md_box_page: pageValue
            });

        } else if(pageType === '+') {

            if(nowPage+1 > this.state.game_md_box_total_page) {
                pageValue = 1;
            } else {
                pageValue = nowPage+1;
            }
            this.setState({
                game_md_box_page: pageValue
            });
        }


    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.topMDRecommend.state === true && this.props.topMDRecommend.payload !== nextProps.topMDRecommend.payload) {
            const mdPayloadCount = nextProps.topMDRecommend.payload.length;

            let totalPage = Helper.getTotalPage((mdPayloadCount - 1), 4);

            this.setState({
                game_md_box_total_page : totalPage
            });
        }
    }

    componentDidMount() {
        this.startPage();
    }

    render() {
        return (
            <div id="wrap">

                <div id="container">
                    <div id="gameBanner01" style={{position:'relative'}}>
                            <MobionBanner
                                BANNER_TYPE="NEWS_TOP"
                            />
                    </div>
                       {/* <GameBannerBox
                        CONTENTS_LIST={this.props.topBannerBox}
                        /> */}
                    <section className="linkList">
                        <h2 className="subTitle1">게임MD추천 <span>직접해본 게임만 추천!</span></h2>
                        <div className="gridType">
                            <ul>
                                <DefaultPagingPhotoBox
                                    CONTENTS_LIST={this.props.topMDRecommend}
                                    CONTENTS_PAGE={this.state.game_md_box_page}
                                />
                            </ul>
                        </div>
                        <div className="listNav">
                            <button type="button" className="btnPrev" onClick={this._handleClickGameMdPageing}
                                    page_type="-">이전
                            </button>
                            <span
                                className="pagination"><strong>{this.state.game_md_box_page}</strong> /<span>{this.state.game_md_box_total_page}</span></span>
                            <button type="button" className="btnNext" onClick={this._handleClickGameMdPageing}
                                    page_type="+">다음
                            </button>
                        </div>
                    </section>

                    <GameBannerBox
                        CONTENTS_LIST={this.props.topBannerBox}
                    />

                    <section className="linkList">
                        <h2 className="subTitle1">아직도 현질하니? <span>사전예약하고 아이템 핵이득!</span></h2>
                        <ul className="gameList">
                            <GameNewGamePhotoBox
                                CONTENTS_LIST={this.props.newGameBox}
                            />
                        </ul>
                    </section>

                    <section className="linkList">
                        <h2 className="subTitle1">오피셜! <span>게임사에서 전해듣는 따끈한 소식!</span></h2>
                        <GameOfficalBoxPhotoBox
                            CONTENTS_LIST={this.props.officalBox}
                        />
                    </section>

                    <section className="linkList">
                        <h2 className="subTitle1">게임 NEWS</h2>
                        <ul className="gameList3">
                            <GameGameNewsPhotoBox
                                CONTENTS_LIST={this.props.newsBox}
                            />
                        </ul>
                    </section>

                    <div className="banner">
                        <InterWorksMediaBannerGame />
                        
                    </div>
                    {/*
                    <GameBannerBox
                        CONTENTS_LIST={this.props.topBannerBox}
                    />
*/}
                    <section className="linkList">
                        <h2 className="subTitle1">리뷰 보러 왔습니다.</h2>
                        <ul className="txtType">
                            <GameReviewBox
                                CONTENTS_LIST={this.props.reviewBox}
                            />
                            <GameFootBannerBox
                                CONTENTS_LIST={this.props.footBannerBox}
                            />
                        </ul>
                    </section>


                </div>

            </div>
        );
    }
};


GameComponent.defaultProps = {
    topMDRecommend: {
        state: false,
        payload : {}
    },
    newGameBox: {
        state: false,
        payload : {}
    },
    officalBox: {
        state: false,
        payload : {}
    },
    newsBox: {
        state: false,
        payload : {}
    },
    reviewBox: {
        state: false,
        payload : {}
    },
    topBannerBox: {
        state: false,
        payload : {}
    },
    footBannerBox: {
        state: false,
        payload : {}
    },
};

const mapStateToProps = state => ({
    topMDRecommend: state.GAME.topMDRecommend,
    newGameBox: state.GAME.newGameBox,
    officalBox: state.GAME.officalBox,
    newsBox: state.GAME.newsBox,
    reviewBox: state.GAME.reviewBox,
    topBannerBox: state.GAME.topBannerBox,
    footBannerBox: state.GAME.footBannerBox,
});

const mapDispatchToProps = {
    getGameMDRecommend,
    getGameNewGameBox,
    getGameOfficalBox,
    getGameNewsBox,
    getGameReviewBox,
    getGameTopBannerBox,
    getGameFootBannerBox,
};



export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(GameComponent));
