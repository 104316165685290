import React from "react";
import { Link } from "react-router-dom";
import * as Helper from 'lib/Helper';
import * as GlobalLink from 'lib/GlobalLink'
import styled from 'styled-components';

export const DefaultPhotoBox = ({
CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }
    // const txt2AddStyle = {
    //     '-webkit-box-orient': 'vertical',
    // }
    return (
        <div>
            {
                ( CONTENTS_LIST.state === true ) && CONTENTS_LIST.payload.map((item, i) => {
                    return (
                        <li key={i}>
                            <Link to={{ pathname: '/news/content/'+item.sid }} >
                                <div>
                                    <span className="thumb"><img src={item.img_path} alt="" style={{height: '60px'}}/></span>
                                    <span className="txt2">{item.title}</span>
                                </div>
                            </Link>
                        </li>
                    );
                })
            }
        </div>
    );
};

export const DefaultPagingPhotoBox = ({
    CONTENTS_LIST,
    CONTENTS_PAGE
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    const CutText = styled.span`
    -webkit-box-orient: vertical;
    `;

    if(CONTENTS_LIST.state === true) {
        const content_list = Helper.paginate(CONTENTS_LIST.payload, 4, CONTENTS_PAGE);
        return (
            <div>
                {
                    content_list.map((item, i) => {
                        return (
                            <ul key={i}>
                                <li>
                                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}  }>
                                        <span className="thumb"><img src={item.img_path} alt="" style={{width:'100%'}}/></span>
                                        <CutText className="txt">{item.title}</CutText>
                                    </Link>
                                </li>
                            </ul>
                        );
                    })
                }
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};


export const ShoppingBestReview = ({
    CONTENTS_LIST
}) => {
    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    const renderItem = [];

    if(CONTENTS_LIST.state === true) {

        // for(var i=0;i<=1;i++) {
        //     renderItem.push(
        //         <li key={i}>
        //             <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(CONTENTS_LIST.payload[i].land_url))}}>
        //                 <span className="thumb"><img src={CONTENTS_LIST.payload[i].img_path} alt=""/></span>
        //                 <span className="txt"><span className="ftColor1">{CONTENTS_LIST.payload[i].title_1}</span>{CONTENTS_LIST.payload[i].title_2}</span>
        //             </Link>
        //         </li>
        //     );
        // }
        //
        // return (
        //     <div>
        //         {renderItem}
        //     </div>
        // );

        return (
            <div>
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        if(i <= 1) {
                            return (
                                <li key={i}>
                                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}}>
                                        <span className="thumb"><img src={item.img_path} alt=""/></span>
                                        <span className="txt"><span className="ftColor1">{item.title_1}</span>{item.title_2}</span>
                                    </Link>
                                </li>
                            );
                        } else {
                            return ('');
                        }
                    })

                }
            </div>
        );


    } else {
        return (
            <div>
                {renderItem}
            </div>
        );
    }
};



// export const DefaultTrendPhotoBox = ({
// CONTENTS_LIST
// }) => {
//     const _handleContentLinkClick = (contents_link) => {
//         GlobalLink.SiteLogCheckURL({
//             url:contents_link
//         });
//     }
//
//     if(CONTENTS_LIST.state === true) {
//
//         return (
//             <div>
//                 {
//                     CONTENTS_LIST.payload.map((item, i) => {
//                         return (
//                             <li key={i}>
//                                 <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}}>
//                                     <span className="thumb"><img src={item.img_path} alt=""/></span>
//                                     <span className="txt"><b>{item.title_b}</b>{item.title_s}</span>
//                                 </Link>
//                             </li>
//                         );
//                     })
//                 }
//             </div>
//         );
//     } else {
//         return (
//             <div>
//
//             </div>
//         );
//     }
// };




export const DefaultTrendPhotoBox = ({
    CONTENTS_LIST
}) => {
    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    let renderItem1 = [];
    let renderItem2 = [];
    let subTitle = '';


    if(CONTENTS_LIST.state === true) {
        for(let tmp_i=0;tmp_i<CONTENTS_LIST.payload.length;tmp_i++) {
            if( tmp_i<=1 ) {
                renderItem1.push(
                    <li key={tmp_i}>
                        <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(CONTENTS_LIST.payload[tmp_i].land_url))}}>
                            <span className="thumb"><img src={CONTENTS_LIST.payload[tmp_i].img_path} alt=""/></span>
                            {/*<span className="txt">{CONTENTS_LIST.payload[tmp_i].title_s}</span>*/}
                            <span className="txt">{CONTENTS_LIST.payload[tmp_i].title_1}</span>
                        </Link>
                    </li>
                );
            } else {
                subTitle= {
                    title_b:CONTENTS_LIST.payload[tmp_i].title_b,
                    title_s:CONTENTS_LIST.payload[tmp_i].title_s
                };
                renderItem2.push(
                    <li key={tmp_i}>
                        <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(CONTENTS_LIST.payload[tmp_i].land_url))}}>
                            <span className="thumb"><img src={CONTENTS_LIST.payload[tmp_i].img_path} alt=""/></span>
                            <span className="txt">{CONTENTS_LIST.payload[tmp_i].title_1}</span>
                            <span className="price"><del>{Helper.numberWithCommas(CONTENTS_LIST.payload[tmp_i].price_sale)}원</del>&nbsp;<b>{Helper.numberWithCommas(CONTENTS_LIST.payload[tmp_i].price_origin)}원</b></span>
                        </Link>
                    </li>
                );
            }
        }

        return (
            <section className="linkList">
                <h2 className="subTitle1">{subTitle.title_b} <span>{subTitle.title_s}</span></h2>
                <div className="gridType">
                    <ul>
                        {renderItem1}
                    </ul>
                </div>
                <ul className="shopType">
                    {renderItem2}
                </ul>
            </section>
        );

    } else {
        return (
            <div>

            </div>
        );
    }
};





// export const DefaultTrendSmallPhotoBox = ({
// CONTENTS_LIST
// }) => {
//
//     const _handleContentLinkClick = (contents_link) => {
//         GlobalLink.SiteLogCheckURL({
//             url:contents_link
//         });
//     }
//
//     if(CONTENTS_LIST.state === true) {
//
//         return (
//             <div>
//                 {
//                     CONTENTS_LIST.payload.map((item, i) => {
//                         return (
//                             <li key={i}>
//                                 <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}}>
//                                     <span className="thumb"><img src={item.img_path} alt=""/></span>
//                                     <span className="txt">{item.title_s}</span>
//                                 </Link>
//                             </li>
//                         );
//                     })
//                 }
//             </div>
//         );
//
//     } else {
//         return (
//             <div>
//
//             </div>
//         );
//     }
// };


export const DefaultTrendSmallPhotoBox = ({
                                              CONTENTS_LIST
                                          }) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    // let renderItem = [];
    // let subTitle = [];

    if(CONTENTS_LIST.state === true) {

        const subTitle = CONTENTS_LIST.payload.map((item, i) => {

            return {
                title_b:item.title_b,
                title_s:item.title_s,

            }

        })

        return (
            <section className="linkList" style={{margin:'0 0 1rem'}}>
                <h2 className="subTitle1">{subTitle.title_b}<span>{subTitle.title_s}</span></h2>
                <div>
                    <ul className="photoAlb">
                        {
                            CONTENTS_LIST.payload.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}}>
                                            <span className="thumb"><img src={item.img_path} alt=""/></span>
                                            <span className="txt">{item.title_1}<span style={{fontWeight:'bold'}}>{item.tag}</span></span>
                                        </Link>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </section>
        );

    } else {
        return (
            <div>

            </div>
        );
    }
};



export const TodayTrendBigTypeBox = ({
    CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {
        const contentsItem = CONTENTS_LIST.payload;
        const renderItem = [];

        for(var i=0; i<3; i++) {
            let tItem = contentsItem[i];

            if(i === 0) {
                renderItem.push(
                    <li key={i}>
                        <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(tItem.land_url))}}>
                            <span className="thumb"><img src={tItem.img_path} alt=""/></span>
                            <span className="txt"><b>{tItem.title_1}</b>{tItem.title_2}</span>
                        </Link>
                    </li>
                )
            } else {
                renderItem.push(
                    <li key={i}>
                        <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(tItem.land_url))}}>
                            <span className="thumb"><img src={tItem.img_path} alt=""/></span>
                            <span className="txt"><b>{tItem.tag}</b></span>
                        </Link>
                    </li>
                )
            }

        }

        return (
            <div>
                {renderItem}
            </div>
        );
    } else {
        return (
            <div>

            </div>
        )
    }




}

export const TodayTrendSmallTypeBox = ({
    CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {
        const contentsItem = CONTENTS_LIST.payload;
        const renderItem = [];

        for(var i=3; i<6; i++) {
            let tItem = contentsItem[i];
            renderItem.push(
                <li key={i}>
                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(tItem.land_url))}}>
                        <span className="thumb"><img src={tItem.img_path} alt=""/></span>
                        <span className="txt">{tItem.title_2}</span>
                    </Link>
                </li>
            )
        }

        return (
            <div>
                {renderItem}
            </div>
        );
    } else {
        return (
            <div>

            </div>
        )
    }

}


export const NewsHeadLinePhotoBox = ({
CONTENTS_LIST
}) => {

    const thumbStyle = {
        // maxHeight: '62px',
        // marginLeft: 'auto',
        // display: 'block',
        // marginRight: 'auto',
        // paddingTop: '10px',
        width: '111px',
        height: '83px',
    };

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {

        return (
            CONTENTS_LIST.payload.map((item, i) => {
                return (
                    <li key={i}>
                        <Link to={{ pathname: '/news/content/'+item.sid }} >
                            <span className="thumb"><img src={item.img_path} style={thumbStyle} alt=""/></span>
                            <span className="source"><img src={item.provider_icon} alt="" style={{maxWidth:'77px', maxHeight:'14px'}}/></span>
                            <span className="tit">{item.title}</span>
                            <span className="opt">
                                <span className="left">{item.pubdate}</span>
                                {/* <span className="right">
                                    <span>댓글 200</span>|<span>좋아요 10</span>
                                </span> */}
                            </span>
                        </Link>
                    </li>
                );
            })
        );
    } else {
        return (
            <div>

            </div>
        );
    }

};

export const ShoppingMdPagingPhotoBox = ({
    CONTENTS_LIST,
    CONTENTS_PAGE
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {
        let content_list = Helper.paginate(CONTENTS_LIST.payload, 6, CONTENTS_PAGE);
        if(CONTENTS_LIST.payload.length < 7) {
            content_list = CONTENTS_LIST.payload;
        } else {
            content_list = Helper.paginate(CONTENTS_LIST.payload, 6, CONTENTS_PAGE);
        }
        return (
            <ul className="photoAlb">
                {
                    content_list.map((item, i) => {
                        return (
                            <li key={i}>
                                <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}}>
                                    <span className="thumb"><img src={item.img_path} alt=""/></span>
                                    <span className="txt">{item.title_1}</span>
                                </Link>
                            </li>
                        );
                    })
                }
            </ul>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};

export const ShoppingListPhotoBox = ({
    CONTENTS_LIST,
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {
        return (
            <ul className="itemLeng3">
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        return (
                            <li key={i}>
                                <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}}>
                                    <span className="thumb"><img src={item.img_path} alt=""/></span>
                                    <span className="txt">{item.title_1}</span>
                                    <span className="price"><del>{item.price_origin}원</del><b>{item.price_sale}원</b></span>
                                </Link>
                            </li>
                        );
                    })
                }
            </ul>
        );
    } else {
        return (
            <ul className="itemLeng3">

        </ul>
        );
    }
};

export const ShoppingScorePhotoBox = ({
    CONTENTS_LIST,
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {
        return (
            <div>
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        return (
                            <li key={i}>
                                <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}}>
                                    <span className="icon"><img src={item.img_path} alt=""/></span>
                                    <span className="name">{item.title_1}<span className="make">{null}</span></span>
                                    {/* <span className="score1">1점</span> */}
                                </Link>
                            </li>
                        );
                    })
                }
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};

export const GameNewGamePhotoBox = ({
    CONTENTS_LIST,
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {
        return (
            <div>
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        return (
                            <li key={i}>
                                <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}}>
                                    <span className="img"><img src={item.img_path} alt=""/></span>
                                    <span className="name"><span className="state">사전예약</span>{item.title}</span>
                                    {/* <span className="opt"><span className="date">{item.s_date} ~ {item.e_date}</span><span className="device">PS4</span></span> */}
                                    <span className="opt"><span className="device">{item.summary}</span></span>
                                </Link>
                            </li>
                        );
                    })
                }
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};

export const GameOfficalBoxPhotoBox = ({
    CONTENTS_LIST,
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {
        return (
            <ul className="gameList2">
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        return (
                            <li key={i}>
                                <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}  }>
                                    <span className="img"><img src={item.img_path} alt=""/></span>
                                    <span className="tit">{item.title}</span>
                                    <span className="txt">{item.summary}</span>
                                </Link>
                            </li>
                        );
                    })
                }
            </ul>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};

export const GameGameNewsPhotoBox = ({
    CONTENTS_LIST,
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {
        return (
            <div>
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        return (
                            <li key={i}>
                                <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}  }>
                                    <span className="img"><img src={item.img_path} alt=""/></span>
                                    <span className="tit">{item.title}</span>
                                    <span className="txt">{item.summary}</span>
                                    {/* <span className="opt"><span className="reply">댓글: 7</span><span>업계동향</span><span>서진수기자</span><span className="date">2019.04.16</span></span> */}
                                </Link>
                            </li>
                        );
                    })
                }
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};

export const NewsSubNewsListPhotoBox = ({
    CONTENTS_LIST,
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {
        return (
            <div>
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        return (
                            <li key={i}>
                                <Link to={{ pathname: '/news/content/'+item.sid }} >
                                    <span className="thumb"><img src={item.img_path} alt=""/></span>
                                    <span className="txt">{item.title}</span>
                                    <span className="opt">{item.provider_company} ㅣ {item.pubdate} </span>
                                </Link>
                            </li>
                        );
                    })
                }
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};

export const NewsSubNewsContentBox = ({
    CONTENTS_LIST,
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }
    if(CONTENTS_LIST.state === true) {
        return (
            <div>
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        let ori_description = item.description;
                        let description = ori_description.replace(/(<([^>]+)>)/ig,"");


                        if(item.img_path){
                            return (
                                <li key={i}>
                                        <span className="thumb"><img src={item.img_path} alt=""/></span>
                                        <span className="txt">{item.title}</span>
                                        <span className="opt">{item.pubdate} </span>
                                        <span className="content">{description}</span>
                                        <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.link))}  }><span className="url">본문보기 ></span></Link>
                                    
                                </li>
                            );
                        } else {
                            return (
                                <li key={i}>
                                        <span className="txt">{item.title}</span>
                                        <span className="opt">{item.pubdate} </span>
                                        <span className="content">{description}</span>
                                        <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.link))}  }><span className="url">본문보기 ></span></Link>
                                    
                                </li>
                            );
                        }
                        // return (
                        //     <li key={i}>
                        //             <span className="thumb"><img src={item.img_path} alt=""/></span>
                        //             <span className="txt">{item.title}</span>
                        //             <span className="opt">{item.pubdate} </span>
                        //             <span className="content">{description}</span>
                        //             <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.link))}  }><span className="url">본문보기 ></span></Link>
                                
                        //     </li>
                        // );
                    })
                }
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};



export const DefaultPagingPhotoBoxGameN = ({
    CONTENTS_LIST,
    CONTENTS_PAGE
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    const CutText = styled.span`
    -webkit-box-orient: vertical;
    `;

    if(CONTENTS_LIST.state === true) {

        const content_list = Helper.paginate(CONTENTS_LIST.payload, 4, CONTENTS_PAGE);
        return (
            <div>
                {
                    content_list.map((item, i) => {
                        return (
                            <ul key={i}>
                                <li>
                                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.link_url))}  }>
                                        <span className="thumb"><img src={item.thumb_main_url} alt="" style={{width:'100%'}}/></span>
                                        <span className="txt_gamen">{item.title}</span>
                                        <span className="opt_gamen">{item.genre}</span>
                                    </Link>
                                </li>
                            </ul>
                        );
                    })
                }
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};


