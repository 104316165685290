export const SHOW_LOADING_ACTION = 'WWWTPAGECOKR/SHOW_LOADING_ACTION';
export const HIDE_LOADING_ACTION = 'WWWTPAGECOKR/HIDE_LOADING_ACTION';

// ----------------------------------------------------------------------------------------------------------------
export const GET_BROWSER_GEOLOCATION = 'map/GET_BROWSER_GEOLOCATION';
export const SUCCEEDED_BROWSER_GEOLOCATION = 'map/SUCCEEDED_BROWSER_GEOLOCATION';
export const FAILED_BROWSER_GEOLOCATION = 'map/FAILED_BROWSER_GEOLOCATION';
//----------------------------------------------------------------------------------------------------------------

export const GET_GOOGLE_PLACE_LIST = 'map/GET_GOOGLE_PLACE_LIST';
export const SUCCEEDED_GOOGLE_PLACE_LIST = 'map/SUCCEEDED_GOOGLE_PLACE_LIST';
export const FAILED_GOOGLE_PLACE_LIST = 'map/FAILED_GOOGLE_PLACE_LIST';

// --------------------------------------------------------------------------------------------------------------------------------------------
export const GET_FEED_TOP_KEYWORD_LIST = 'feed/GET_FEED_TOP_KEYWORD_LIST';
export const SUCCEEDED_FEED_TOP_KEYWORD_LIST = 'feed/SUCCEEDED_FEED_TOP_KEYWORD_LIST';
export const FAILED_FEED_TOP_KEYWORD_LIST = 'feed/FAILED_FEED_TOP_KEYWORD_LIST';

/* new work */
/* 인증번호 */
//발송----------------------------------------------------------------------------------------
export const TRY_CERTIFICATION = 'certification/TRY_CERTIFICATION';
export const SUCCEEDED_CERTIFICATION = 'certification/SUCCEEDED_CERTIFICATION';
export const FAILED_CERTIFICATION = 'certification/FAILED_CERTIFICATION';
//확인----------------------------------------------------------------------------------------
export const TRY_CONFIRM = 'confirm/TRY_CONFIRM';
export const SUCCEEDED_CONFIRM = 'confirm/SUCCEEDED_CONFIRM';
export const FAILED_CONFIRM = 'confirm/FAILED_CONFIRM';
/* 인증번호 end */
/* 전화번호 로그서치 */
//----------------------------------------------------------------------------------------
export const GET_LOG_PHONE = 'log/GET_LOG_PHONE';
export const SUCCEEDED_LOG_PHONE = 'log/SUCCEEDED_LOG_PHONE';
export const FAILED_LOG_PHONE = 'log/FAILED_LOG_PHONE';
/* 전화번호 로그서치 end */
/* 브라우저 토큰발급 */
//----------------------------------------------------------------------------------------
export const GET_BROWSER_TOKEN = 'token/GET_BROWSER_TOKEN';
export const SUCCEEDED_BROWSER_TOKEN = 'token/SUCCEEDED_BROWSER_TOKEN';
export const FAILED_BROWSER_TOKEN = 'token/FAILED_BROWSER_TOKEN';
/* 브라우저 토큰발급 end */
/* 평범한 회원가입 */
//----------------------------------------------------------------------------------------
export const TRY_DEFAULT_JOIN_MEMBER = 'auth/TRY_DEFAULT_JOIN_MEMBER';
export const SUCCEEDED_DEFAULT_JOIN_MEMBER = 'auth/SUCCEEDED_DEFAULT_JOIN_MEMBER';
export const FAILED_DEFAULT_JOIN_MEMBER = 'auth/FAILED_DEFAULT_JOIN_MEMBER';
/* 평범한 회원가입 end */
/* 회원가입 */
//----------------------------------------------------------------------------------------
export const TRY_JOIN_MEMBER = 'auth/TRY_JOIN_MEMBER';
export const SUCCEEDED_JOIN_MEMBER = 'auth/SUCCEEDED_JOIN_MEMBER';
export const FAILED_JOIN_MEMBER = 'auth/FAILED_JOIN_MEMBER';
/* 회원가입 end */
/* 회원탈퇴 */
//----------------------------------------------------------------------------------------
export const TRY_WITHDRAWAL = 'auth/TRY_WITHDRAWAL';
export const SUCCEEDED_WITHDRAWAL = 'auth/SUCCEEDED_WITHDRAWAL';
export const FAILED_WITHDRAWAL = 'auth/FAILED_WITHDRAWAL';
/* 회원탈퇴 end */
/* 비밀번호 변경 mypage */
//----------------------------------------------------------------------------------------
export const TRY_USER_CHANGE_PW = 'auth/TRY_USER_CHANGE_PW';
export const SUCCEEDED_USER_CHANGE_PW = 'auth/SUCCEEDED_USER_CHANGE_PW';
export const FAILED_USER_CHANGE_PW = 'auth/FAILED_USER_CHANGE_PW';
/* 비밀번호 변경 end */
/* 비밀번호 변경 findpw */
//----------------------------------------------------------------------------------------
export const TRY_DEFAULT_CHANGE_PW = 'auth/TRY_DEFAULT_CHANGE_PW';
export const SUCCEEDED_DEFAULT_CHANGE_PW = 'auth/SUCCEEDED_DEFAULT_CHANGE_PW';
export const FAILED_DEFAULT_CHANGE_PW = 'auth/FAILED_DEFAULT_CHANGE_PW';
/* 비밀번호 변경 end */
/* 비밀번호 체크 */
//----------------------------------------------------------------------------------------
export const TRY_PASSWORD_CHECK = 'auth/TRY_PASSWORD_CHECK';
export const SUCCEEDED_PASSWORD_CHECK = 'auth/SUCCEEDED_PASSWORD_CHECK';
export const FAILED_PASSWORD_CHECK = 'auth/FAILED_PASSWORD_CHECK';
/* 비밀번호 체크 end */
/* 로그인 */
//----------------------------------------------------------------------------------------
export const TRY_LOGIN_USER = 'auth/TRY_LOGIN_USER';
export const SUCCEEDED_LOGIN_USER = 'auth/SUCCEEDED_LOGIN_USER';
export const FAILED_LOGIN_USER = 'auth/FAILED_LOGIN_USER';
/* 로그인 end */
/* 내정보 */
//----------------------------------------------------------------------------------------
export const GET_USER_MYINFO = 'auth/GET_USER_MYINFO';
export const SUCCEEDED_USER_MYINFO = 'auth/SUCCEEDED_USER_MYINFO';
export const FAILED_USER_MYINFO = 'auth/FAILED_USER_MYINFO';
/* 내정보 end */
/* 포인트 충전,사용 내역 */
//----------------------------------------------------------------------------------------
export const GET_USER_MYPOINT = 'auth/GET_USER_MYPOINT';
export const SUCCEEDED_USER_MYPOINT = 'auth/SUCCEEDED_USER_MYPOINT';
export const FAILED_USER_MYPOINT = 'auth/FAILED_USER_MYPOINT';
/* 포인트 충전,사용 내역 end */
/* 1:1 문의 리스트 */
//----------------------------------------------------------------------------------------
export const GET_ONE_INQUIRT = 'auth/GET_ONE_INQUIRT';
export const SUCCEEDED_ONE_INQUIRT = 'auth/SUCCEEDED_ONE_INQUIRT';
export const FAILED_ONE_INQUIRT = 'auth/FAILED_ONE_INQUIRT';
/* 1:1 문의 리스트 end */
//----------------------------------------------------------------------------------------
export const GET_WEATHER_DETAIL = 'weather/GET_WEATHER_DETAIL';
export const SUCCEEDED_WEATHER_DETAIL = 'weather/SUCCEEDED_WEATHER_DETAIL';
export const FAILED_WEATHER_DETAIL = 'weather/FAILED_WEATHER_DETAIL';
//----------------------------------------------------------------------------------------
export const GET_FEED_WEATHER = 'weather/GET_FEED_WEATHER';
export const SUCCEEDED_FEED_WEATHER = 'weather/SUCCEEDED_FEED_WEATHER';
export const FAILED_FEED_WEATHER = 'weather/FAILED_FEED_WEATHER';
//----------------------------------------------------------------------------------------
export const GET_WEATHER_WEEK = 'weather/GET_WEATHER_WEEK';
export const SUCCEEDED_WEATHER_WEEK = 'weather/SUCCEEDED_WEATHER_WEEK';
export const FAILED_WEATHER_WEEK = 'weather/FAILED_WEATHER_WEEK';
//----------------------------------------------------------------------------------------
export const GET_WEATHER_HOUR = 'weather/GET_WEATHER_HOUR';
export const SUCCEEDED_WEATHER_HOUR = 'weather/SUCCEEDED_WEATHER_HOUR';
export const FAILED_WEATHER_HOUR = 'weather/FAILED_WEATHER_HOUR';
//----------------------------------------------------------------------------------------
export const GET_WEATHER_ADDRESS_RESULT = 'weather/GET_WEATHER_ADDRESS_RESULT';
export const SUCCEEDED_WEATHER_ADDRESS_RESULT = 'weather/SUCCEEDED_WEATHER_ADDRESS_RESULT';
export const FAILED_WEATHER_ADDRESS_RESULT = 'weather/FAILED_WEATHER_ADDRESS_RESULT';
//----------------------------------------------------------------------------------------
export const GET_WEATHER_HISTORY_LIST = 'weather/GET_WEATHER_HISTORY_LIST';
export const SUCCEEDED_WEATHER_HISTORY_LIST = 'weather/SUCCEEDED_WEATHER_HISTORY_LIST';
export const FAILED_WEATHER_HISTORY_LIST = 'weather/FAILED_WEATHER_HISTORY_LIST';
//car-------------------------------------------------------------------------------------
//1----------------------------------------------------------------------------------------
export const GET_CAR_SECTION1 = 'car/GET_CAR_SECTION1';
export const SUCCEEDED_CAR_SECTION1 = 'car/SUCCEEDED_CAR_SECTION1';
export const FAILED_CAR_SECTION1 = 'car/FAILED_CAR_SECTION1';
//2----------------------------------------------------------------------------------------
export const GET_CAR_SECTION2 = 'car/GET_CAR_SECTION2';
export const SUCCEEDED_CAR_SECTION2 = 'car/SUCCEEDED_CAR_SECTION2';
export const FAILED_CAR_SECTION2 = 'car/FAILED_CAR_SECTION2';
//3----------------------------------------------------------------------------------------
export const GET_CAR_SECTION3 = 'car/GET_CAR_SECTION3';
export const SUCCEEDED_CAR_SECTION3 = 'car/SUCCEEDED_CAR_SECTION3';
export const FAILED_CAR_SECTION3 = 'car/FAILED_CAR_SECTION3';
//4----------------------------------------------------------------------------------------
export const GET_CAR_SECTION4 = 'car/GET_CAR_SECTION4';
export const SUCCEEDED_CAR_SECTION4 = 'car/SUCCEEDED_CAR_SECTION4';
export const FAILED_CAR_SECTION4 = 'car/FAILED_CAR_SECTION4';
//5----------------------------------------------------------------------------------------
export const GET_CAR_SECTION5 = 'car/GET_CAR_SECTION5';
export const SUCCEEDED_CAR_SECTION5 = 'car/SUCCEEDED_CAR_SECTION5';
export const FAILED_CAR_SECTION5 = 'car/FAILED_CAR_SECTION5';
//benner----------------------------------------------------------------------------------------
export const GET_CAR_SECTION_BENNER = 'car/GET_CAR_SECTION_BENNER';
export const SUCCEEDED_CAR_SECTION_BENNER = 'car/SUCCEEDED_CAR_SECTION_BENNER';
export const FAILED_CAR_SECTION_BENNER = 'car/FAILED_CAR_SECTION_BENNER';
//----------------------------------------------------------------------------------------
/* new work end */

export const GET_FEED_TOP_LIST = 'feed/GET_FEED_TOP_LIST';
export const SUCCEEDED_FEED_TOP_LIST = 'feed/SUCCEEDED_FEED_TOP_LIST';
export const FAILED_FEED_TOP_LIST = 'feed/FAILED_FEED_TOP_LIST';


export const GET_FEED_TOP_PHOTO_LIST = 'feed/GET_FEED_TOP_PHOTO_LIST';
export const SUCCEEDED_FEED_TOP_PHOTO_LIST = 'feed/SUCCEEDED_FEED_TOP_PHOTO_LIST';
export const FAILED_FEED_TOP_PHOTO_LIST = 'feed/FAILED_FEED_TOP_PHOTO_LIST';


export const GET_FEED_CENTER_BOX_LIST = 'feed/GET_FEED_CENTER_BOX_LIST';
export const SUCCEEDED_FEED_CENTER_BOX_LIST = 'feed/SUCCEEDED_FEED_CENTER_BOX_LIST';
export const FAILED_FEED_CENTER_BOX_LIST = 'feed/FAILED_FEED_CENTER_BOX_LIST';


export const GET_FEED_CENTER_GAME_MD_BOX_LIST = 'feed/GET_FEED_CENTER_GAME_MD_BOX_LIST';
export const SUCCEEDED_FEED_CENTER_GAME_MD_BOX_LIST = 'feed/SUCCEEDED_FEED_CENTER_GAME_MD_BOX_LIST';
export const FAILED_FEED_CENTER_GAME_MD_BOX_LIST = 'feed/FAILED_FEED_CENTER_GAME_MD_BOX_LIST';


export const GET_FEED_TODAY_TREND_BOX_LIST = 'feed/GET_FEED_TODAY_TREND_BOX_LIST';
export const SUCCEEDED_FEED_TODAY_TREND_BOX_LIST = 'feed/SUCCEEDED_FEED_TODAY_TREND_BOX_LIST';
export const FAILED_FEED_TODAY_TREND_BOX_LIST = 'feed/FAILED_FEED_TODAY_TREND_BOX_LIST';

export const GET_FEED_TODAY_TREND_FOOT_BOX_LIST = 'feed/GET_FEED_TODAY_TREND_FOOT_BOX_LIST';
export const SUCCEEDED_FEED_TODAY_TREND_FOOT_BOX_LIST = 'feed/SUCCEEDED_FEED_TODAY_TREND_FOOT_BOX_LIST';
export const FAILED_FEED_TODAY_TREND_FOOT_BOX_LIST = 'feed/FAILED_FEED_TODAY_TREND_FOOT_BOX_LIST';

// --------------------------------------------------------------------------------------------------------------------------------------------
export const GET_NEWS_MAIN_TOP_BULLETIN = 'news_main/GET_NEWS_MAIN_TOP_BULLETIN';
export const SUCCEEDED_NEWS_MAIN_TOP_BULLETIN = 'news_main/SUCCEEDED_NEWS_MAIN_TOP_BULLETIN';
export const FAILED_NEWS_MAIN_TOP_BULLETIN = 'news_main/FAILED_NEWS_MAIN_TOP_BULLETIN';

export const GET_NEWS_MAIN_TOP_BOX = 'news_main/GET_NEWS_MAIN_TOP_BOX';
export const SUCCEEDED_NEWS_MAIN_TOP_BOX = 'news_main/SUCCEEDED_NEWS_MAIN_TOP_BOX';
export const FAILED_NEWS_MAIN_TOP_BOX = 'news_main/FAILED_NEWS_MAIN_TOP_BOX';

export const GET_NEWS_MAIN_TOP_PHOTO_BOX = 'news_main/GET_NEWS_MAIN_TOP_PHOTO_BOX';
export const SUCCEEDED_NEWS_MAIN_TOP_PHOTO_BOX = 'news_main/SUCCEEDED_NEWS_MAIN_TOP_PHOTO_BOX';
export const FAILED_NEWS_MAIN_TOP_PHOTO_BOX = 'news_main/FAILED_NEWS_MAIN_TOP_PHOTO_BOX';


export const GET_NEWS_MAIN_CENTER_BOX = 'news_main/GET_NEWS_MAIN_CENTER_BOX';
export const SUCCEEDED_NEWS_MAIN_CENTER_BOX = 'news_main/SUCCEEDED_NEWS_MAIN_CENTER_BOX';
export const FAILED_NEWS_MAIN_CENTER_BOX = 'news_main/FAILED_NEWS_MAIN_CENTER_BOX';


export const GET_NEWS_MAIN_CENTER_HEADLINE = 'news_main/GET_NEWS_MAIN_CENTER_HEADLINE';
export const SUCCEEDED_NEWS_MAIN_CENTER_HEADLINE = 'news_main/SUCCEEDED_NEWS_MAIN_CENTER_HEADLINE';
export const FAILED_NEWS_MAIN_CENTER_HEADLINE = 'news_main/FAILED_NEWS_MAIN_CENTER_HEADLINE';


export const GET_NEWS_MAIN_TODAY_NEWS = 'news_main/GET_NEWS_MAIN_TODAY_NEWS';
export const SUCCEEDED_NEWS_MAIN_TODAY_NEWS = 'news_main/SUCCEEDED_NEWS_MAIN_TODAY_NEWS';
export const FAILED_NEWS_MAIN_TODAY_NEWS = 'news_main/FAILED_NEWS_MAIN_TODAY_NEWS';


// --------------------------------------------------------------------------------------------------------------------------------------------
export const GET_SHOPPING_TODAY_TREND = 'shopping/GET_SHOPPING_TODAY_TREND';
export const SUCCEEDED_SHOPPING_TODAY_TREND  = 'shopping/SUCCEEDED_SHOPPING_TODAY_TREND';
export const FAILED_SHOPPING_TODAY_TREND  = 'shopping/FAILED_SHOPPING_TODAY_TREND';

export const GET_SHOPPING_TODAY_TREND_FOOT = 'shopping/GET_SHOPPING_TODAY_TREND_FOOT';
export const SUCCEEDED_SHOPPING_TODAY_TREND_FOOT = 'shopping/SUCCEEDED_SHOPPING_TODAY_TREND_FOOT';
export const FAILED_SHOPPING_TODAY_TREND_FOOT = 'shopping/FAILED_SHOPPING_TODAY_TREND_FOOT';


export const GET_SHOPPING_MD_RECOMMEND = 'shopping/GET_SHOPPING_MD_RECOMMEND';
export const SUCCEEDED_SHOPPING_MD_RECOMMEND = 'shopping/SUCCEEDED_SHOPPING_MD_RECOMMEND';
export const FAILED_SHOPPING_MD_RECOMMEND = 'shopping/FAILED_SHOPPING_MD_RECOMMEND';


export const GET_SHOPPING_BEST_REVIEW = 'shopping/GET_SHOPPING_BEST_REVIEW';
export const SUCCEEDED_SHOPPING_BEST_REVIEW = 'shopping/SUCCEEDED_SHOPPING_BEST_REVIEW';
export const FAILED_SHOPPING_BEST_REVIEW = 'shopping/FAILED_SHOPPING_BEST_REVIEW';


export const GET_SHOPPING_BEST_REVIEW_FOOT_BOX = 'shopping/GET_SHOPPING_BEST_REVIEW_FOOT_BOX';
export const SUCCEEDED_SHOPPING_BEST_REVIEW_FOOT_BOX = 'shopping/SUCCEEDED_SHOPPING_BEST_REVIEW_FOOT_BOX';
export const FAILED_SHOPPING_BEST_REVIEW_FOOT_BOX = 'shopping/FAILED_SHOPPING_BEST_REVIEW_FOOT_BOX';



export const GET_SHOPPING_FASHION_BOX = 'shopping/GET_SHOPPING_FASHION_BOX';
export const SUCCEEDED_SHOPPING_FASHION_BOX = 'shopping/SUCCEEDED_SHOPPING_FASHION_BOX';
export const FAILED_SHOPPING_FASHION_BOX = 'shopping/FAILED_SHOPPING_FASHION_BOX';


export const GET_SHOPPING_FASHION_BOX_FOOT = 'shopping/GET_SHOPPING_FASHION_BOX_FOOT';
export const SUCCEEDED_SHOPPING_FASHION_BOX_FOOT = 'shopping/SUCCEEDED_SHOPPING_FASHION_BOX_FOOT';
export const FAILED_SHOPPING_FASHION_BOX_FOOT = 'shopping/FAILED_SHOPPING_FASHION_BOX_FOOT';


export const GET_SHOPPING_TECH_IT_BOX = 'shopping/GET_SHOPPING_TECH_IT_BOX';
export const SUCCEEDED_SHOPPING_TECH_IT_BOX = 'shopping/SUCCEEDED_SHOPPING_TECH_IT_BOX';
export const FAILED_SHOPPING_TECH_IT_BOX = 'shopping/FAILED_SHOPPING_TECH_IT_BOX';


export const GET_SHOPPING_RECOMMEND_APP = 'shopping/GET_SHOPPING_RECOMMEND_APP';
export const SUCCEEDED_SHOPPING_RECOMMEND_APP = 'shopping/SUCCEEDED_SHOPPING_RECOMMEND_APP';
export const FAILED_SHOPPING_RECOMMEND_APP = 'shopping/FAILED_SHOPPING_RECOMMEND_APP';


export const GET_SHOPPING_TOP_BNNER_BOX = 'shopping/GET_SHOPPING_TOP_BNNER_BOX';
export const SUCCEEDED_SHOPPING_TOP_BNNER_BOX = 'shopping/SUCCEEDED_SHOPPING_TOP_BNNER_BOX';
export const FAILED_SHOPPING_TOP_BNNER_BOX = 'shopping/FAILED_SHOPPING_TOP_BNNER_BOX';

export const GET_SHOPPING_FOOT_BNNER_BOX = 'shopping/GET_SHOPPING_FOOT_BNNER_BOX';
export const SUCCEEDED_SHOPPING_FOOT_BNNER_BOX = 'shopping/SUCCEEDED_SHOPPING_FOOT_BNNER_BOX';
export const FAILED_SHOPPING_FOOT_BNNER_BOX = 'shopping/FAILED_SHOPPING_FOOT_BNNER_BOX';

// --------------------------------------------------------------------------------------------------------------------------------------------

export const GET_GAME_MD_RECOMMEND = 'game/GET_GAME_MD_RECOMMEND';
export const SUCCEEDED_GAME_MD_RECOMMEND = 'game/SUCCEEDED_GAME_MD_RECOMMEND';
export const FAILED_GAME_MD_RECOMMEND = 'game/FAILED_GAME_MD_RECOMMEND';

export const GET_GAME_NEW_GAME_BOX = 'game/GET_GAME_NEW_GAME_BOX';
export const SUCCEEDED_GAME_NEW_GAME_BOX = 'game/SUCCEEDED_GAME_NEW_GAME_BOX';
export const FAILED_GAME_NEW_GAME_BOX = 'game/FAILED_GAME_NEW_GAME_BOX';

export const GET_GAME_OFFICAL_BOX = 'game/GET_GAME_OFFICAL_BOX';
export const SUCCEEDED_GAME_OFFICAL_BOX = 'game/SUCCEEDED_GAME_OFFICAL_BOX';
export const FAILED_GAME_OFFICAL_BOX = 'game/FAILED_GAME_OFFICAL_BOX';

export const GET_GAME_NEWS_BOX = 'game/GET_GAME_NEWS_BOX';
export const SUCCEEDED_NEWS_BOX = 'game/SUCCEEDED_NEWS_BOX';
export const FAILED_GAME_NEWS_BOX = 'game/FAILED_GAME_NEWS_BOX';

export const GET_GAME_REVIEW_BOX = 'game/GET_GAME_REVIEW_BOX';
export const SUCCEEDED_REVIEW_BOX = 'game/SUCCEEDED_REVIEW_BOX';
export const FAILED_GAME_REVIEW_BOX = 'game/FAILED_GAME_REVIEW_BOX';


export const GET_GAME_TOP_BANNER_BOX = 'game/GET_GAME_TOP_BANNER_BOX';
export const SUCCEEDED_GAME_TOP_BANNER_BOX = 'game/SUCCEEDED_GAME_TOP_BANNER_BOX';
export const FAILED_GAME_TOP_BANNER_BOX = 'game/FAILED_GAME_TOP_BANNER_BOX';

export const GET_GAME_FOOT_BANNER_BOX = 'game/GET_GAME_FOOT_BANNER_BOX';
export const SUCCEEDED_GAME_FOOT_BANNER_BOX = 'game/SUCCEEDED_GAME_FOOT_BANNER_BOX';
export const FAILED_GAME_FOOT_BANNER_BOX = 'game/FAILED_GAME_FOOT_BANNER_BOX';

// --------------------------------------------------------------------------------------------------------------------------------------------
export const GET_NEWS_SUB_TOP_PHOTOBOX = 'news_sub/GET_NEWS_SUB_TOP_PHOTOBOX';
export const SUCCEEDED_NEWS_SUB_TOP_PHOTOBOX = 'news_sub/SUCCEEDED_NEWS_SUB_TOP_PHOTOBOX';
export const FAILED_NEWS_SUB_TOP_PHOTOBOX = 'news_sub/FAILED_NEWS_SUB_TOP_PHOTOBOX';

export const GET_NEWS_SUB_FOOT_CATEGORY_PHOTOBOX = 'news_sub/GET_NEWS_SUB_FOOT_CATEGORY_PHOTOBOX';
export const SUCCEEDED_NEWS_FOOT_SUB_CATEGORY_PHOTOBOX = 'news_sub/SUCCEEDED_NEWS_FOOT_SUB_CATEGORY_PHOTOBOX';
export const FAILED_NEWS_SUB_FOOT_CATEGORY_PHOTOBOX = 'news_sub/FAILED_NEWS_SUB_FOOT_CATEGORY_PHOTOBOX';


export const GET_NEWS_SUB_NEWS_PROVIDER = 'news_sub/GET_NEWS_SUB_NEWS_PROVIDER';
export const SUCCEEDED_NEWS_FOOT_SUB_NEWS_PROVIDER = 'news_sub/SUCCEEDED_NEWS_FOOT_SUB_NEWS_PROVIDER';
export const FAILED_NEWS_SUB_FOOT_NEWS_PROVIDER = 'news_sub/FAILED_NEWS_SUB_FOOT_NEWS_PROVIDER';


export const GET_NEWS_SUB_CONTENT = 'news_sub/GET_NEWS_SUB_CONTENT';
export const SUCCEEDED_NEWS_SUB_CONTENT = 'news_sub/SUCCEEDED_NEWS_SUB_CONTENT';
export const FAILED_NEWS_SUB_CONTENT = 'news_sub/FAILED_NEWS_SUB_CONTENT';

// --------------------------------------------------------------------------------------------------------------------------------------------
export const TRY_INQUIRY_CREATE = 'inquiry/TRY_INQUIRY_CREATE';
export const SUCCEEDED_INQUIRY_CREATE = 'inquiry/SUCCEEDED_INQUIRY_CREATE';
export const FAILED_INQUIRY_CREATE = 'inquiry/FAILED_INQUIRY_CREATE';
// --------------------------------------------------------------------------------------------------------------------------------------------


export const GET_FEED_CENTER_GAME_N_BOX_LIST = 'feed/GET_FEED_CENTER_GAME_N_BOX_LIST';
export const SUCCEEDED_FEED_CENTER_GAME_N_BOX_LIST = 'feed/SUCCEEDED_FEED_CENTER_GAME_N_BOX_LIST';
export const FAILED_FEED_CENTER_GAME_N_BOX_LIST = 'feed/FAILED_FEED_CENTER_GAME_N_BOX_LIST';

