/* eslint-disable no-undef */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from 'lib/Helper';

import {
    getNewsMainTopBulletin,
    getNewsMainTopBox,
    getNewsMainTopPhotoBox,
    getNewsMainCenterNewsBox,
    getNewsMainHeadLineBox,
    getNewsMainTodayNews,
} from 'store/Actions';

import {
    NewsMainBulletin,
    DefaultNewsListBox,
    DefaultPhotoBox,
    DefaultNewsRankBox,
    NewsHeadLinePhotoBox,
    NewsCPMainNewListBox,
    // SyrupTableBanner,
    MobionBanner,
    InterWorksMediaBanner
 } from 'elements';


 const stateInitialize = {
    center_box_category : '1001',
};

export class NewsMainComponent extends Component {

    constructor(props) {

        super(props);

        this.state = stateInitialize;
    }

    _getTopBulletin = () => {
        this.props.getNewsMainTopBulletin();
    }

    _getTopBox = () => {
        this.props.getNewsMainTopBox();
    }


    _getTopPhotoBox = () => {
        this.props.getNewsMainTopPhotoBox();
    }


    _getCenterNewsBox = (category) => {
        this.props.getNewsMainCenterNewsBox(category);
    }


    _getNewsMainHeadLineBox = () => {
        this.props.getNewsMainHeadLineBox();
    }


    _getNewsMainTodayNewsBox = () => {
        this.props.getNewsMainTodayNews();
    }

    _handleCenterBoxCategoryClick = (category) => {
        this.setState({
            center_box_category: category
        });
        this._getCenterNewsBox(Helper.getCodeToNewsCategoryName(category));
    }

    _getNewsBannerOne = () => {

        var obj = $("#newsBanner01"),
        obj_width = obj.width(),
        obj_height = obj_width * 0.3125;
        obj.css({'width':'100%', 'height':obj_height});

        var newsBanner01 = new ASUMSDK();
    

        
        var newsBanner01conf = {
            targetId : 'newsBanner01',
            adUnitId : '505b47b1-7567-4c15-a5a9-73efe378158e', //505b47b1-7567-4c15-a5a9-73efe378158e
            callback : {
                onAdLoaded : function(isDefaultAd){
                    // if(isDefaultAd==true){
                    //     $("#newsBanner01").css('height','auto');
                    //     $("#newsBanner01").html('<a href="https://stockletter.kr/event/register01?utm_source=asum&utm_medium=banner&utm_campaign=noad" target="_blank"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
                    // }
                },
                onAdFailed : function(error){
                    $("#newsBanner01").css('height','auto');
                   // $("#newsBanner01").html('<a href="https://stockletter.kr/event/register04" target="_blank"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
                },
    
                onAdClicked : function(url){
                        return false;
                },
            }
    
        };

        newsBanner01.loadAd(newsBanner01conf);
        
    }

    _getNewsBannerTwo = () => {

        var obj = $("#newsBanner02"),
        obj_width = obj.width(),
        obj_height = obj_width * 0.3125;
        obj.css({'width':'100%', 'height':obj_height});

        var newsBanner02 = new ASUMSDK();
        var newsBanner02conf = {
            targetId : 'newsBanner02',
            adUnitId : '505b47b1-7567-4c15-a5a9-73efe378158e',
            callback : {
                onAdLoaded : function(isDefaultAd){
                    $("#newsBanner02").html('');
                },
                onAdFailed : function(error){
                    $("#newsBanner02").css('height','auto');
                },
    
                onAdClicked : function(url){
                        return false;
                },
            }
    
        };

        newsBanner02.loadAd(newsBanner02conf);
    }

    componentDidMount() {
        this._getTopBulletin();
        this._getTopBox();
        this._getTopPhotoBox();
        this._getCenterNewsBox(Helper.getCodeToNewsCategoryName(this.state.center_box_category));
        this._getNewsMainHeadLineBox();
        this._getNewsMainTodayNewsBox();

        //배너
        this._getNewsBannerOne();
        //this._getNewsBannerTwo();
    }

    render() {
        return (
            <div id="wrap">

                <div id="container">

                        <NewsMainBulletin
                            CONTENTS_LIST={this.props.topBulletin}
                            />


                    <div id="newsBanner01" style={{position:'relative'}}>
                        <MobionBanner
                            BANNER_TYPE="NEWS_TOP"
                        />
                    </div>

                    <div className="linkList">
                        <ul className="txtType">
                            <DefaultNewsListBox
                                CONTENTS_LIST={this.props.topBox}
                                />
                        </ul>
                        <ul className="thumbType">
                            <DefaultPhotoBox
                                CONTENTS_LIST={this.props.topPhotoBox}
                                />
                        </ul>
                    </div>

                    <div  className="banner">
                        <MobionBanner
                            BANNER_TYPE="NEWS_MIDDLE"
                        />
                    </div>

                    {/* <p className="weatherWrap">
                        <span className="state">
                            <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/img_weather.png'} alt="구름낌"/>
                        </span>
                        <span className="area">서울</span> 미세먼지 <strong className="dustState">보통</strong>
                        <span className="temp">18.8°</span>
                    </p> */}

                    <div className="tabMenu">
                        <ul className="menuLeng4">
                            <li className={this.state.center_box_category === '1002' ? 'active' : ''}><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleCenterBoxCategoryClick('1002')}  }>정치</Link></li>
                            <li className={this.state.center_box_category === '1004' ? 'active' : ''}><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleCenterBoxCategoryClick('1004')}  }>연예</Link></li>
                            <li className={this.state.center_box_category === '1005' ? 'active' : ''}><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleCenterBoxCategoryClick('1005')}  }>스포츠</Link></li>
                            <li className={this.state.center_box_category === '1007' ? 'active' : ''}><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleCenterBoxCategoryClick('1007')}  }>야구</Link></li>
                        </ul>
                    </div>

                    <div className="linkList">
                        <ul className="txtType">
                            <DefaultNewsRankBox
                                CONTENTS_LIST={this.props.centerNewsBox}
                                />

                        </ul>
                        <Link onClick={ (e) => { window.scrollTo(0, 0); }} to={{ pathname: 'news/sub/'+this.state.center_box_category }}>
                            <span style={{'display':'block','textAlign':'right','margin':'5px 0'}}>
                                더보기 &#62;
                            </span>
                        </Link>
                    </div>

                    <section className="linkList">
                        <h2 className="subTitle1">헤드라인 뉴스</h2>
                        <ul className="newsType">
                            <NewsHeadLinePhotoBox
                                CONTENTS_LIST={this.props.headLineNewsBox}
                            />
                        </ul>
                    </section>

                    <div className="linkList">
                        <h2 className="subTitle1">이시각 주요 뉴스</h2>
                        <ul className="srcType">
                            <NewsCPMainNewListBox
                                CONTENTS_LIST={this.props.todayNewsBox}
                            />
                        </ul>
                        {/* <a href="link" className="more">더보기</a> */}
                    </div>

                    <section className="section">
                        <h2 className="subTitle1">서비스 바로가기</h2>
                        <nav className="navMenu">
                            <ul className="dp1">
                                <li className="navNews"><Link to="/news">뉴스</Link></li>
                                <li className="navGame"><Link to="/game">게임</Link></li>
                                <li className="navShoping"><Link to="/shopping">쇼핑</Link></li>
                                <li className="navMap"><Link to="/map">지도</Link></li>
                                <li className="navSearch"><Link to="/search">검색</Link></li>
                            </ul>
                        </nav>
                    </section>

                    <div className="banner">
                        <MobionBanner
                            BANNER_TYPE="NEWS_BOTTOM"
                        />
                    </div>

                </div>

            </div>
        );
    }
};

NewsMainComponent.defaultProps = {
    topBulletin: {
        state: false,
        payload : {}
    },
    topBox: {
        state: false,
        payload : {}
    },
    topPhotoBox: {
        state: false,
        payload : {}
    },
    centerNewsBox: {
        state: false,
        payload : {}
    },
    headLineNewsBox: {
        state: false,
        payload : {}
    },
    todayNewsBox: {
        state: false,
        payload : {}
    },
};



const mapStateToProps = state => ({
    topBulletin : state.NEWS_MAIN.topBulletin,
    topBox : state.NEWS_MAIN.topBox,
    topPhotoBox : state.NEWS_MAIN.topPhotoBox,
    centerNewsBox : state.NEWS_MAIN.centerNewsBox,
    headLineNewsBox : state.NEWS_MAIN.headLineNewsBox,
    todayNewsBox : state.NEWS_MAIN.todayNewsBox,
});

const mapDispatchToProps = {
    getNewsMainTopBulletin,
    getNewsMainTopBox,
    getNewsMainTopPhotoBox,
    getNewsMainCenterNewsBox,
    getNewsMainHeadLineBox,
    getNewsMainTodayNews
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsMainComponent));
