import * as API from 'lib/API';
import { Cookies } from 'react-cookie';

export const SiteLogCheckURL = async (link) => {

    try {

        if (link.text==undefined) link.text='';
        //await API.postContentsLinkToLog({
        //    target_url: link.url,
        //    target_name:link.text
        //});
       

        try{

            const cookies = new Cookies();
            let token =  (cookies.get('__token')) ? cookies.get('__token') : '';

            window.monsterTrackLink(token,link.text,link.url);    //몬스터큐브 클릭

            window.skTrackLink(link.text,link.url);    //SK클릭

        }catch(e){

        }
    }
    catch(error) {
        console.debug(error);
    }
    finally {
        // window.open(decodeURIComponent(link.url), '');
        window.location.href=decodeURIComponent(link.url);
    }

}