import { defaultAxios } from 'lib/GlobalAxios'
// import * as Helper from 'lib/Helper'


export function postContentsLinkToLog(payload) {

    return defaultAxios.get('/tracker/click',payload);
    // return defaultAxios.get('http://psmever.dlinkddns.com/etc/nearbysearch.php',payload);
}

/**
 *
 * 피드 실시간 키워드
 */
export function getFeedKeyWordList() {
    return defaultAxios.get('/potal/realkeyword');
}

// ------------------------------------------------------------------------------


/* new work */
/*
 * 인증번호 발송
 */
export function tryCertification(params) {
    return defaultAxios.post('/auth/certification', params);
}
/*
 * 인증번호 확인
 */
export function tryConfirm(params) {
    return defaultAxios.post('/auth/confirm', params);
}

/*
 * 전화번호 로그 서치
 */
export function getLogPhoneNum(params) {
    return defaultAxios.get('/user/log', params);
}
/*
 * 토큰발급
 */
export function getBrowserToken() {
    return defaultAxios.get('/token/getting_t');
}
/*
 * 평범한 회원가입
 */
export function tryDefaultJoinMember(params) {
    return defaultAxios.post('/auth/default_join', params);
}
/*
 * 카드 조회 회원가입
 */
export function tryJoinMember(params) {
    return defaultAxios.post('/auth/join', params);
}
/*
 * 비밀번호 변경 mypage
 */
export function tryUserChangePw(params) {
    return defaultAxios.post('/auth/changepw', params);
}
/*
 * 비밀번호 변경 findpw
 */
export function tryDefaultChangePw(params) {
    return defaultAxios.post('/auth/defchangepw', params);
}
/*
 * 비밀번호 체크
 */
export function tryPasswordCheck(params) {
    return defaultAxios.post('/auth/password_check', params);
}
/*
 * 회원탈퇴
 */
export function tryWithdrawal(params) {
    return defaultAxios.post('/auth/withdrawal', params);
}

/*
 * 로그인
 */
export function tryLoginUser(params) {
    return defaultAxios.post('/auth/login', params);
}
/*
 * 로그인 정보 유지
 */
export function getUserMyinfo(params) {
    return defaultAxios.post('/auth/myinfo', params);
}
/*
 * 포인트 충전,사용 내역
 */
export function getUserMypoint(params) {
    return defaultAxios.post('/auth/usepoint', params);
}

/*
 * 1:1 문의 답변 리스트
 */
export function getOneononeList(params) {
    return defaultAxios.post('/inquiry/oneonone_list', params);
}

/*
 *
 * 날씨
 */
export function getWeatherDetail(citycode) {
    return defaultAxios.get('/potal/weatheri_detail_info', {
        params: {
            'city_code': citycode,
        }
    });
}

export function getFeedWeather(citycode) {
    return defaultAxios.get('/potal/weatheri', {
        params: {
            'city_code': citycode,
        }
    });
}

export function getWeatherWeek(citycode) {
    return defaultAxios.get('/potal/weatheri_week', {
        params: {
            'city_code': citycode,
        }
    });
}

export function getWeatherHour(citycode) {
    return defaultAxios.get('/potal/weatheri_hour', {
        params: {
            'city_code': citycode,
        }
    });
}

export function getWeatherAddressResult(keyword) {
    return defaultAxios.get('/potal/address_search', {
        params: {
            'keyword': keyword,
        }
    });
}

export function getWeatherHistoryList(histories) {
    return defaultAxios.get('/potal/history_search', {
        params: {
            'histories': histories,
        }
    });
}

/**
 *
 * 자동차 메뉴
 */

/* section1 */
export function getCarSection1(params) {
    return defaultAxios.get('/potal/car', params);
}
/* section2 */
export function getCarSection2(params) {
    return defaultAxios.get('/potal/car', params);
}
/* section3 */
export function getCarSection3(params) {
    return defaultAxios.get('/potal/car', params);
}
/* section4 */
export function getCarSection4(params) {
    return defaultAxios.get('/potal/car', params);
}
/* section5 */
export function getCarSection5(params) {
    return defaultAxios.get('/potal/car', params);
}
/* section benner */
export function getCarBanner(params) {
    return defaultAxios.get('/potal/car', params);
}

/* new work end */


/**
 *
 * 피드 상단 5꼭지
 */
export function getFeedTopList() {
    return defaultAxios.get('/potal/news', {
        params: {
            'category': 'main',
            'limit': '5',
            // 'check_img': 'N',

        }
    });
}

/**
 *
 * 피드 상단 사진 3개 짜리.
 */
export function getFeedTopPhotoList() {
    return defaultAxios.get('/potal/news', {
        params: {
            'category': 'main',
            'limit': '3',
            'check_img': 'Y',

        }
    });
}

/**
 *
 * 피드 중단 카테고리 박스
 */
export function getFeedCenterBoxList(category) {
    // console.debug({apicategory:category});
    // category = 'main';
    return defaultAxios.get('/potal/news', {
        params: {
            'category': category,
            'limit': '5',
            // 'check_img': 'N',

        }
    });
}


/**
 *
 * 피드 중간 game md 박스
 */
export function getFeedCenterGameMdBoxList(category) {
    // console.debug({apicategory:category});
    // category = 'main';
    return defaultAxios.get('/potal/game', {
        params: {
            'category': 'md',
            'limit': '16',
        }
    });
}


/**
 *
 * 피드 중간 gameN 박스
 */
export function getFeedCenterGameNBoxList(category) {
    // console.debug({apicategory:category});
    // category = 'main';
    return defaultAxios.get('/potal/game_n', {
        params: {
            'category': 'game_n',
            'limit': '16',
        }
    });
}



/**
 *
 * 피드 하단 투데이 트렌드 박스
 */
export function getFeedTodayTrendBoxList(category) {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'today',
            'limit': '6',
        }
    });
}



/**
 *
 * 피드 하단 투데이 트렌드 하단 박스
 */
export function getFeedTodayTrendFootBoxList() {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'today',
            'limit': '3',
        }
    });
}

// ------------------------------------------------------------------------------
/**
 *
 * 뉴스 메인 속보
 */
export function getNewsMainTopBulletin() {
    return defaultAxios.get('/potal/news_quick', {
        params: {
            'category': 'main',
            'limit': '1',
            // 'check_img': 'N',

        }
    });
}

/**
 *
 * 뉴스 메인 상단 박스
 */
export function getNewsMainTopBox() {
    return defaultAxios.get('/potal/news', {
        params: {
            'category': 'main',
            'limit': '5',
            // 'check_img': 'N',

        }
    });
}

/**
 *
 * 뉴스 메인 상단 포토 박스
 */
export function getNewsMainTopPhotoBox() {
    return defaultAxios.get('/potal/news', {
        params: {
            'category': 'main',
            'limit': '3',
            'check_img': 'Y',

        }
    });
}



/**
 *
 * 뉴스 메인 가운데 뉴스 순위 박스(?)
 */
export function getNewsMainCenterNewsBox(category) {
    // category = 'main'
    return defaultAxios.get('/potal/news', {
        params: {
            'category': category,
            'limit': '6',
            'check_img': 'Y',

        }
    });
}


/**
 *
 * 뉴스 메인 헤드라인 박스
 */
export function getNewsMainHeadLineNewsBox(category) {
    return defaultAxios.get('/potal/news', {
        params: {
            'category': 'main',
            'limit': '4',
            'check_img': 'Y',

        }
    });
}



/**
 *
 * 뉴스 메인 주요 뉴스
 */
export function getNewsMainTodayNewsBox(category) {
    return defaultAxios.get('/potal/news', {
        params: {
            'category': 'main',
            'limit': '5',
            // 'check_img': 'N',

        }
    });
}


/**
 *
 * 뉴스 컨텐츠
 */
export function getNewsContentsBox(sid) {
    return defaultAxios.get('/potal/news_detail', {
        params: {
            'sid': sid,
        }
    });
}

// ------------------------------------------------------------------------------

/*
getShoppingTodayTrend
getShoppingTodayTrendFoot
getShoppingMDRecommend
getShoppingBestReview
getShoppingBestReviewFoot
getShoppingFashionBox
getShoppingFashionBoxFoot
getShoppingTechItBox
getShoppingRecommendApp
*/


/**
 *
 * 쇼핑 투데이 트렌드
 */
export function getShoppingTodayTrend() {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'today',
            'limit': '6',
        }
    });
}

/**
 *
 * 쇼핑 투데이 트렌드 하단
 */
export function getShoppingTodayTrendFoot() {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'today',
            'limit': '6',
        }
    });
}

/**
 *
 * 쇼핑 MD 추천
 */
export function getShoppingMDRecommend() {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'md',
            'limit': '90',
        }
    });
}

/**
 *
 * 쇼핑 베스트 리뷰
 */
export function getShoppingBestReview() {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'no_fail',
            'limit': '5',
        }
    });
}


/**
 *
 * 쇼핑 베스트 리뷰 하단 박스
 */
export function getShoppingBestReviewFootBox() {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'no_fail',
            'limit': '3',
        }
    });
}

/**
 *
 * 쇼핑 패션
 */
export function getShoppingFashionBox() {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'fashion',
            'limit': '5',
        }
    });
}


/**
 *
 * 쇼핑 패션 하단
 */
export function getShoppingFashionBoxFoot() {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'fashion',
            'limit': '4',
        }
    });
}

/**
 *
 * 쇼핑 테크 IT
 */
export function getShoppingTechItBox() {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'tech',
            'limit': '6',
        }
    });
}

/**
 *
 * 쇼핑 추천 앱
 */
export function getShoppingRecommendApp() {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'recommend',
            'limit': '6',
        }
    });
}

/**
 *
 * 쇼핑 TOP 배너
 */
export function getShoppingTopBnnerBox() {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'ban1',
        }
    });
}

/**
 *
 * 쇼핑 FOOT 배너
 */
export function getShoppingFootBnnerBox() {
    return defaultAxios.get('/potal/shopping', {
        params: {
            'category': 'ban2',
        }
    });
}


// --------------------------------------------------------------------------------------------------------------------------------------------

/**
 *
 * 게임 상단 MD 추천
 */
export function getGameMdRecommend() {
    return defaultAxios.get('/potal/game', {
        params: {
            'category': 'md',
            'limit': '16',
        }
    });
}

/**
 *
 * 게임 광고?
 */
export function getGameNewGameBox() {
    return defaultAxios.get('/potal/game', {
        params: {
            'category': 'cpa',
            'limit': '3',
        }
    });
}

/**
 *
 * 게임 오피셜
 */
export function getGameOfficalBox() {
    return defaultAxios.get('/potal/game', {
        params: {
            'category': 'official',
            'limit': '4',
        }
    });
}

/**
 *
 * 게임 뉴스 박스
 */
export function getGameNewsBox() {
    return defaultAxios.get('/potal/game', {
        params: {
            'category': 'news',
            'limit': '4',
        }
    });
}

/**
 *
 * 게임 리뷰
 */
export function getGameReviewBox() {
    return defaultAxios.get('/potal/game', {
        params: {
            'category': 'reviews',
            'limit': '3',
        }
    });
}


/**
 *
 * 게임 상단 배너
 */
export function getGameTopBannerBox() {
    return defaultAxios.get('/potal/game', {
        params: {
            'category': 'ban1'
        }
    });
}

/**
 *
 * 게임 하단 배너
 */
export function getGameFootBannerBox() {
    return defaultAxios.get('/potal/game', {
        params: {
            'category': 'ban2'
        }
    });
}


// -------------------------------------------------------------

/**
 * 뉴스 서브 상단 박스
 */
export function getNewsSubTopPhotobox(category) {
    // category='main';
    return defaultAxios.get('/potal/news', {
        params: {
            'category': category,
            'limit': '6',
            'check_img': 'Y',

        }
    });
}

/**
 *
 * 뉴스 서브 하단 카테고리 박스
 */
export function getNewsSubFootCategoryPhotobox(category) {
    // category='main';
    return defaultAxios.get('/potal/news', {
        params: {
            'category': category,
            'limit': '6',
            'check_img': 'Y',

        }
    });
}


/**
 *
 * 뉴스 서브 하단 관심있는 언론사
 */
export function getNewsSubNewsProvider() {
    return defaultAxios.get('/potal/news_provider');
}
// ------------------------------------------------------------------------------
// {"latitude":37.50745155582311,"longitude":127.05360415125435,"placeCategory":"restaurant"}
export function getGooglePlaceData(payload) {
    // return fetch('http://api.t-page.co.kr/nearbysearch.php');
    // return defaultAxios.post('http://psmever.dlinkddns.com/etc/nearbysearch.php', payload);
    // return defaultAxios.post('http://api.t-page.co.kr/nearbysearch.php', payload);
    return defaultAxios.get('/potal/place', {
        params: {
            'latitude': payload.latitude,
            'longitude': payload.longitude,
            'placeCategory': payload.placeCategory,

        }
    });
}

// ------------------------------------------------------------------------------
export function getGoogleSearchHtml(payload) {
    // return fetch('http://api.t-page.co.kr/nearbysearch.php');
    return defaultAxios.get('http://www.t-page.co.kr/test.html?q=%EB%B0%95%EC%84%B1%EB%AF%BC');
}


export function tryInquiryCreate(payload) {
    return defaultAxios.post('/inquiry/create', payload);
}



export function getGooglePlaceData2(payload) {
    // return fetch('http://www.t-page.co.kr/etc/nearbysearch.php', {
    return fetch('http://api.t-page.co.kr/potal/place', {
        method: "POST",
        body: JSON.stringify(payload),
        // mode: "no-cors",
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
            // 'Content-Type': 'text/html',
            // 'Content-Type':'application/json',
            'Accept': 'application/json; charset=utf-8',
            // 'Content-Type': 'application/x-www-form-urlencoded'
    //         'Access-Control-Allow-Credentials' : true,
    // 'Access-Control-Allow-Origin':'*',
    // 'Access-Control-Allow-Methods':'GET',
    // 'Access-Control-Allow-Headers':'application/json; charset=utf-8',

    // "Access-Control-Allow-Origin": "*",
    //   "Access-Control-Allow-Credentials": "true",
    //   "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    //   "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    //   'Content-Type': 'application/json',
        },
        // credentials: "same-origin"
      }).then(function(response) {
        // response.status     //=> number 100–599
        // response.statusText //=> String
        // response.headers    //=> Headers
        // response.url        //=> String
        console.debug({response:response});
        return response;
      }, function(error) {
        // error.message //=> String
        console.debug({error:error});
      })
}
