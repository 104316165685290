import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Header,Footer } from 'components';
import * as Helper from 'lib/Helper';
import { Link } from "react-router-dom";
import { Cookies } from 'react-cookie';
import queryString from "query-string";

import { tryDefaultChangePw, tryCertification, tryConfirm } from 'store/Actions';

import {} from 'elements';
const cookies = new Cookies();

export class FindPasswordComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: (cookies.get('__token')) ? cookies.get('__token') : '',
            id: '',
            certi_num: '',
            password: '',
            password_check: '',
            confirm: false,
            certificationIng: false,
            confirmIng: false,
        };
    }

    handleOnchange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    _findAction = () => {
        let {
            token,
            id,
            certi_num,
            password,
            password_check,
        } = this.state;

        if(!this.state.confirm) {
            alert('휴대폰 인증을 완료해주세요.');
            return false;
        }

        let params = {
            token: token,
            id: id,
            change_pwd: password,
            change_pwd_check: password_check,
        }

        this.props.tryDefaultChangePw(params);
    }

    _findCertification = () => {
        let {
            id
        } = this.state;
        let regPhone = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})([0-9]{3,4})([0-9]{4})$/;

        if(id === '') {
            alert('휴대폰번호를 입력해주세요.');
            return false;
        }

        if(!regPhone.test(id)){
            alert('잘못된 휴대폰 번호입니다.');
            return false;
        }

        this.setState({
            certificationIng: true
        });

        let params = {
            id: id,
            type: '회원가입',
        }
        this.props.tryCertification(params);
    }

    _findConfirm = () => {
        let {
            id,
            certi_num
        } = this.state;

        if(id === '') {
            alert('휴대폰번호를 입력해주세요.');
            return false;
        }

        if(certi_num === '') {
            alert('인증번호를 입력해주세요.');
            return false;
        }

        let params = {
            phone: id,
            code: certi_num,
        }

        this.props.tryConfirm(params);
    }

    componentDidMount(){
        // console.debug({ name:'componentDidMount'});
    }

    componentWillReceiveProps(nextProps) {
        // console.debug({ name:'Component WILL RECIEVE PROPS!', nextProps: nextProps})
        if(nextProps.CONFIRM.state === true && this.props.CONFIRM.state !== nextProps.CONFIRM.state) {
            this.setState({
                confirm : true
            });
        }
        if(nextProps.DEFAULT_CHANGE_PW.state === true && this.props.DEFAULT_CHANGE_PW.state !== nextProps.DEFAULT_CHANGE_PW.state) {
            alert('비밀번호 변경이 완료되었습니다.');
            this.props.history.push('/login');
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.debug({ name:'ShouldComponentUpdate', nextProps: nextProps, nextState:nextState});
        return true;
    }

    componentWillUpdate(nextProps, nextState) {
        // console.debug({ name:'Component WILL UPDATE!' , nextProps: nextProps, nextState:nextState});
    }

    componentDidUpdate(prevProps, prevState) {
        // console.debug({ name:'Component DID UPDATE!' , prevProps: prevProps, prevState:prevState}
    }

    componentDidCatch(error, info) {
        // console.debug({ name:'Component DID Catch!' , error: error, info: info})
        //Handle error.
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        return (
            <div id="wrap">
                <div id="container">
                    <div className="joinPage">
                        <div className="joinPageHeader">
                            <Link to="/login">
                                <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/webtoon/before.svg'} />
                            </Link>
                            <div>비밀번호 찾기</div>
                        </div>
                        <div className="joinPageBody">
                            <div className="joinPageBodyForm">
                                <p className="precautions"> *아이디는 본인 핸드폰번호를 넣으셔야 합니다. </p>
                                <div className="inputForm">
                                    <div>
                                        <div>
                                            <span>아이디</span>
                                        </div>
                                        <div>
                                            <div>
                                                <input type="number" name="id" onChange={this.handleOnchange} placeholder="  휴대폰번호" disabled={(this.state.confirm) ? true : false} />
                                            </div>
                                            <div>
                                                <button onClick={() => this._findCertification()} disabled={(this.state.confirm) ? true : false}>휴대폰인증</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <span>인증번호</span>
                                        </div>
                                        <div>
                                            <div>
                                                <input type="number" name="certi_num" onChange={this.handleOnchange} placeholder="  숫자6자리입력" disabled={(this.state.confirm) ? true : false} />
                                            </div>
                                            <div>
                                                <button onClick={() => this._findCertification()} disabled={(this.state.confirm) ? true : false}>재전송</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button type="button" onClick={() => this._findConfirm()} disabled={(this.state.confirm) ? true : false}>인증확인</button>
                                    </div>
                                </div>
                            </div>
                            <div className="joinPageBodyForm">
                                <p className="precautions"> *비밀번호를 입력해주세요. (6자리이상) </p>
                                <div className="passwordForm">
                                    <div>
                                        <div>
                                            <span>비밀번호</span>
                                        </div>
                                        <div>
                                            <input type="password" name="password" onChange={this.handleOnchange} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <span>비밀번호 확인</span>
                                        </div>
                                        <div>
                                            <input type="password" name="password_check" onChange={this.handleOnchange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="joinPageBodyButton">
                                <button type="button" onClick={() => this._findAction()}>확인</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => ({
    DEFAULT_CHANGE_PW: state.DEFAULT_CHANGE_PW,
    CERTIFICATION: state.CERTIFICATION,
    CONFIRM: state.CONFIRM,
});

const mapDispatchToProps = {
    tryDefaultChangePw,
    tryCertification,
    tryConfirm,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FindPasswordComponent));
