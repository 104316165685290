import React from "react";
import { Link } from "react-router-dom";
import * as Helper from 'lib/Helper';
import * as GlobalLink from 'lib/GlobalLink'
import styled from 'styled-components';
import { Cookies } from 'react-cookie';

import { Swiper, Slide } from 'react-dynamic-swiper';
import 'react-dynamic-swiper/lib/styles.css';

export const MainWeaterBox = ({
    WEATHER_DATA
}) => {
    const cookies = new Cookies();
    const cities = (cookies.get('weatherHistory')) ? cookies.get('weatherHistory') : '';
    const _moveDetail = (mCode, idx) => {
        cookies.set('cityCode', mCode, { path: '/' });
        cookies.set('cityIdx', idx, { path: '/' });
        window.location = '/weather/detail';
    };
    if(WEATHER_DATA.state === true && typeof WEATHER_DATA.payload === "object") {
        let city_idx = (cookies.get('cityIdx')) ? cookies.get('cityIdx') : '0';
        
        return (
            <Swiper
                swiperOptions={{
                    slidesPerView: 'auto',
                    initialSlide: city_idx
                }}
                navigation={false}
                pagination={false}
            >
            {WEATHER_DATA.payload.map((item, i) => {
                let idx = 0;
                if(cities.length > 0) {
                    cities.map((citem, ci) => {
                        if(citem.mCode == item.cityCode) {
                            idx = citem.idx;
                        }
                    });
                }
                return (
                    <Slide key={i}> 
                        <a onClick={ (e) => {_moveDetail(item.cityCode, idx)}}>
                            <div>
                                <div>
                                    <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/'+item.sky_code+'.svg'} alt={item.sky}/>
                                </div>
                                <div>
                                    <div>
                                        <div>{item.temp}°</div>
                                    </div>
                                    <div>
                                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/'+item.pm10_str+'.svg'} />
                                        <div>미세먼지 {item.pm10_s}</div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div>{item.city_str}</div>
                                    </div>
                                    <div>
                                        <div>{item.mintemp}°/{item.maxtemp}°</div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </Slide> 
                );
            })}
            </Swiper>
        );
    } else {
        return (
            <div>
            </div>
        );
    }
};

export const DetailWeaterBox = ({
    WEATHER_DATA
}) => {
    if(WEATHER_DATA) {
        return (
            WEATHER_DATA.map((item, i) => {
                return (
                    <div key={i}>
                        <div>
                            <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/'+item.sky_code+'.svg'} alt={item.sky}/>
                        </div>
                        <div>
                            <div>
                                <div>{item.temp}°</div>
                            </div>
                            <div>
                                <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/'+item.pm10_str+'.svg'} />
                                <div>미세먼지 {item.pm10_s}</div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div>{item.city_str}</div>
                            </div>
                            <div>
                                <div>{item.mintemp}°/{item.maxtemp}°</div>
                            </div>
                        </div>
                        <a href="/weather/search">
                            <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/more.svg'} />
                        </a>
                    </div>
                );
            })
        );
    } else {
        return (
            <div>
            </div>
        );
    }
};

export const DetailWeaterHourLine = ({
    WEATHER_DATA
}) => {
    if(WEATHER_DATA) {
        return (
            WEATHER_DATA.map((item, i) => {
                return (
                    <div key={i}>
                        <dl>
                            <dd>
                                {item.time}시
                            </dd>
                            <dd>
                                <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/'+item.sky_code+'.svg'} alt={item.sky} />
                            </dd>
                        </dl>
                    </div>
                );
            })
        );
    } else {
        return (
            <div>
            </div>
        );
    }
};


export const DetailWeaterWeekList = ({
    WEATHER_DATA
}) => {

    if(WEATHER_DATA) {
        return (
            WEATHER_DATA.map((item, i) => {
                return (
                    <li key={i}>
                        <div>{item.aplYmd_str}</div>
                        <div>
                            <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/'+item.sky_code+'.svg'} alt={item.sky}/>
                        </div>
                        <div>
                            {item.mintemp}°/{item.maxtemp}°
                        </div>
                    </li>
                );
            })
        );
    } else {
        return (
            <div>
            </div>
        );
    }
};

