import React from "react";
import { Link } from "react-router-dom";
import * as GlobalLink from 'lib/GlobalLink'

export const NewsMainBulletin = ({
CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {
        const item = CONTENTS_LIST.payload[0];
        return (
            <p className="breakingNews">
                <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.link))}  }>
                    <span>속보</span> {item.title}
                </Link>
            </p>
        );
    } else {
        return (
            <p className="breakingNews">
            </p>
        );
    }

};






