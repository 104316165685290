/* eslint-disable no-undef */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from 'lib/Helper'

import {
    getShoppingTodayTrend,
    getShoppingTodayTrendFoot,
    getShoppingMDRecommend,
    getShoppingBestReview,
    getShoppingBestReviewFoot,
    getShoppingFashionBox,
    getShoppingFashionBoxFoot,
    getShoppingTechItBox,
    getShoppingRecommendApp,
    getShoppingTopBnnerBox,
    getShoppingFootBnnerBox,
} from 'store/Actions';

import {
    DefaultTrendPhotoBox,
    DefaultTrendSmallPhotoBox,
    ShoppingMdPagingPhotoBox,
    ShoppingBestReviewBox,
    // ShoppingListPhotoBox,
    ShoppingScorePhotoBox,
    TodayTrendBigTypeBox,
    TodayTrendSmallTypeBox,
    DefaultBannerBox,
    ShoppingBestReview,
    InterWorksMediaBanner,
    MediaCategoryBannerTop,
    MediaCategoryBannerBottom,
    MobionBanner,

} from 'elements';

export class ShoppingComponent extends Component {

    constructor(props) {

        super(props);

        this.state = {
            center_game_md_box_page : 1,
            center_game_md_box_total_page : 1,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.mdRecommend.state === true && this.props.mdRecommend.payload !== nextProps.mdRecommend.payload) {
            const mdPayloadCount = nextProps.mdRecommend.payload.length;

            let totalPage = Helper.getTotalPage((mdPayloadCount - 1), 6);

            if(totalPage === 0) {
                this.setState({
                    center_game_md_box_page : 1,
                    center_game_md_box_total_page : 1
                });
            } else {
                this.setState({
                    center_game_md_box_total_page : totalPage
                });
            }
        }
    }


    _getShoppingTodayTrend = () => {
        this.props.getShoppingTodayTrend();
    }
    _getShoppingTodayTrendFoot = () => {
        this.props.getShoppingTodayTrendFoot();
    }
    _getShoppingMDRecommend = () => {
        this.props.getShoppingMDRecommend();
    }
    _getShoppingBestReview = () => {
        this.props.getShoppingBestReview();
    }
    _getShoppingBestReviewFoot = () => {
        this.props.getShoppingBestReviewFoot();
    }
    _getShoppingFashionBox = () => {
        this.props.getShoppingFashionBox();
    }
    _getShoppingFashionBoxFoot = () => {
        this.props.getShoppingFashionBoxFoot();
    }
    _getShoppingTechItBox = () => {
        this.props.getShoppingTechItBox();
    }
    _getShoppingRecommendApp = () => {
        this.props.getShoppingRecommendApp();
    }
    _getShoppingTopBnnerBox = () => {
        this.props.getShoppingTopBnnerBox();
    }
    _getShoppingFootBnnerBox = () => {
        this.props.getShoppingFootBnnerBox();
    }

    _getShoppingBannerOne = () => {

        var obj = $("#shopBanner01"),
        obj_width = obj.width(),
        obj_height = obj_width * 0.3125;
        obj.css({'width':'100%', 'height':obj_height});

        var shopBanner01 = new ASUMSDK();
        
        var shopBanner01conf = {
            targetId : 'shopBanner01',
            adUnitId : 'a08bd670-4e90-4c9b-94af-05812fd5dea6',
            callback : {
                onAdLoaded : function(isDefaultAd){
                    //  if(isDefaultAd==true){
                    //     $("#subnewsBanner01").css('height','auto');
                    //     $("#subnewsBanner01").html('<a href="https://stockletter.kr/event/register01?utm_source=asum&utm_medium=banner&utm_campaign=noad" target="_blank"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
                    // }
                },
                onAdFailed : function(error){
                    $("#shopBanner01").css('height','auto');
                    //$("#shopBanner01").html('<a href="https://stockletter.kr/event/register04" target="_blank"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
                },
    
                onAdClicked : function(url){
                        return false;
                },
            }
    
        };
        shopBanner01.loadAd(shopBanner01conf);
        
    }

    _getShoppingBannerTwo = () => {

        var obj = $("#shopBanner02"),
        obj_width = obj.width(),
        obj_height = obj_width * 0.3125;
        obj.css({'width':'100%', 'height':obj_height});

        var shopBanner02 = new ASUMSDK();
        var shopBanner02conf = {
            targetId : 'shopBanner02',
            adUnitId : 'a08bd670-4e90-4c9b-94af-05812fd5dea6',
            callback : {
                onAdLoaded : function(isDefaultAd){
                    $("#shopBanner02").html('');
                },
                onAdFailed : function(error){
                    $("#shopBanner02").css('height','auto');
                },
    
                onAdClicked : function(url){
                        return false;
                },
            }
        };

        shopBanner02.loadAd(shopBanner02conf);
    }

    _getShoppingBannerThree = () => {

        var obj = $("#shopBanner03"),
        obj_width = obj.width(),
        obj_height = obj_width * 0.3125;
        obj.css({'width':'100%', 'height':obj_height});

        var shopBanner03 = new ASUMSDK();
        var shopBanner03conf = {
            targetId : 'shopBanner03',
            adUnitId : 'a08bd670-4e90-4c9b-94af-05812fd5dea6',
            callback : {
                onAdLoaded : function(isDefaultAd){
                    $("#shopBanner03").html('');
                },
                onAdFailed : function(error){
                    $("#shopBanner03").css('height','auto');
                },
    
                onAdClicked : function(url){
                        return false;
                },
            }
        };

        shopBanner03.loadAd(shopBanner03conf);
    }

    startPage = () => {
        this._getShoppingTodayTrend();
        // this._getShoppingTodayTrendFoot();
        this._getShoppingMDRecommend();
        this._getShoppingBestReview();
        // this._getShoppingBestReviewFoot();
        this._getShoppingFashionBox();
        // this._getShoppingFashionBoxFoot();
        this._getShoppingTechItBox();
        this._getShoppingRecommendApp();
        this._getShoppingTopBnnerBox();
        this._getShoppingFootBnnerBox();

        //banner
        this._getShoppingBannerOne();
        //this._getShoppingBannerTwo();
        //this._getShoppingBannerThree();
    }

    _handleClickGameMdPageing = (e) => {

        let nowPage = this.state.center_game_md_box_page;
        let pageValue;
        const pageType = e.target.getAttribute('page_type');

        if(pageType === '-') {

            if(nowPage-1 === 0) {
                pageValue = this.state.center_game_md_box_total_page;
            } else {
                pageValue = nowPage-1;
            }
            this.setState({
                center_game_md_box_page: pageValue
            });

        } else if(pageType === '+') {

            if(nowPage+1 > this.state.center_game_md_box_total_page) {
                pageValue = 1;
            } else {
                pageValue = nowPage+1;
            }
            this.setState({
                center_game_md_box_page: pageValue
            });
        }


    }


    componentDidMount() {

        this.startPage();
    }

    render() {
        return (

            <div id="wrap">

                <div id="container">

                    {/*<MediaCategoryBannerTop />*/}

                    <div  id="shopBanner01" style={{position:'relative'}}>
                        {/* <DefaultBannerBox
                            CONTENTS_LIST={this.props.topBannerBox}
                        /> */}
                        <MobionBanner
                                BANNER_TYPE="NEWS_TOP"
                            />
                    </div>

                    <section className="linkList">
                        <h2 className="subTitle1">TODAY TREND <span>오늘 제일 핫한 트랜드한 쇼핑 아이템</span></h2>
                        <ul className="thumbBigType">
                            <TodayTrendBigTypeBox
                                CONTENTS_LIST={this.props.todayTrend}
                            />
                        </ul>
                        <ul className="thumbType">
                            <TodayTrendSmallTypeBox
                                CONTENTS_LIST={this.props.todayTrend}
                            />
                        </ul>
                    </section>

                    <div  className="banner">
                        <DefaultBannerBox
                            CONTENTS_LIST={this.props.topBannerBox}
                        />
                    </div>

                    {/* <section className="linkList">
                        <h2 className="subTitle1">MD추천 <span>열심히 골라봤어요!</span></h2>
                        <div>
                            <ShoppingMdPagingPhotoBox
                                CONTENTS_LIST={this.props.mdRecommend}
                                CONTENTS_PAGE={this.state.center_game_md_box_page}
                            />
                        </div>
                        <div className="listNav">
                            <button type="button" className="btnPrev" onClick={this._handleClickGameMdPageing} page_type="-" disabled={this.state.center_game_md_box_total_page === 0 ? "disabled" : ""}>이전</button>
                            <span className="pagination"><strong>{this.state.center_game_md_box_page}</strong> /<span>{this.state.center_game_md_box_total_page}</span></span>
                            <button type="button" className="btnNext" onClick={this._handleClickGameMdPageing} page_type="+" disabled={this.state.center_game_md_box_total_page === 0 ? "disabled" : ""}>다음</button>
                        </div>
                    </section> */}

                    <div className="banner">
                        <DefaultBannerBox
                            CONTENTS_LIST={this.props.topBannerBox}
                        />
                    </div>

                    <section className="linkList">
                        <h2 className="subTitle1">실패하지 않아요! <span>BEST 리뷰</span></h2>
                        <div className="gridType">
                            <ul>
                                <ShoppingBestReview
                                    CONTENTS_LIST={this.props.bestReview}
                                />
                            </ul>
                        </div>
                        <ul className="txtType">
                            <ShoppingBestReviewBox
                                CONTENTS_LIST={this.props.bestReview}
                            />
                        </ul>
                    </section>

                    <DefaultTrendPhotoBox
                        CONTENTS_LIST={this.props.fashionBox}
                    />

                    <div className="banner">
                        {/*<DefaultBannerBox
                            CONTENTS_LIST={this.props.footBannerBox}
                        />
                        */}
                        <InterWorksMediaBanner  />
                    </div>

                    <DefaultTrendSmallPhotoBox
                        CONTENTS_LIST={this.props.techItBox}
                    />

                    <section className="section">
                        <h2 className="subTitle1">쇼핑 추천 <span>APP</span></h2>
                        <div className="appList">
                            <ul>
                                <ShoppingScorePhotoBox
                                    CONTENTS_LIST={this.props.recommendApp}
                                    />
                            </ul>
                        </div>
                    </section>
                    {/*<div className="banner">
                                            <MediaCategoryBannerBottom />
                                        </div>*/}
                 </div>

            </div>
        );
    }
};

ShoppingComponent.defaultProps = {
    todayTrend: {
        state: false,
        payload : {}
    },
    todayTrendFoot: {
        state: false,
        payload : {}
    },
    mdRecommend: {
        state: false,
        payload : {}
    },
    bestReview: {
        state: false,
        payload : {}
    },
    bestReviewFootBox: {
        state: false,
        payload : {}
    },
    fashionBox: {
        state: false,
        payload : {}
    },
    fashionBoxFoot: {
        state: false,
        payload : {}
    },
    techItBox: {
        state: false,
        payload : {}
    },
    recommendApp: {
        state: false,
        payload : {}
    },
    topBannerBox: {
        state: false,
        payload : {}
    },
    footBannerBox: {
        state: false,
        payload : {}
    },
};

const mapStateToProps = state => ({
    todayTrend: state.SHOPPING.todayTrend,
    todayTrendFoot: state.SHOPPING.todayTrendFoot,
    mdRecommend: state.SHOPPING.mdRecommend,
    bestReview: state.SHOPPING.bestReview,
    bestReviewFootBox: state.SHOPPING.bestReviewFootBox,
    fashionBox: state.SHOPPING.fashionBox,
    fashionBoxFoot: state.SHOPPING.fashionBoxFoot,
    techItBox: state.SHOPPING.techItBox,
    recommendApp: state.SHOPPING.recommendApp,
    topBannerBox: state.SHOPPING.topBannerBox,
    footBannerBox: state.SHOPPING.footBannerBox,
});

const mapDispatchToProps = {
    getShoppingTodayTrend,
    getShoppingTodayTrendFoot,
    getShoppingMDRecommend,
    getShoppingBestReview,
    getShoppingBestReviewFoot,
    getShoppingFashionBox,
    getShoppingFashionBoxFoot,
    getShoppingTechItBox,
    getShoppingRecommendApp,
    getShoppingTopBnnerBox,
    getShoppingFootBnnerBox,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingComponent));
