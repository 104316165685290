
import * as ActionTypes from './ActionTypes';

export const testAction = () => ({
    type: ActionTypes.SHOW_LOADING_ACTION
});

/**
 *
 * @param {*} payload
 *
 * 현재 브라우져 gps 정보 체크 및 정보 가지고 오기
 */
export const getBrowserGeolocation = (payload) => ({
    type: ActionTypes.GET_BROWSER_GEOLOCATION,
    payload
});

/**
 *
 * @param {*} payload
 *
 * 구글 플레이스 api 호출
 */
export const getGooglePlaceList = (payload) => ({
    type: ActionTypes.GET_GOOGLE_PLACE_LIST,
    payload
});

/**
 *
 * @param {*} payload
 *
 * 뉴스 테이터 호출
 */
export const getFeedTopKeyWordList = () => ({
    type: ActionTypes.GET_FEED_TOP_KEYWORD_LIST,
});

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------


/* new work */
/**
 *
 * @param {*} payload
 *
 * Auth
 */
/* 인증코드 */
/* 발송 */
export const tryCertification = (params) => ({
    type: ActionTypes.TRY_CERTIFICATION,
    params:params
});
/* 확인 */
export const tryConfirm = (params) => ({
    type: ActionTypes.TRY_CONFIRM,
    params:params
});

/* 인증코드 end */

/* 전화번호 로그 서치 */
export const getLogPhoneNum = (params) => ({
    type: ActionTypes.GET_LOG_PHONE,
    params:params
});
/* 전화번호 로그 서치 end */
 
/* 토큰 서치 */
export const getBrowserToken = () => ({
    type: ActionTypes.GET_BROWSER_TOKEN
});
/* 토큰 서치 end */
/* 평범한 회원가입 */
export const tryDefaultJoinMember = (params) => ({
    type: ActionTypes.TRY_DEFAULT_JOIN_MEMBER,
    params:params
});
/* 평범한 회원가입 end */
/* 회원가입 */
export const tryJoinMember = (params) => ({
    type: ActionTypes.TRY_JOIN_MEMBER,
    params:params
});
/* 회원가입 end */

/* 비밀번호 변경 mypage */
export const tryUserChangePw = (params) => ({
    type: ActionTypes.TRY_USER_CHANGE_PW,
    params:params
});
/* 비밀번호 변경 end */
/* 비밀번호 변경 findpw */
export const tryDefaultChangePw = (params) => ({
    type: ActionTypes.TRY_DEFAULT_CHANGE_PW,
    params:params
});
/* 비밀번호 변경 end */

/* 비밀번호 체크 */
export const tryPasswordCheck = (params) => ({
    type: ActionTypes.TRY_PASSWORD_CHECK,
    params:params
});
/* 비밀번호 체크 end */

/* 회원탈퇴 */
export const tryWithdrawal = (params) => ({
    type: ActionTypes.TRY_WITHDRAWAL,
    params:params
});
/* 회원탈퇴 end */

/* 로그인 */
export const tryLoginUser = (params) => ({
    type: ActionTypes.TRY_LOGIN_USER,
    params:params
});
/* 로그인 end */

/* 내정보 */
export const getUserMyinfo = (params) => ({
    type: ActionTypes.GET_USER_MYINFO,
    params:params
});
/* 내정보 end */

/* 포인트 충전,사용 내역 */
export const getUserMypoint = (params) => ({
    type: ActionTypes.GET_USER_MYPOINT,
    params:params
});
/* 포인트 충전,사용 내역 end */

/* 1:1 문의 리스트 */
export const getOneononeList = (params) => ({
    type: ActionTypes.GET_ONE_INQUIRT,
    params:params
});
/* 1:1 문의 리스트 end */

/**
 *
 * @param {*} payload
 *
 * 날씨 데이터 호출
 */

export const getWeatherDetail = (citycode) => ({
    type: ActionTypes.GET_WEATHER_DETAIL,
    citycode:citycode
});

export const getFeedWeather = (citycode) => ({
    type: ActionTypes.GET_FEED_WEATHER,
    citycode:citycode
});

export const getWeatherWeek = (citycode) => ({
    type: ActionTypes.GET_WEATHER_WEEK,
    citycode:citycode
});

export const getWeatherHour = (citycode) => ({
    type: ActionTypes.GET_WEATHER_HOUR,
    citycode:citycode
});

export const getWeatherAddressResult = (keyword) => ({
    type: ActionTypes.GET_WEATHER_ADDRESS_RESULT,
    keyword:keyword
});

export const getWeatherHistoryList = (histories) => ({
    type: ActionTypes.GET_WEATHER_HISTORY_LIST,
    histories:histories
});

/**
 *
 * @param {*} payload
 *
 * 자동차 센션 정보
 */

/* section1 */
export const getCarSection1 = (payload) => ({
    type: ActionTypes.GET_CAR_SECTION1,
    payload:payload
});
/* section2 */
export const getCarSection2 = (payload) => ({
    type: ActionTypes.GET_CAR_SECTION2,
    payload:payload
});
/* section3 */
export const getCarSection3 = (payload) => ({
    type: ActionTypes.GET_CAR_SECTION3,
    payload:payload
});
/* section4 */
export const getCarSection4 = (payload) => ({
    type: ActionTypes.GET_CAR_SECTION4,
    payload:payload
});
/* section5 */
export const getCarSection5 = (payload) => ({
    type: ActionTypes.GET_CAR_SECTION5,
    payload:payload
});
/* section benner */
export const getCarBanner = (payload) => ({
    type: ActionTypes.GET_CAR_SECTION_BENNER,
    payload:payload
});

/* new work end */


/**
 *
 * @param {*} payload
 *
 * 뉴스 테이터 호출
 */
export const getFeedTopList = () => ({
    type: ActionTypes.GET_FEED_TOP_LIST,
});

/**
 *
 * @param {*} payload
 *
 * feed 상단 사진 리스트
 */
export const getFeedTopPhotoList = () => ({
    type: ActionTypes.GET_FEED_TOP_PHOTO_LIST,
});


/**
 *
 * @param {*} payload
 *
 * feed 상단 사진 리스트
 */
export const getFeedCenterBoxList = (category) => ({
    type: ActionTypes.GET_FEED_CENTER_BOX_LIST,
    category:category
});


/**
 *
 * @param {*} payload
 *
 * feed 상단 사진 리스트
 */
export const getFeedCenterGameMdBoxList = (category) => ({
    type: ActionTypes.GET_FEED_CENTER_GAME_MD_BOX_LIST,
    category:category
});


/**
 *
 * @param {*} payload
 *
 * feed 상단 사진 리스트
 */
export const getFeedTodayTrendBoxList = () => ({
    type: ActionTypes.GET_FEED_TODAY_TREND_BOX_LIST
});

/**
 *
 * 피드 하단 박스
 */
export const getFeedTodayTrendFootBoxList = () => ({
    type: ActionTypes.GET_FEED_TODAY_TREND_FOOT_BOX_LIST
});


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------
/**
 *
 *
 * 뉴스 메인 상단 속보 기사
 */
export const getNewsMainTopBulletin = () => ({
    type: ActionTypes.GET_NEWS_MAIN_TOP_BULLETIN
});

/**
 *
 *
 * 뉴스 메인 상단 박스
 */
export const getNewsMainTopBox = () => ({
    type: ActionTypes.GET_NEWS_MAIN_TOP_BOX
});

/**
 * 뉴스 메인 상단 포토 박스
 */
export const getNewsMainTopPhotoBox = () => ({
    type: ActionTypes.GET_NEWS_MAIN_TOP_PHOTO_BOX
});


/**
 * 뉴스 메인 중단 카테고리 뉴스 순위(?)
 */
export const getNewsMainCenterNewsBox = (category) => ({
    type: ActionTypes.GET_NEWS_MAIN_CENTER_BOX,
    category:category
});



/**
 * 뉴스 메인 헤드라인
 */
export const getNewsMainHeadLineBox = () => ({
    type: ActionTypes.GET_NEWS_MAIN_CENTER_HEADLINE
});


/**
 * 뉴스 메인 주요뉴스
 */
export const getNewsMainTodayNews = () => ({
    type: ActionTypes.GET_NEWS_MAIN_TODAY_NEWS
});


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------

/**
 * 쇼핑 투데이 트렌드
 */
export const getShoppingTodayTrend = () => ({
    type: ActionTypes.GET_SHOPPING_TODAY_TREND
});


/**
 * 쇼핑 투데이 트렌드 하단
 */
export const getShoppingTodayTrendFoot = () => ({
    type: ActionTypes.GET_SHOPPING_TODAY_TREND_FOOT
});

/**
 * 쇼핑 MD 추천
 */
export const getShoppingMDRecommend = () => ({
    type: ActionTypes.GET_SHOPPING_MD_RECOMMEND
});

/**
 * 쇼핑 베스트 리뷰
 */
export const getShoppingBestReview = () => ({
    type: ActionTypes.GET_SHOPPING_BEST_REVIEW
});

/**
 * 쇼핑 베스트 리뷰 하단
 */
export const getShoppingBestReviewFoot = () => ({
    type: ActionTypes.GET_SHOPPING_BEST_REVIEW_FOOT_BOX
});


/**
 * 쇼핑 패션
 */
export const getShoppingFashionBox = () => ({
    type: ActionTypes.GET_SHOPPING_FASHION_BOX
});


/**
 * 쇼핑 패션 하단
 */
export const getShoppingFashionBoxFoot = () => ({
    type: ActionTypes.GET_SHOPPING_FASHION_BOX_FOOT
});

/**
 * 쇼핑 테크 IT
 */
export const getShoppingTechItBox = () => ({
    type: ActionTypes.GET_SHOPPING_TECH_IT_BOX
});


/**
 * 쇼핑 추천 APP
 */
export const getShoppingRecommendApp = () => ({
    type: ActionTypes.GET_SHOPPING_RECOMMEND_APP
});

/**
 * 쇼핑 TOP 배너
 */
export const getShoppingTopBnnerBox = () => ({
    type: ActionTypes.GET_SHOPPING_TOP_BNNER_BOX
});

/**
 * 쇼핑 FOOT 배너
 */
export const getShoppingFootBnnerBox = () => ({
    type: ActionTypes.GET_SHOPPING_FOOT_BNNER_BOX
});
// --------------------------------------------------------------------------------------------------------------------------------------------


/**
 * 게임 상단 MD 추천
 */
export const getGameMDRecommend = () => ({
    type: ActionTypes.GET_GAME_MD_RECOMMEND
});

/**
 * 게임 새 게임 소개?
 */
export const getGameNewGameBox = () => ({
    type: ActionTypes.GET_GAME_NEW_GAME_BOX
});

/**
 * 게임 오피셜
 */
export const getGameOfficalBox = () => ({
    type: ActionTypes.GET_GAME_OFFICAL_BOX
});

/**
 * 게임 뉴스
 */
export const getGameNewsBox = () => ({
    type: ActionTypes.GET_GAME_NEWS_BOX
});

/**
 * 게임 리뷰
 */
export const getGameReviewBox = () => ({
    type: ActionTypes.GET_GAME_REVIEW_BOX
});


/**
 * 게임 상단 베너
 */
export const getGameTopBannerBox = () => ({
    type: ActionTypes.GET_GAME_TOP_BANNER_BOX
});


/**
 * 게임 하단 베너
 */
export const getGameFootBannerBox = () => ({
    type: ActionTypes.GET_GAME_FOOT_BANNER_BOX
});


// --------------------------------------------------------------------------------------------------------------------------------------------
/**
 *
 * @param {*} category
 *
 * 뉴스 서브 상단 포토 뉴스 리스트
 */
export const getNewsSubTopPhotobox = (category) => ({
    type: ActionTypes.GET_NEWS_SUB_TOP_PHOTOBOX,
    category:category
});

/**
 *
 * @param {*} category
 *
 * 뉴스 서브 하단 포토 뉴스 리스트
 */
export const getNewsSubFootCategoryPhotobox = (category) => ({
    type: ActionTypes.GET_NEWS_SUB_FOOT_CATEGORY_PHOTOBOX,
    category:category
});


/**
 *
 * @param {*} category
 *
 * 뉴스 서브 관심 있는 언론사
 */
export const getNewsSubNewsProvider = (category) => ({
    type: ActionTypes.GET_NEWS_SUB_NEWS_PROVIDER,
    category:category
});

/**
 *
 * @param {*}
 *
 * 광고/제휴 문의하기
 */
export const tryInquiryCreate = (payload) => ({
    type: ActionTypes.TRY_INQUIRY_CREATE,
    payload: payload
});

/**
 *
 * @param {*} sid
 *
 * 뉴스 컨텐츠
 */
export const getNewsContentProvider = (sid) => ({
    type: ActionTypes.GET_NEWS_SUB_CONTENT,
    sid:sid
});



/**
 *
 * @param {*} payload
 *
 * feed 상단 GameN 사진 리스트
 */
export const getFeedCenterGameNBoxList = (category) => ({
    type: ActionTypes.GET_FEED_CENTER_GAME_N_BOX_LIST,
    category:category
});



// --------------------------------------------------------------------------------------------------------------------------------------------


