import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from 'lib/Helper';
import Iframe from 'react-iframe'
import { Cookies } from 'react-cookie';

import {} from 'store/Actions';

import {} from 'elements';

const cookies = new Cookies();

export class WebtoonComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: (cookies.get('__token')) ? cookies.get('__token') : '',
            login_yn: (cookies.get('__useyn')) ? cookies.get('__useyn') : 0,
            user_id: '01048446721',
        };
    }

    componentDidMount(){
        //const ifram_h = document.getElementById('webtoonFrame').contentWindow.document.body.scrollHeight;
        //document.getElementById('webtoonFrame').height = ifram_h;

        var dummy = new Date().getTime();
        document.getElementById("webtoonFrame").src = "http://soda.aspmna.mtoon.com/auth/?userCode="+this.state.token+"&fw=" + window.innerWidth + "&fh=" + window.innerHeight + "&t=" + dummy;
      
        window.addEventListener('message', function(e) {
            if (e.data.height != undefined){    
            //console.log(e.data.height)      
                document.getElementById("webtoonFrame").style.height =e.data.height + 'px';
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        // console.debug({ name:'Component WILL RECIEVE PROPS!', nextProps: nextProps})
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.debug({ name:'ShouldComponentUpdate', nextProps: nextProps, nextState:nextState});
        return true;
    }

    componentWillUpdate(nextProps, nextState) {
        // console.debug({ name:'Component WILL UPDATE!' , nextProps: nextProps, nextState:nextState});
    }

    componentDidUpdate(prevProps, prevState) {
        // console.debug({ name:'Component DID UPDATE!' , prevProps: prevProps, prevState:prevState}
    }

    componentDidCatch(error, info) {
        // console.debug({ name:'Component DID Catch!' , error: error, info: info})
        //Handle error.
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        return (
            <div id="wrap">
                <div id="container">
                    <div style={{width:'100%', overflowX:'hidden'}}>
                        <Iframe url={"http://soda.aspmna.mtoon.com/auth/?userCode="+this.state.token}
                            width="100%"
                            scrolling="no"
                            id="webtoonFrame"
                            className="webtoonFrame"
                            display="initial"
                            position="relative"/>
                    </div>
                </div>
            </div>
        );
    }
};

WebtoonComponent.defaultProps = {
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(WebtoonComponent));
