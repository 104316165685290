import axios from 'axios';
// import jsonAdapter from 'axios-jsonp'
import * as Helper from 'lib/Helper';
// import _ from 'lodash';

// const CancelToken = axios.CancelToken;

const defaultAPIURL = (() => {
    const nowHostName = window.location.hostname;
    console.debug(process.env);
    if(process.env.REACT_APP_ENV === 'production_test') {
        return process.env.REACT_APP_PRODUCTION_API_URL;
    } else if(nowHostName === 'localhost') {
        return process.env.REACT_APP_DEVELOPMENT_API_URL;
    } else if(nowHostName === 'www-dev.t-page.co.kr') {
        return process.env.REACT_APP_DEVELOPMENT_API_URL;
    } else if(nowHostName === 'dev.t-page.co.kr') {
        return process.env.REACT_APP_DEVELOPMENT_API_URL;
    } else if(nowHostName === 'www.t-page.co.kr') {
        return process.env.REACT_APP_PRODUCTION_API_URL;
    } else if(nowHostName === 't-page.co.kr') {
        return process.env.REACT_APP_PRODUCTION_API_URL;
    } else if(nowHostName === 'wifi.t-page.co.kr') {
        return process.env.REACT_APP_PRODUCTION_API_URL;
    } else if(nowHostName === 'aws-wifi.t-page.co.kr') {
        return process.env.REACT_APP_PRODUCTION_AWS_API_URL;
    } else if(nowHostName === 'aws-web.t-page.co.kr') {
        return process.env.REACT_APP_PRODUCTION_AWS_API_URL;
    }
})();

export const defaultAxios = axios.create({
    baseURL: defaultAPIURL,
    timeout: process.env.REACT_APP_API_TIMEOUT,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
});

// Add a response interceptor
defaultAxios.interceptors.response.use(function (response) {
    // console.debug(response.data);
    // Helper.sleep(1000);
    // setTimeout(resolve, 1000);
    return response.data;
}, function (error) {
    Helper.DEBUG({name: 'server error', error:error});
    // const { status, statusText, data } = error.response;
    // const { name, result, message } = data;

    // return Promise.reject({
    //     status: status,
    //     statusText: statusText,
    //     data: {
    //         name: name,
    //         result: result,
    //         message: message
    //     }
    // });
});