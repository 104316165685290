import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Header,Footer } from 'components';
import * as Helper from 'lib/Helper';
import { Link } from "react-router-dom";
import { Cookies } from 'react-cookie';

import { tryLoginUser, getUserMyinfo, getBrowserToken } from 'store/Actions';

import {} from 'elements';
const cookies = new Cookies();

export class LoginComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: (cookies.get('__token')) ? cookies.get('__token') : '',
            login_yn: (cookies.get('__useyn')) ? cookies.get('__useyn') : 0,
            id: '',
            password: '',
        };
    }

    handleOnchange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    _loginAction = () => {
        let {
            id,
            password,
        } = this.state;

        if(id === '') {
            alert('아이디를 입력해주세요.')
            return false;
        }
        if(password === '') {
            alert('비밀번호를 입력해주세요.')
            return false;
        }

        let params = {
            id: id,
            password: password,
        }

        this.props.tryLoginUser(params);
    }

    componentDidMount(){
        if(this.state.token === '') {
            cookies.set('__useyn', 0, { path: '/' });
            this.props.getBrowserToken();
        } else {
            if(this.state.login_yn > 0) {
                const { token } = this.state;
                let parmas = {
                    token: token,
                }
                this.props.getUserMyinfo(parmas);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.debug({ name:'Component WILL RECIEVE PROPS!', nextProps: nextProps})
        if(nextProps.USER_MYINFO.state === true && this.props.USER_MYINFO.state !== nextProps.USER_MYINFO.state) {
            this.props.history.push('/');
        } else {
            if(nextProps.LOGIN_USER.state === true && this.props.LOGIN_USER.state !== nextProps.LOGIN_USER.state) {
                cookies.set('__token', nextProps.LOGIN_USER.payload.token, { path: '/' });
                cookies.set('__useyn', 1, { path: '/' });
                this.props.history.push('/');
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.debug({ name:'ShouldComponentUpdate', nextProps: nextProps, nextState:nextState});
        return true;
    }

    componentWillUpdate(nextProps, nextState) {
        // console.debug({ name:'Component WILL UPDATE!' , nextProps: nextProps, nextState:nextState});
    }

    componentDidUpdate(prevProps, prevState) {
        // console.debug({ name:'Component DID UPDATE!' , prevProps: prevProps, prevState:prevState}
    }

    componentDidCatch(error, info) {
        // console.debug({ name:'Component DID Catch!' , error: error, info: info})
        //Handle error.
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        return (
            <div id="wrap">
                <div id="container">
                    <div className="loginPage">
                        <div className="loginPageHeader">
                            <Link to="/">
                                <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/webtoon/before.svg'} />
                            </Link>
                            <div>로그인</div>
                        </div>
                        <div className="loginPageBody">
                            <div>
                                <div>
                                    <input type="text" name="id" onChange={this.handleOnchange} placeholder="  핸드폰번호" />
                                </div>
                                <div>
                                    <input type="password" name="password" onChange={this.handleOnchange} placeholder="  비밀번호" />
                                </div>
                            </div>
                            <div>
                                <button type="button" onClick={() => this._loginAction()}>로그인</button>
                            </div>
                            <div>
                                <Link to="/find_pw">비밀번호 찾기</Link>
                                <span>|</span>
                                <Link to="/default_join">회원가입</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => ({
    LOGIN_USER: state.LOGIN_USER,
    USER_MYINFO: state.USER_MYINFO,
    BROWSER_TOKEN: state.BROWSER_TOKEN,
});

const mapDispatchToProps = {
    tryLoginUser,
    getUserMyinfo,
    getBrowserToken,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginComponent));
