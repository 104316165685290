import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Header,Footer } from 'components';
import * as Helper from 'lib/Helper';
import { Link } from "react-router-dom";
import { Cookies } from 'react-cookie';
import queryString from "query-string";

import { tryJoinMember, getLogPhoneNum } from 'store/Actions';

import {} from 'elements';
const cookies = new Cookies();

export class JoinComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: (cookies.get('__token')) ? cookies.get('__token') : '',
            orderid: (this.props.location.search) ? queryString.parse(this.props.location.search).ORDERID : '',
            uid: '',
            id: '',
            code: '',
            password: '',
            password_check: '',
            terms_check: false,
        };
    }

    handleOnchange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    toggleTermsCheck = () => {
        let terms = (this.state.terms_check) ? false : true;
        this.setState({
            terms_check: terms
        });
    }

    _joinAction = () => {
        let {
            token,
            uid,
            id,
            password,
            password_check,
            terms_check,
        } = this.state;

        let params = {
            token: token,
            uid,
            id: id,
            password: password.trim(),
            password_check: password_check.trim(),
            terms_check: terms_check,
        }

        this.props.tryJoinMember(params);
    }

    componentDidMount(){
        // console.debug({ name:'componentDidMount'});
        // this._getCarSection1();
        if(this.state.orderid !== '') {
            let { orderid } = this.state;
            let params = {params: { order_id : orderid }};

            this.props.getLogPhoneNum(params);
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.debug({ name:'Component WILL RECIEVE PROPS!', nextProps: nextProps})
        if(nextProps.LOG_PHONE.state === true && this.props.LOG_PHONE.state !== nextProps.LOG_PHONE.state) {
            this.setState({
                uid: nextProps.LOG_PHONE.payload.uid,
                id: nextProps.LOG_PHONE.payload.phone,
                code: nextProps.LOG_PHONE.payload.phone_code,
            });
        }
        if(nextProps.JOIN_MEMBER.state === true && this.props.JOIN_MEMBER.state !== nextProps.JOIN_MEMBER.state) {
            alert('환영합니다.');
            this.props.history.push('/login');
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.debug({ name:'ShouldComponentUpdate', nextProps: nextProps, nextState:nextState});
        return true;
    }

    componentWillUpdate(nextProps, nextState) {
        // console.debug({ name:'Component WILL UPDATE!' , nextProps: nextProps, nextState:nextState});
    }

    componentDidUpdate(prevProps, prevState) {
        // console.debug({ name:'Component DID UPDATE!' , prevProps: prevProps, prevState:prevState}
    }

    componentDidCatch(error, info) {
        // console.debug({ name:'Component DID Catch!' , error: error, info: info})
        //Handle error.
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        return (
            <div id="wrap">
                <div id="container">
                    <div className="joinPage">
                        <div className="joinPageHeader">
                            <Link to="/login">
                                <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/webtoon/before.svg'} />
                            </Link>
                            <div>회원가입</div>
                        </div>
                        <div className="joinPageBody">
                            <div className="joinPageBodyInfo">
                                <dl>
                                    <dt>환영합니다. {this.state.code}님!</dt>
                                    <dd>ID: {this.state.id}</dd>
                                </dl>
                            </div>
                            <div className="joinPageBodyForm">
                                <p className="precautions"> *비밀번호를 입력해주세요. (6자리이상) </p>
                                <div className="passwordForm">
                                    <div>
                                        <div>
                                            <span>비밀번호</span>
                                        </div>
                                        <div>
                                            <input type="password" name="password" onChange={this.handleOnchange} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <span>비밀번호 확인</span>
                                        </div>
                                        <div>
                                            <input type="password" name="password_check" onChange={this.handleOnchange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="actionFrom">
                                    <div>
                                        <input type="checkbox" name="terms_check" id="terms_check" onClick={this.toggleTermsCheck} />
                                        <label htmlFor="terms_check"></label>
                                        <span><a href="/tos" target="_blank">이용약관</a>, <a href="/privacypolicy" target="_blank">개인정보처리방침</a> 동의</span>
                                    </div>
                                </div>
                            </div>
                            <div className="joinPageBodyButton">
                                <button type="button" onClick={() => this._joinAction()}>확인</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => ({
    LOG_PHONE: state.LOG_PHONE,
    JOIN_MEMBER: state.JOIN_MEMBER,
});

const mapDispatchToProps = {
    tryJoinMember,
    getLogPhoneNum
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(JoinComponent));
