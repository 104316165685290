import React, { Component } from "react";
import ReactDOM from 'react-dom'
// import * as Helper from 'lib/Helper';

export class MobionBanner extends Component {

    constructor() {
        super();
        this.state = {
            iFrameHeight: '0px'
        }
    }

    render() {
        
        if(this.props.BANNER_TYPE === "FEED_TOP"){
            const iframeURL = './iframe/banner/mobion/feed_320x100_top.html';
            return (
                <iframe
                title="feed 320x100 top banner"
                    style={{width:'100%', height:'110px', overflow:'visible'}}
                    onLoad={() => {
                        const obj = ReactDOM.findDOMNode(this);
                        this.setState({
                            "iFrameHeight":  obj.contentWindow.document.body.scrollHeight + 'px'
                        });
                    }}
                    ref="iframe"
                    src={iframeURL}
                    width="100%"
                    height={this.state.iFrameHeight}
                    scrolling="no"
                    frameBorder="0"
                />
            );
        } else if(this.props.BANNER_TYPE === "FEED_BOTTOM"){
            const iframeURL = './iframe/banner/mobion/feed_320x100_bottom.html';
            return (
                <iframe
                title="feed 320x100 bottom banner"
                    style={{width:'100%', height:'110px', overflow:'visible'}}
                    onLoad={() => {
                        const obj = ReactDOM.findDOMNode(this);
                        this.setState({
                            "iFrameHeight":  obj.contentWindow.document.body.scrollHeight + 'px'
                        });
                    }}
                    ref="iframe"
                    src={iframeURL}
                    width="100%"
                    height={this.state.iFrameHeight}
                    scrolling="no"
                    frameBorder="0"
                />
            );
        } else if(this.props.BANNER_TYPE === "NEWS_TOP"){
            const iframeURL = '../../iframe/banner/mobion/news_320x100_top.html';
            return (
                <iframe
                title="news 320x100 top banner"
                    style={{width:'100%', height:'110px', overflow:'visible'}}
                    onLoad={() => {
                        const obj = ReactDOM.findDOMNode(this);
                        this.setState({
                            "iFrameHeight":  obj.contentWindow.document.body.scrollHeight + 'px'
                        });
                    }}
                    ref="iframe"
                    src={iframeURL}
                    width="100%"
                    height={this.state.iFrameHeight}
                    scrolling="no"
                    frameBorder="0"
                />
            );
        } else if(this.props.BANNER_TYPE === "NEWS_MIDDLE"){
            const iframeURL = '../../iframe/banner/mobion/news_320x100_middle.html';
            return (
                <iframe
                title="news 320x100 middle banner"
                    style={{width:'100%', height:'110px', overflow:'visible'}}
                    onLoad={() => {
                        const obj = ReactDOM.findDOMNode(this);
                        this.setState({
                            "iFrameHeight":  obj.contentWindow.document.body.scrollHeight + 'px'
                        });
                    }}
                    ref="iframe"
                    src={iframeURL}
                    width="100%"
                    height={this.state.iFrameHeight}
                    scrolling="no"
                    frameBorder="0"
                />
            );
        } else if(this.props.BANNER_TYPE === "NEWS_BOTTOM"){
            const iframeURL = '../../iframe/banner/mobion/news_320x100_bottom.html';
            return (
                <iframe
                title="news 320x100 bottom banner"
                    style={{width:'100%', height:'110px', overflow:'visible'}}
                    onLoad={() => {
                        const obj = ReactDOM.findDOMNode(this);
                        this.setState({
                            "iFrameHeight":  obj.contentWindow.document.body.scrollHeight + 'px'
                        });
                    }}
                    ref="iframe"
                    src={iframeURL}
                    width="100%"
                    height={this.state.iFrameHeight}
                    scrolling="no"
                    frameBorder="0"
                />
            );
        } else if(this.props.BANNER_TYPE === "MAP"){
            const iframeURL = './iframe/banner/mobion/map_320x100.html';
            return (
                <iframe
                title="map 320x100 banner"
                    style={{width:'100%', height:'110px', overflow:'visible'}}
                    onLoad={() => {
                        const obj = ReactDOM.findDOMNode(this);
                        this.setState({
                            "iFrameHeight":  obj.contentWindow.document.body.scrollHeight + 'px'
                        });
                    }}
                    ref="iframe"
                    src={iframeURL}
                    width="100%"
                    height={this.state.iFrameHeight}
                    scrolling="no"
                    frameBorder="0"
                />
            );
        } else {
            return (
                <div></div>
            )
        }
    }
};

export default MobionBanner;
