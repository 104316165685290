import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Header,Footer } from 'components';
import * as Helper from 'lib/Helper';
import { Link } from "react-router-dom";
import { Cookies } from 'react-cookie';

import { getUserMyinfo } from 'store/Actions';

const cookies = new Cookies();
export class WithdrawalFirstComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: (cookies.get('__token')) ? cookies.get('__token') : '',
        };
    }

    handleOnchange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    componentDidMount(){
        // console.debug({ name:'componentDidMount'});
        if(this.state.token === '') {
            window.location.href = '/';
        } else {
            const { token } = this.state;
            let parmas = {
                token: token,
            }
            this.props.getUserMyinfo(parmas);
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.debug({ name:'Component WILL RECIEVE PROPS!', nextProps: nextProps})
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.debug({ name:'ShouldComponentUpdate', nextProps: nextProps, nextState:nextState});
        return true;
    }

    componentWillUpdate(nextProps, nextState) {
        // console.debug({ name:'Component WILL UPDATE!' , nextProps: nextProps, nextState:nextState});
    }

    componentDidUpdate(prevProps, prevState) {
        // console.debug({ name:'Component DID UPDATE!' , prevProps: prevProps, prevState:prevState}
    }

    componentDidCatch(error, info) {
        // console.debug({ name:'Component DID Catch!' , error: error, info: info})
        //Handle error.
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        return (
            <div id="wrap">
                <div id="container">
                    <div className="withdrawalPage">
                        <div className="withdrawalPageHeader">
                            <Link to="/mypage">
                                <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/webtoon/before.svg'} />
                            </Link>
                            <div>회원탈퇴</div>
                        </div>
                        <div className="withdrawalPageBody">
                            <h2>
                                <span>회원탈퇴시 안내사항</span>
                            </h2>
                            <div>
                                <b>회원탈퇴를 신청하기 전에 안내 사항을 꼭 확인해주세요.</b>
                            </div>
                            <ul>
                                <li>- 탈퇴 후 재가입하시면 기존 <b>포인트 및 쿠폰이 복구가 불가</b>할 수 있사오니 신중하게 선택하시기 바랍니다.</li>
                                <li>- 탈퇴 후 회원정보 및 개인형 서비스 이용기록은 모두 삭제됩니다.</li>
                            </ul>
                        </div>
                        <div className="withdrawalPageConf">
                            <div className="actionFrom">
                                <Link to="/withdrawal/second">
                                    <input type="checkbox" name="terms_check" id="terms_check" onClick={this.toggleTermsCheck} />
                                    <label htmlFor="terms_check"></label>
                                    <span><b>안내 사항을 모두 확인하였으며, 이에 동의합니다.</b></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => ({
    USER_MYINFO: state.USER_MYINFO,
});

const mapDispatchToProps = {
    getUserMyinfo,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(WithdrawalFirstComponent));
