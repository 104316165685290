import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import ScrollMenu from 'react-horizontal-scrolling-menu';

import './sub_menu.css?1';

const list = [
    { name: 'item9999', menu_name: '전체', menu_code:9999 },
    { name: 'item1', menu_name: '경제', menu_code:1001 },
    { name: 'item2', menu_name: '정치', menu_code:1002 },
    { name: 'item3', menu_name: '사회', menu_code:1003 },
    { name: 'item4', menu_name: '연예', menu_code:1004 },
    { name: 'item5', menu_name: '스포츠', menu_code:1005 },
    { name: 'item10', menu_name: '국제', menu_code:1010 },
    { name: 'item12', menu_name: '문화', menu_code:1012 },
    { name: 'item16', menu_name: '게임', menu_code:1016 },    
    { name: 'item15', menu_name: '산업', menu_code:1015 },
    { name: 'item17', menu_name: '사설', menu_code:1017 },
    { name: 'item9', menu_name: '생활', menu_code:1009 },
    { name: 'item7', menu_name: '야구', menu_code:1007 },
    { name: 'item13', menu_name: '영상', menu_code:1013 },
    { name: 'item8', menu_name: '축구', menu_code:1008 },
    { name: 'item14', menu_name: '포토', menu_code:1014 },
    { name: 'item6', menu_name: 'IT', menu_code:1006 },
];

const MenuItem = ({text, menu_name, menu_code, selected}) => {
    // return <div
    //     className={`menu-item ${selected ? 'active' : ''}`}
    // >{text}</div>;
    // console.debug(selected);
    return <div className={`deps2-menu-item ${selected ? 'active' : ''}`}>
            <Link to={{ pathname: '/news/sub/'+menu_code, state: '/news/sub/'+menu_code }}><span>{menu_name}</span></Link>
        </div>
};

export const Menu = (list, selected) =>
    list.map(el => {
        const {name, menu_name, menu_code} = el;

        return <MenuItem text={name} menu_name={menu_name} menu_code={menu_code} key={name} selected={selected} />;
    });




export class NewsSubMenu extends Component {

    constructor(props) {

        super(props);

        this.state = {
            selected : null
        }

        this.menuItems = Menu(list, this.state.selected);
    }

    onSelect = key => {
        this.setState({ selected: key });

        // console.debug(this.state);
    }

    componentDidMount() {
        const newsCategory = this.props.match.params.news_cateory;

        switch (newsCategory) {
            case '1001':
                this.setState({ selected: 'item1' });
                break;
            case '1002':
                this.setState({ selected: 'item2' });
                break;
            case '1003':
                this.setState({ selected: 'item3' });
                break;
            case '1004':
                this.setState({ selected: 'item4' });
                break;
            case '1005':
                this.setState({ selected: 'item5' });
                break;
            case '1006':
                this.setState({ selected: 'item6' });
                break;
            case '1007':
                this.setState({ selected: 'item7' });
                break;
            case '1008':
                this.setState({ selected: 'item8' });
                break;
            case '1009':
                this.setState({ selected: 'item9' });
                break;
            case '1010':
                this.setState({ selected: 'item10' });
                break;
            case '1011':
                this.setState({ selected: 'item11' });
                break;
            case '1012':
                this.setState({ selected: 'item12' });
                break;
            case '1013':
                this.setState({ selected: 'item13' });
                break;
            case '1014':
                this.setState({ selected: 'item14' });
                break;
            case '1015':
                this.setState({ selected: 'item15' });
                break;
            case '1016':
                this.setState({ selected: 'item16' });
                break;
            case '1017':
                this.setState({ selected: 'item17' });
                break;
            case '1018':
                this.setState({ selected: 'item18' });
                break;
            case '1019':
                this.setState({ selected: 'item19' });
                break;
            case '9999':
                this.setState({ selected: 'item9999' });
                break;
            default:
                this.setState({ selected: 'item9999' });
                break;
        }
    }

    render() {

        const { selected } = this.state;
        const menu = this.menuItems;

        return (
            <div className="dp2">
                <ScrollMenu
                    data={menu}
                    wheel={false}
                    selected={selected}
                    scrollToSelected={true}
                    onSelect={this.onSelect}
                    alignCenter={false}
                    arrowDisabledClass={true}
                    hideSingleArrow={true}
                    hideArrows={true}
                />
             </div>
         );
    }
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsSubMenu));