import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

export class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_uid: false,
        }
    }

    componentDidMount() {

        //if (!document.getElementById('interworksmedia')) {


        const interworksmediaJS = document.createElement('script');
        interworksmediaJS.id = 'interworksmedia';
        interworksmediaJS.src = '//cdn.interworksmedia.co.kr/js/tm/aip/latest/aip.js';
        document.body.appendChild(interworksmediaJS);

        //}
    }

    render() {
            return (
                <footer id="footer">
                    <div className="links">
                        <span><Link to="/tos" onClick={ (e) => { window.scrollTo(0, 0); }}>이용약관</Link></span>
                        <span><Link to="/privacypolicy" onClick={ (e) => { window.scrollTo(0, 0); }}>개인정보처리방침</Link></span>
                        <span><Link to="/inquiry" onClick={ (e) => { window.scrollTo(0, 0); }}>광고/제휴문의</Link></span>
                    </div>
                    {/* <div className="privacy">
                    </div> */}
                    <small>
                        (주)메타큐브 | 대표이사 : 유재범<br></br>
                        서울특별시 강남구 논현로 63길 71 (주)메타큐브<br></br>
                        통신판매번호 : 제2018-서울강남-02815호<br></br>
                        Copyright &copy; META CUBE. All rights reserved
                    </small>
                </footer>
            );
    }
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer));