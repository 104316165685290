import React, { Component } from "react";
import ReactDOM from 'react-dom'

export class GoogleSearchIframe extends Component {

    constructor() {
        super();
        this.state = {
            iFrameHeight: '0px'
        }
    }

    render() {

        const iframeURL = './iframe/googlesearch/googlesearch.html?q='+this.props.searchString;

        return (
            <iframe
            title="google search result"
                style={{width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}
                onLoad={() => {
                    const obj = ReactDOM.findDOMNode(this);
                    this.setState({
                        "iFrameHeight":  obj.contentWindow.document.body.scrollHeight + 'px'
                    });
                }}
                ref="iframe"
                src={iframeURL}
                width="100%"
                height={this.state.iFrameHeight}
                scrolling="no"
                frameBorder="0"
            />
        );
    }
};


export default GoogleSearchIframe;