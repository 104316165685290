import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Header,Footer } from 'components';
import * as Helper from 'lib/Helper';
import { Link } from "react-router-dom";
import { Cookies } from 'react-cookie';

import { getUserMypoint } from 'store/Actions';

import {} from 'elements';

const cookies = new Cookies();
export class TpointComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: (cookies.get('__token')) ? cookies.get('__token') : '',
            changetab: 'charge',
        };
    }

    _changeTabForm = (val) => {
        this.setState({
            changetab: val
        })
    }

    componentDidMount(){
        // console.debug({ name:'componentDidMount'});
        if(this.state.token === '') {
        } else {
            const { token } = this.state;
            let parmas = {
                token: token,
            }
            this.props.getUserMypoint(parmas);
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.debug({ name:'Component WILL RECIEVE PROPS!', nextProps: nextProps})
        if(nextProps.USER_MYPOINT.state === true && this.props.USER_MYPOINT.state !== nextProps.USER_MYPOINT.state) {
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.debug({ name:'ShouldComponentUpdate', nextProps: nextProps, nextState:nextState});
        return true;
    }

    componentWillUpdate(nextProps, nextState) {
        // console.debug({ name:'Component WILL UPDATE!' , nextProps: nextProps, nextState:nextState});
    }

    componentDidUpdate(prevProps, prevState) {
        // console.debug({ name:'Component DID UPDATE!' , prevProps: prevProps, prevState:prevState}
    }

    componentDidCatch(error, info) {
        // console.debug({ name:'Component DID Catch!' , error: error, info: info})
        //Handle error.
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        var tabCargeStyle = {borderBottom: (this.state.changetab === 'charge') ? "2px solid #333" : "",
                        color: (this.state.changetab === 'charge') ? "#333" : ""};
        var tabUsageStyle = {borderBottom: (this.state.changetab === 'usage') ? "2px solid #333" : "",
                        color: (this.state.changetab === 'usage') ? "#333" : ""};
        return (
            <div id="wrap">
                <div id="container">
                    <div className="tpointPage">
                        <div className="tpointPageHeader">
                            <Link to="/">
                                <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/webtoon/before.svg'} />
                            </Link>
                            <div>T-page 포인트</div>
                        </div>
                        <div className="tpointPageTab">
                            <button onClick={ () => {this._changeTabForm('charge')}} style={tabCargeStyle}>포인트 충전내역</button>
                            <button onClick={ () => {this._changeTabForm('usage')}} style={tabUsageStyle}>포인트 사용내역</button>
                        </div>
                        <div className="tpointPageBody">
                            <div className="historyList charge" style={{display: (this.state.changetab === 'charge') ? 'block' : 'none'}}>
                                <ul>
                                    {this.props.USER_MYPOINT.payload ? this._renderPointPluseList() : <ul><li></li></ul>}
                                </ul>
                            </div>
                            <div className="historyList usage" style={{display: (this.state.changetab === 'usage') ? 'block' : 'none'}}>
                                <ul>
                                    {this.props.USER_MYPOINT.payload ? this._renderPointMinusList() : <ul><li></li></ul>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderPointPluseList = () => {
        let list = <ul><li></li></ul>;
        const data = this.props.USER_MYPOINT.payload.point_use;

        if (data && data.length > 0){
            list = data.map((item, i) => {
                if(item.type == 1) {
                    return <li key={i}>
                        <div>{item.created_time}</div>
                        <div>{item.point}P</div>
                    </li>
                }
            })
        }
        return list;
    }
    _renderPointMinusList = () => {
        let list = <ul><li></li></ul>;
        const data = this.props.USER_MYPOINT.payload.point_use;

        if (data && data.length > 0){
            list = data.map((item, i) => {
                if(item.type == 0) {
                    return <li key={i}>
                        <div>
                            <div>{item.created_time}</div>
                            <div>{item.title}</div>
                        </div>
                        <div>{item.point}</div>
                    </li>
                }
            })
        }
        return list;
    }
};

const mapStateToProps = state => ({
    USER_MYPOINT: state.USER_MYPOINT,
});

const mapDispatchToProps = {
    getUserMypoint,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TpointComponent));
