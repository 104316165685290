import React from "react";
import { Link } from "react-router-dom";
import * as Helper from 'lib/Helper';
import * as GlobalLink from 'lib/GlobalLink'
import styled from 'styled-components';
import { Cookies } from 'react-cookie';
import { Slide, Fade } from 'react-slideshow-image';

export const CarSection1Box = ({
    CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state) {
        if(CONTENTS_LIST.payload === null) {
            return false;
        }
        return (
            <div className="listImg">
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        return (
                            <Link key={i} to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}  }>
                                <img src={item.img_path} alt="" style={{width:"100%"}} />
                            </Link>
                        );
                    })
                }
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};

export const CarSection2Box = ({
    CONTENTS_LIST,
    CONTENTS_PAGE
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state) {
        const content_list = Helper.paginate(CONTENTS_LIST.payload, 3, CONTENTS_PAGE);
        if(CONTENTS_LIST.payload === null) {
            return false;
        }
        return (
            <ul className="newsType">
                {
                    content_list.map((item, i) => {
                        return (
                            <li key={i}>
                                <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}  }>
                                    <span className="thumb">
                                        <img src={item.img_path} alt=""/>
                                    </span>
                                    <span className="tit">{item.title}</span>
                                    <span className="opt">{item.tag}</span>
                                </Link>
                            </li>
                        );
                    })
                }
            </ul>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};

export const CarSection3Box = ({
    CONTENTS_LIST,
    CONTENTS_PAGE
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state) {
        const content_list = Helper.paginate(CONTENTS_LIST.payload, 4, CONTENTS_PAGE);
        if(CONTENTS_LIST.payload === null) {
            return false;
        }
        return (
            <div>
                {
                    content_list.map((item, i) => {
                        return (
                            <ul key={i}>
                                <li>
                                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}  }>
                                        <span className="thumb"><img src={item.img_path} alt="" style={{width:'100%'}}/></span>
                                        <span className="txt">{item.title}</span>
                                    </Link>
                                </li>
                            </ul>
                        );
                    })
                }
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};

export const CarSection4Box = ({
    CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state) {
        if(CONTENTS_LIST.payload === null) {
            return false;
        }
        return (
            <div className="listImg">
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        return (
                            <Link key={i} to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}  }>
                                <img src={item.img_path} alt="" style={{width:"100%"}} />
                            </Link>
                        );
                    })
                }
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};

export const CarSection5Box = ({
    CONTENTS_LIST,
    CONTENTS_TYPE
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state) {
        if(CONTENTS_LIST.payload === null) {
            return false;
        }
        return (
            <ul className="newsType">
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        return (
                            <li key={i} style={{display: (item.cate === CONTENTS_TYPE) ? 'block' : 'none' }}>
                                <Link key={i} to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}  }>
                                    <span className="thumb"><img src={item.img_path} alt="" style={{width:"100%"}} /></span>
                                    <span className="tit" style={{ fontWeight:'400' }}>{item.title}</span>
                                    <span className="opt">{item.tag}</span>
                                </Link>
                            </li>
                        );
                    })
                }
            </ul>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};

export const CarSection1BennerBox = ({
    CONTENTS_LIST
}) => {
    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    const fadeProperties2 = {
        duration: 5000,
        transitionDuration: 500,
        infinite: true,
        indicators: false,
        arrows: false,
        onChange: (oldIndex, newIndex) => {
        }
    }

    if(CONTENTS_LIST.state === true) {
        if(CONTENTS_LIST.payload === null) {
            return false;
        }
        return (
            <div className="slide-container" style={{margin:'0 -1.5rem 2.3rem'}}>
                <Fade {...fadeProperties2}>
                    {
                    CONTENTS_LIST.payload.map((item, i) => {
                        const urlStyle = {
                            width: '100%',
                            height: '100%',
                            backgroundSize: '100% 100%',
                        }
                        const hiddenStyle = {
                            width: '0.1px',
                            height: '0.1px'
                        }
                        return (
                            <div className="each-fade" key={i}>
                                <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}  }>
                                    <img src={item.img_path} style={urlStyle} />
                                    {/* <img src={item.imp_url} style={hiddenStyle} /> */}
                                </Link>
                            </div>
                        )
                    })
                    }
                </Fade>
            </div>
        );
    } else {
        return (
            <div>
            </div>
        );
    }
};

export const CarSection4BennerBox = ({
    CONTENTS_LIST
}) => {
    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    const fadeProperties2 = {
        duration: 5000,
        transitionDuration: 500,
        infinite: true,
        indicators: false,
        arrows: false,
        onChange: (oldIndex, newIndex) => {
        }
    }

    if(CONTENTS_LIST.state === true) {
        if(CONTENTS_LIST.payload === null) {
            return false;
        }
        return (
            <div className="banner">
                <div className="slide-container">
                    <Fade {...fadeProperties2}>
                        {
                        CONTENTS_LIST.payload.map((item, i) => {
                            const urlStyle = {
                                width: '100%',
                                height: '100%',
                                backgroundSize: '100% 100%',
                            }
                            const hiddenStyle = {
                                width: '0.1px',
                                height: '0.1px'
                            }
                            return (
                                <div className="each-fade" key={i}>
                                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}  }>
                                        <img src={item.img_path} style={urlStyle} />
                                        {/* <img src={item.imp_url} style={hiddenStyle} /> */}
                                    </Link>
                                </div>
                            )
                        })
                        }
                    </Fade>
                </div>
            </div>
        );
    } else {
        return (
            <div>
            </div>
        );
    }
};