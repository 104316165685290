import React, { Component } from 'react';
import {
    Route,
    Switch,
} from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    MainComponent,
    HomeComponent,
    FeedComponent,
    NewsMainComponent,
    NewsSubComponent,
    NewsContentComponent,
    GameComponent,
    ShoppingComponent,
    MapComponent,
    SearchComponent,
    ToSComponent,
    PrivacyPolicyComponent,
    InquiryComponent,
    Test,

    WeatherDetailComponent,
    WeatherSearchComponent,
    WeatherSearchHistoryComponent,
    CarComponent,
    WebtoonComponent,
    /* member */
    DefaultJoinComponent,
    JoinComponent,
    FindPasswordComponent,
    LoginComponent,
    MypageComponent,
    TpointComponent,
    WithdrawalFirstComponent,
    WithdrawalSecondComponent,
    /* member end */
    OneinquiryComponent,
} from 'components';
import ReactGA from 'react-ga';
import { Header,Footer } from 'components';
import PointComponent from 'components/PointComponent';

class Root extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    _handleHostProtocol = () => {

        let nowHostName = window.location.hostname;
        let x = window.location.protocol;

        //console.log(nowHostName);
        
        if((nowHostName === 'http://wifi.t-page.co.kr' || nowHostName === 'wifi.t-page.co.kr') && x !== 'https:') {
            let changeURL = 'https://'+nowHostName+''+this.props.location.pathname;
            window.location.href = changeURL;
        }

        if((nowHostName === 'www.t-page.co.kr' || nowHostName === 't-page.co.kr' || nowHostName === 'http://t-page.co.kr') && x !== 'https:') {
            let changeURL = 'https://'+nowHostName+''+this.props.location.pathname;
            window.location.href = changeURL;
        }


        // if ( x === 'http:' &&  this.props.location.pathname==='/car') {
        //     let changeURL = 'http://'+nowHostName+''+this.props.location.pathname;
        //     window.location.href = changeURL;
        //     //let changeURL = 'http://'+nowHostName+':3000/webtoon';
        // }

        //let changeURL = 'http://'+nowHostName+':3000/webtoon';
        //window.history.pushState({'page_id': 1, 'user_id': 5 }, "웹툰", changeURL);

        


    }

    componentWillMount() {
        this._handleHostProtocol();
    }

    componentDidMount() {
        // console.debug({ name:'Component DID MOUNT!', state: this.state})
    }

    componentWillReceiveProps(nextProps) {
        // console.debug({ name:'Component WILL RECIEVE PROPS!', nextProps: nextProps})
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.debug({ name:'ShouldComponentUpdate', nextProps: nextProps, nextState:nextState});

        return true;
    }

    componentWillUnmount() {
        // console.debug({ name:'Component WILL UNMOUNT!' , state: this.state})
    }


    componentWillUpdate(nextProps, nextState) {
        // console.debug({ name:'Component WILL UPDATE!' , nextProps: nextProps, nextState:nextState})
        this._handleHostProtocol();
    }

    componentDidUpdate(prevProps, prevState) {
        // console.debug({ name:'Component DID UPDATE!' , prevProps: prevProps, prevState:prevState})
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    componentDidCatch(error, info) {
        // console.debug({ name:'Component DID Catch!' , error: error, info: info})
        //Handle error.
    }

    render() {
        const pathArr = ['/default_join', '/join', '/login',
                         '/mypage', '/tpoint', '/find_pw',
                         '/withdrawal/first', '/withdrawal/second'];

        if(pathArr.includes(this.props.location.pathname)) {
            return (
                <div>
                    <Switch>
                        /* member */
                        <Route exact path='/default_join' component={ DefaultJoinComponent }/>
                        <Route exact path='/join' component={ JoinComponent }/>
                        <Route exact path='/find_pw' component={ FindPasswordComponent }/>
                        <Route exact path='/login' component={ LoginComponent }/>
                        <Route exact path='/mypage' component={ MypageComponent }/>
                        <Route exact path='/tpoint' component={ TpointComponent }/>
                        <Route exact path='/withdrawal/first' component={ WithdrawalFirstComponent }/>
                        <Route exact path='/withdrawal/second' component={ WithdrawalSecondComponent }/>
                        /* member end */
                    </Switch>
                </div>
            );
        } else {
            return (
                <div>
                    <Header/>
                    <Switch>
                        {/* <Route exact path='/' component={ MainComponent }/> */}
                        <Route exact path='/' component={ FeedComponent }/>
                        <Route exact path='/main' component={ MainComponent }/>
                        <Route exact path='/home' component={ HomeComponent }/>
                        <Route exact path='/feed' component={ FeedComponent }/>
                        <Route exact path='/news' component={ NewsMainComponent }/>
                        <Route exact path='/game' component={ GameComponent }/>
                        <Route exact path='/shopping' component={ ShoppingComponent }/>
                        <Route exact path='/map' component={ MapComponent }/>
                        <Route exact path='/search' component={ SearchComponent }/>
                        <Route exact path='/news/sub/:news_cateory' component={ NewsSubComponent }/>
                        <Route exact path='/news/content/:news_sid' component={ NewsContentComponent }/>
                        <Route exact path='/tos' component={ ToSComponent }/>
                        <Route exact path='/privacypolicy' component={ PrivacyPolicyComponent }/>
                        <Route exact path='/inquiry' component={ InquiryComponent }/>
                        <Route exact path='/test' component={ Test }/>

                        <Route exact path='/weather/detail' component={ WeatherDetailComponent }/>
                        <Route exact path='/weather/search' component={ WeatherSearchComponent }/>
                        <Route exact path='/weather/search/history' component={ WeatherSearchHistoryComponent }/>
                        <Route exact path='/car' component={ CarComponent }/>
                        <Route exact path='/webtoon' component={ WebtoonComponent }/>
                        <Route exact path='/oneonone' component={ OneinquiryComponent }/>
                        <Route exact path='/point' component={ PointComponent }/>
                    </Switch>
                    <Footer/>
                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Root));
