/* eslint-disable no-undef */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from 'lib/Helper';
import { Cookies } from 'react-cookie';
import * as GlobalLink from 'lib/GlobalLink'
import { Swiper, Slide } from 'react-dynamic-swiper';
import 'react-dynamic-swiper/lib/styles.css';
import IconScrollMenu from 'react-horizontal-scrolling-menu';

import {
    //getFeedTopKeyWordList,
    getFeedTopList,
    getFeedTopPhotoList,
    getFeedCenterBoxList,
    getFeedCenterGameMdBoxList,
    getFeedCenterGameNBoxList,
    getFeedTodayTrendBoxList,
    getFeedTodayTrendFootBoxList,
    getFeedWeather,
    getShoppingMDRecommend,
    getUserMyinfo,
} from 'store/Actions';

import {
    DefaultNewsListBox,
    DefaultPhotoBox,
    DefaultPagingPhotoBox,
    // DefaultTrendPhotoBox,
    // DefaultTrendSmallPhotoBox,
    // TodayTrendSectionBox,
    DefaultPagingPhotoBoxGameN,
    TodayTrendBigTypeBox,
    TodayTrendSmallTypeBox,
    //SyrupTableBanner,
    MobionBanner,
    InterWorksMediaBanner,
    MainWeaterBox,
    ShoppingMdPagingPhotoBox,
    WebtoonBennerBox,
    WebtoonPointInfoBox,
} from 'elements';

//import FeedKeyWordListCloseType from './KeywordList/FeedKeyWordListCloseType';
//import FeedKeyWordListOpenType from './KeywordList/FeedKeyWordListOpenType';

const cookies = new Cookies();

const iconList = [
    { name: 'ssg', text: 'SSG', path: 'http://www.ssg.com/?ckwhere=t_page', extension: 'png' },
    { name: 'halfclub', text: '하프클럽', path: 'http://www.halfclub.com/partner/h_twifi_mw', extension: 'png' },
    { name: 'wemap', text: '위메프', path: 'http://api.linkmine.co.kr/ck.html?app_code=1z9Wqcgx6m&sid=27390', extension: 'jpg' },
    { name: 'coupang', text: '쿠팡', path: 'http://api.linkmine.co.kr/ck.html?app_code=WPcjjtVwBQ&sid=19051', extension: 'jpg' },
    { name: 'google', text: '구글', path: 'https://www.google.com/', extension: 'png' },
    { name: 'facebook', text: '페이스북', path: 'https://m.facebook.com/', extension: 'png' },
];

export class FeedComponent extends Component {

    constructor(props) {

        super(props);

        const cookies = new Cookies();

        this.state = {
            token: (cookies.get('__token')) ? cookies.get('__token') : '',
            login_yn: (cookies.get('__useyn')) ? cookies.get('__useyn') : 0,
            user_uid: '',
            user_point: '',
            center_box_category : '1002',
            center_game_md_box_page : 1,
            center_game_md_box_total_page : 1,
            top_keyword_view_open_type : false,
            city_codes : (cookies.get('weatherHistory')) ? cookies.get('weatherHistory') : '',
            city_idx: (cookies.get('cityIdx')) ? cookies.get('cityIdx') : '0',
            selected: this.props.location.pathname,
            center_shopping_md_box_page : 1,
            center_shopping_md_box_total_page : 1,
            center_game_n_box_page : 1,
            center_game_n_box_total_page : 1,
            width: window.innerWidth,
        }

        // console.log(process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/01.svg');

        const _handleContentLinkClick = (contents_text,contents_link) => {
            GlobalLink.SiteLogCheckURL({
                text:contents_text,
                url:contents_link
            });
        }

        const IconListItem = ({text, name, path, extension, selected}) => {
            return <div style={{padding:'10px'}}>
                <a href="/" style={{textAlign:'center'}} target="_blank" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(text),encodeURIComponent(path))}  }>
                    <div>
                        <img
                          src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/'+name+'.'+extension}
                          alt={text+' icon'}
                          style={{width:'56px', height:'56px'}}
                          />
                    </div>
                    <div style={{fontSize:'16px', margin:'5px 0 0'}}>{text}</div>
                </a>
            </div>;
        };
        const IconList = (iconList, selected) =>
            iconList.map(el => {
                const {
                    name,
                    text,
                    path,
                    icon,
                    extension,
                } = el;

                return <IconListItem text={text} key={name} name={name} path={path} extension={extension} selected={selected} />;
            }
        );

        this.menuItems = IconList(iconList, this.state.selected);
    }

    _handleClickGameMdPageing = (e) => {
        let nowPage = this.state.center_game_md_box_page;
        let pageValue;
        const pageType = e.target.getAttribute('page_type');

        if(pageType === '-') {

            if(nowPage-1 === 0) {
                pageValue = this.state.center_game_md_box_total_page;
            } else {
                pageValue = nowPage-1;
            }
            this.setState({
                center_game_md_box_page: pageValue
            });

        } else if(pageType === '+') {

            if(nowPage+1 > this.state.center_game_md_box_total_page) {
                pageValue = 1;
            } else {
                pageValue = nowPage+1;
            }
            this.setState({
                center_game_md_box_page: pageValue
            });
        }
    }

    _handleClickGameNPageing = (e) => {
        let nowPage = this.state.center_game_n_box_page;
        let pageValue;
        const pageType = e.target.getAttribute('page_type');

        if(pageType === '-') {

            if(nowPage-1 === 0) {
                pageValue = this.state.center_game_n_box_total_page;
            } else {
                pageValue = nowPage-1;
            }
            this.setState({
                center_game_n_box_page: pageValue
            });

        } else if(pageType === '+') {

            if(nowPage+1 > this.state.center_game_n_box_total_page) {
                pageValue = 1;
            } else {
                pageValue = nowPage+1;
            }
            this.setState({
                center_game_n_box_page: pageValue
            });
        }
    }



    _getFeedWeather = () => {
        let mCodes = [];
        let codes = this.state.city_codes;

        if(codes === '') {
           mCodes = ["1168000000", "4713000000"];
        } else {
            codes.map((item , i) => {
                mCodes = mCodes.concat(item.mCode);
            });
        }

        this.props.getFeedWeather(mCodes.toString(mCodes.toString()));
    }

    _getFeedTopList = () => {
        this.props.getFeedTopList();
    }

    _getFeedTopPhotoList = () => {
        this.props.getFeedTopPhotoList();
    }

    _getFeedCenterBoxList = (category) => {
        this.props.getFeedCenterBoxList(category);
    }

    _getFeedCenterGameMdBoxList = () => {
        this.props.getFeedCenterGameMdBoxList();
    }

    _getFeedCenterGameNBoxList = () => {
        this.props.getFeedCenterGameNBoxList();
    }


    _getFeedTodayTrendBoxList = () => {
        this.props.getFeedTodayTrendBoxList();
    }

    _getFeedTodayTrendFootBoxList = () => {
        this.props.getFeedTodayTrendFootBoxList();
    }

    //_getFeedTopKeyWordList = () => {
    //    this.props.getFeedTopKeyWordList();
    //}

    _getShoppingMDRecommend = () => {
        this.props.getShoppingMDRecommend();
    }

    _getFeedBannerOne = () => {

        var obj = $("#feedBanner01"),
        obj_width = obj.width(),
        obj_height = obj_width * 0.3125;
        obj.css({'width':'100%', 'height':obj_height});

        var feedBanner01 = new ASUMSDK();
        
        var feedBanner01conf = {
            targetId : 'feedBanner01',
            adUnitId : '0ac5f5f4-ad77-453a-abf2-817a7efef688', //0ac5f5f4-ad77-453a-abf2-817a7efef688
            callback : {
                onAdLoaded : function(isDefaultAd){
                    console.log(isDefaultAd+"zzz");
                    // if(isDefaultAd==true){
                    //     $("#feedBanner01").css('height','auto');
                    //     $("#feedBanner01").html('<a href="https://stockletter.kr/event/register01?utm_source=asum&utm_medium=banner&utm_campaign=noad" target="_blank" style="height:100%;"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
                    // }

                },
                onAdFailed : function(error){
                    console.log(error+"errro");
                    $("#feedBanner01").css('height','auto');
                    //$("#feedBanner01").html('<a href="https://stockletter.kr/event/register04" target="_blank" style="height:100%;"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
                },
    
                onAdClicked : function(url){
                        return false;
                },
            }
    
        };

        feedBanner01.loadAd(feedBanner01conf);

        //하단 테스트
        // $("#feedBanner02").css('height','auto');
        // $("#feedBanner02").html('<a href="https://stockletter.kr/event/register01?utm_source=asum&utm_medium=banner&utm_campaign=noad" target="_blank" style="height:100%;"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
    }

    startPage  = () => {
        this._getFeedWeather();
        //this._getFeedTopKeyWordList();
        this._getFeedTopList();
        this._getFeedTopPhotoList();
        this._getFeedCenterBoxList(Helper.getCodeToNewsCategoryName(this.state.center_box_category));
        this._getFeedCenterGameMdBoxList();
        this._getFeedTodayTrendBoxList();
        this._getFeedTodayTrendFootBoxList();
        this._getShoppingMDRecommend();
        this._getFeedCenterGameNBoxList();

        if(this.state.token === '') {
        } else {
            if(this.state.login_yn > 0) {
                const { token } = this.state;
                let parmas = {
                    token: token,
                }
                this.props.getUserMyinfo(parmas);
            }
        }

        //Asumbanner
        this._getFeedBannerOne();
    }

    componentDidMount() {
        this.startPage();
    }

    componentWillMount() {
         this.setState({width : window.innerWidth});
     }


    componentWillReceiveProps(nextProps) {

        if(nextProps.feedCenterGameMdBox.state === true && this.props.feedCenterGameMdBox.payload !== nextProps.feedCenterGameMdBox.payload) {
            const mdPayloadCount = nextProps.feedCenterGameMdBox.payload.length;

            let totalPage = Helper.getTotalPage((mdPayloadCount - 1), 4);

            this.setState({
                center_game_md_box_total_page : totalPage
            });
        }

        if(nextProps.feedCenterGameNBox.state === true && this.props.feedCenterGameNBox.payload !== nextProps.feedCenterGameNBox.payload) {
            const NPayloadCount = nextProps.feedCenterGameNBox.payload.length;

            let totalPage = Helper.getTotalPage((NPayloadCount - 1), 4);

            this.setState({
                center_game_n_box_total_page : totalPage
            });
        }

        if(nextProps.mdRecommend.state === true && this.props.mdRecommend.payload !== nextProps.mdRecommend.payload) {
            const mdPayloadCount = nextProps.mdRecommend.payload.length;

            let totalPage = Helper.getTotalPage((mdPayloadCount - 1), 6);
            
            if(totalPage == 0) {
                this.setState({
                    center_shopping_md_box_page : 1,
                    center_shopping_md_box_total_page : 1
                });
            } else {
                this.setState({
                    center_shopping_md_box_total_page : totalPage
                });
            }
        }

        if(nextProps.USER_MYINFO.state === true && this.props.USER_MYINFO.state !== nextProps.USER_MYINFO.state) {
            this.setState({
                user_uid: nextProps.USER_MYINFO.payload.uid,
                user_point: nextProps.USER_MYINFO.payload.point
            })
        }
    }


    _handleCenterBoxCategoryClick = (category) => {

        this.setState({
            center_box_category: category
        });

        this._getFeedCenterBoxList(Helper.getCodeToNewsCategoryName(category));
    }

    _handleClickShoppingMdPageing = (e) => {

        const pageType = e.target.getAttribute('page_type');

        let nowPage = this.state.center_shopping_md_box_page;
        let pageValue;

        if(pageType === '-') {

            if(nowPage-1 === 0) {
                pageValue = this.state.center_shopping_md_box_total_page;
            } else {
                pageValue = nowPage-1;
            }
            this.setState({
                center_shopping_md_box_page: pageValue
            });

        } else if(pageType === '+') {

            if(nowPage+1 > this.state.center_shopping_md_box_total_page) {
                pageValue = 1;
            } else {
                pageValue = nowPage+1;
            }
            this.setState({
                center_shopping_md_box_page: pageValue
            });
        }
    }

    _handleKeywordOpenCloseButton = () => {
        if(this.state.top_keyword_view_open_type === false ) {
            this.setState({
                top_keyword_view_open_type: true
            });
        } else {
            this.setState({
                top_keyword_view_open_type: false
            });
        }
    }


    render() {
        const { selected } = this.state;
        const IconListItem = this.menuItems;
        return (
            <div id="wrap">

                <div id="container">

                    {
                        //<FeedKeyWordListCloseType BUTTON_OPEN_CLICK={this._handleKeywordOpenCloseButton} />
                    }

                    {
                        //this.state.top_keyword_view_open_type && <FeedKeyWordListOpenType BUTTON_OPEN_CLICK={this._handleKeywordOpenCloseButton} />
                    }

                    {/* <div className="dataChart">
                        <ul>
                            <li>
                                <span className="chart"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample4.png'} alt=""/></span>남은데이터<br /><strong>95.75GB</strong> 남음
                            </li>
                            <li>
                                <span className="chart"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample5.png'} alt=""/></span>영상,부가전화<br /><strong>5시간 0분</strong> 남음
                            </li>
                            <li>
                                <span className="chart"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample6.png'} alt=""/></span>SMS/MMS<br /><strong>무제한</strong>
                            </li>
                        </ul>
                    </div> 
                    <section className="linkList">
                        <div style={{ display:(this.state.user_uid === '') ? 'block' : 'none' }}>
                            <WebtoonBennerBox
                                TOKEN={this.state.token}
                             />
                        </div>
                        <div style={{ display:(this.state.user_uid === '') ? 'none' : 'block' }}>
                            <WebtoonPointInfoBox
                                POINT={this.state.user_point}
                                TOKEN={this.state.token}
                             />
                        </div>
                    </section>
                    */}
                    <div className="linkList">
                        <ul className="txtType">
                            <DefaultNewsListBox
                                CONTENTS_LIST={this.props.feedTop}
                            />
                        </ul>
                        <ul className="thumbType">
                            <DefaultPhotoBox
                                CONTENTS_LIST={this.props.feedTopPhoto}
                            />
                        </ul>
                    </div>
                     <div id="feedBanner01"  style={{position:'relative'}}>
                            <MobionBanner
                                BANNER_TYPE="FEED_TOP"
                            />
                    </div>
                    
                    <section className="linkList">
                        <h2 className="subTitle1">게임MD추천 <span>직접해본 게임만 추천!</span></h2>
                        <div className="gridType">
                            <DefaultPagingPhotoBox
                                CONTENTS_LIST={this.props.feedCenterGameMdBox}
                                CONTENTS_PAGE={this.state.center_game_md_box_page}
                            />
                        </div>
                        <div className="listNav">
                            <button type="button" className="btnPrev" onClick={this._handleClickGameMdPageing} page_type="-">이전</button>
                            <span className="pagination">
                                <strong>{this.state.center_game_md_box_page}</strong> / <span>{this.state.center_game_md_box_total_page}</span>
                            </span>
                            <button type="button" className="btnNext" onClick={this._handleClickGameMdPageing} page_type="+">다음</button>
                        </div>

                        <div className="iconGridType" style={{margin:'0 -1.5rem'}}>
                            <IconScrollMenu
                              data={IconListItem}
                              selected={selected}
                              onSelect={this.onSelect}
                              alignCenter={(this.state.width >= 600)}
                            />
                        </div>
                    </section>

                    <section className="linkList">
                        <h2 className="subTitle1">Wifi 오락실</h2>
                        <div className="gridType">
                            <DefaultPagingPhotoBoxGameN
                                CONTENTS_LIST={this.props.feedCenterGameNBox}
                                CONTENTS_PAGE={this.state.center_game_n_box_page}
                            />
                        </div>
                        <div className="listNav">
                            <button type="button" className="btnPrev" onClick={this._handleClickGameNPageing} page_type="-">이전</button>
                            <span className="pagination">
                                <strong>{this.state.center_game_n_box_page}</strong> / <span>{this.state.center_game_n_box_total_page}</span>
                            </span>
                            <button type="button" className="btnNext" onClick={this._handleClickGameNPageing} page_type="+">다음</button>
                        </div>

                    </section>

                    <div className="banner">
                        <MobionBanner
                            BANNER_TYPE="FEED_TOP"
                        />
                    </div>

                    <div className="weatherApp">
                        {/*this.props.feedWeaterBox ? this._renderWeaders() : <div></div>*/}
                        <MainWeaterBox
                            WEATHER_DATA={this.props.feedWeaterBox}
                        />
                    </div>

                    {/* <section className="linkList">
                        <h2 className="subTitle1">MD추천 <span>열심히 골라봤어요!</span></h2>
                        <div>
                            <ShoppingMdPagingPhotoBox
                                CONTENTS_LIST={this.props.mdRecommend}
                                CONTENTS_PAGE={this.state.center_shopping_md_box_page}
                            />
                        </div>
                        <div className="listNav">
                            <button type="button" className="btnPrev" onClick={this._handleClickShoppingMdPageing} page_type="-" disabled={this.state.center_shopping_md_box_total_page === 0 ? "disabled" : ""}>이전</button>
                            <span className="pagination"><strong>{this.state.center_shopping_md_box_page}</strong> /<span>{this.state.center_shopping_md_box_total_page}</span></span>
                            <button type="button" className="btnNext" onClick={this._handleClickShoppingMdPageing} page_type="+" disabled={this.state.center_shopping_md_box_total_page === 0 ? "disabled" : ""}>다음</button>
                        </div>
                    </section> */}

                    <div className="tabMenu">
                        <ul className="menuLeng4">
                            <li className={this.state.center_box_category === '1002' ? 'active' : ''}><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleCenterBoxCategoryClick('1002')}  }>정치</Link></li>
                            <li className={this.state.center_box_category === '1004' ? 'active' : ''}><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleCenterBoxCategoryClick('1004')}  }>연예</Link></li>
                            <li className={this.state.center_box_category === '1005' ? 'active' : ''}><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleCenterBoxCategoryClick('1005')}  }>스포츠</Link></li>
                            <li className={this.state.center_box_category === '1007' ? 'active' : ''}><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleCenterBoxCategoryClick('1007')}  }>야구</Link></li>
                        </ul>
                    </div>

                    <div className="linkList">
                        <ul className="txtType">
                            <DefaultNewsListBox
                                CONTENTS_LIST={this.props.feedBoxCenterBox}
                            />
                        </ul>
                        <Link onClick={ (e) => { window.scrollTo(0, 0); }} to={{ pathname: 'news/sub/'+this.state.center_box_category }}>
                            <span style={{'display':'block','textAlign':'right','margin':'5px 0'}}>
                                더보기 &#62;
                            </span>
                        </Link>
                    </div>

                    <div className="banner">
                        <InterWorksMediaBanner/>
                    </div>

                    <section className="linkList">
                        <h2 className="subTitle1">TODAY TREND <span>오늘 제일 핫한 트랜드한 쇼핑 아이템</span></h2>
                        <ul className="thumbBigType">
                            <TodayTrendBigTypeBox
                                CONTENTS_LIST={this.props.feedTodayTrendBox}
                            />
                        </ul>
                        <ul className="thumbType">
                            <TodayTrendSmallTypeBox
                                CONTENTS_LIST={this.props.feedTodayTrendBox}
                            />
                        </ul>
                    </section>

                </div>

            </div>
        );
    }

    _renderWeaders = () => {
        let list = <MainWeaterBox
                        WEATHER_DATA={this.props.feedWeaterBox}
                    />
        return list;
    }
};

FeedComponent.defaultProps = {
    feedTopKeyWordList: {
        state: false,
        payload : {}
    },
    feedTop: {
        state: false,
        payload : {}
    },
    feedTopPhoto: {
        state: false,
        payload : {}
    },
    feedBoxCenterBox: {
        state: false,
        payload : {}
    },
    feedCenterGameMdBox: {
        state: false,
        payload : {}
    },
    feedCenterGameNBox: {
        state: false,
        payload : {}
    },
    feedTodayTrendBox: {
        state: false,
        payload : {}
    },
    feedTodayFootBox: {
        state: false,
        payload : {}
    },
    feedWeaterBox: {
        state: false,
        payload : {}
    },
    mdRecommend: {
        state: false,
        payload : {}
    },
};


const mapStateToProps = state => ({
    feedTopKeyWordList : state.FEED.feedTopKeyWordList,
    feedTop : state.FEED.feedTop,
    feedTopPhoto : state.FEED.feedTopPhoto,
    feedBoxCenterBox : state.FEED.feedBoxCenterBox,
    feedCenterGameMdBox : state.FEED.feedCenterGameMdBox,
    feedCenterGameNBox : state.FEED.feedCenterGameNBox,
    feedTodayTrendBox : state.FEED.feedTodayTrendBox,
    feedTodayFootBox : state.FEED.feedTodayFootBox,
    feedWeaterBox: state.FEED.feedWeaterBox,
    mdRecommend: state.SHOPPING.mdRecommend,
    USER_MYINFO: state.USER_MYINFO,
});

const mapDispatchToProps = {
    //getFeedTopKeyWordList,
    getFeedTopList,
    getFeedTopPhotoList,
    getFeedCenterBoxList,
    getFeedCenterGameMdBoxList,
    getFeedCenterGameNBoxList,
    getFeedTodayTrendBoxList,
    getFeedTodayTrendFootBoxList,
    getFeedWeather,
    getShoppingMDRecommend,
    getUserMyinfo,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FeedComponent));
