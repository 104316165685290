import * as API from 'lib/API';
import { put, takeLatest, all } from 'redux-saga/effects';
import * as ActionTypes from './ActionTypes';
import * as Helper from 'lib/Helper';
import { Cookies } from 'react-cookie';
const cookies = new Cookies();

/**
 * 사용자 브라우져 GPS 체크 및 정보 가지고 오기.
 */
function* fetchGetBrowserGeolocation(action) {
    try {
        yield put({
            type: ActionTypes.SUCCEEDED_BROWSER_GEOLOCATION,
            payload: {
                state:true,
                latitude:action.payload.latitude,
                longitude:action.payload.longitude,
                name:action.payload.name
            }
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_BROWSER_GEOLOCATION,
            payload: {
                state:false,
                latitude : '',
                longitude : '',
                name : ''
            }
        });
    }
}

/**
 *
 * @param {*} action
 *
 * 구글 플레이스 정보 가지고 오기.
 */
function* fetchGetGooglePlaceList(action) {
    try {
        const getResult = yield API.getGooglePlaceData(action.payload);
        yield put({
            type: ActionTypes.SUCCEEDED_GOOGLE_PLACE_LIST,
            payload: getResult.items
        });
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_GOOGLE_PLACE_LIST,
            payload: false
        });
    }
}

/**
 *
 * 메인 상단 키워드 리스트
 */
function* fetchGetFeedTopKeyWordList() {
    try {
        const getResult = yield API.getFeedKeyWordList();
        yield put({
            type: ActionTypes.SUCCEEDED_FEED_TOP_KEYWORD_LIST,
            payload: getResult.items
        });
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_FEED_TOP_KEYWORD_LIST,
            payload: false
        });
    }
}


// -------------------------------------------------------

/* new work */
/* 인증번호 */
/* 발송 */
function* fetchCertification(action) {
    try {
        const getResult = yield API.tryCertification(action.params);
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        } else {
            alert(getResult.message);
        }
        yield put({
            type: ActionTypes.SUCCEEDED_CERTIFICATION,
            payload: getResult.result
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_CERTIFICATION,
            payload: false
        });
    }
}
/* 확인 */
function* fetchConfirm(action) {
    try {
        const getResult = yield API.tryConfirm(action.params);
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        } else {
            alert(getResult.message);
        }
        yield put({
            type: ActionTypes.SUCCEEDED_CONFIRM,
            payload: getResult.result
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_CONFIRM,
            payload: false
        });
    }
}
/* 인증번호 end */

/* 전화번호 로그서치 */
function* fetchLogPhoneNum(action) {
    try {
        const getResult = yield API.getLogPhoneNum(action.params);
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        }
        yield put({
            type: ActionTypes.SUCCEEDED_LOG_PHONE,
            payload: getResult.result
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_LOG_PHONE,
            payload: false
        });
    }
}
/* 전화번호 로그서치 end */

/**
 *
 * @param {*} action
 * Token
 */
/* 브라우저 토큰 */
function* fetchBrowserToken(action) {
    try {
        const getResult = yield API.getBrowserToken();
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        }
        yield put({
            type: ActionTypes.SUCCEEDED_BROWSER_TOKEN,
            payload: getResult.result
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_BROWSER_TOKEN,
            payload: false
        });
    }
}
/* 브라우저 토큰 end */

/**
 *
 * @param {*} action
 * Auth
 */
 /* 평범한 회원가입 */
function* fetchTryDefaultJoinMember(action) {
    try {
        const getResult = yield API.tryDefaultJoinMember(action.params);
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        }
        yield put({
            type: ActionTypes.SUCCEEDED_DEFAULT_JOIN_MEMBER,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_DEFAULT_JOIN_MEMBER,
            payload: false
        });
    }
}
/* 평범한 회원가입 end */
/* 회원가입 */
function* fetchTryJoinMember(action) {
    try {
        const getResult = yield API.tryJoinMember(action.params);
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        }
        yield put({
            type: ActionTypes.SUCCEEDED_JOIN_MEMBER,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_JOIN_MEMBER,
            payload: false
        });
    }
}
/* 회원가입 end */
/* 회원탈퇴 */
function* fetchTryWithdrawal(action) {
    try {
        const getResult = yield API.tryWithdrawal(action.params);
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        }
        yield put({
            type: ActionTypes.SUCCEEDED_WITHDRAWAL,
            payload: getResult
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_WITHDRAWAL,
            payload: false
        });
    }
}
/* 회원탈퇴 end */

/* 비밀번호 변경 mypage */
function* fetchTryUserChangePw(action) {
    try {
        const getResult = yield API.tryUserChangePw(action.params);
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        }
        yield put({
            type: ActionTypes.SUCCEEDED_USER_CHANGE_PW,
            payload: getResult.result
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_USER_CHANGE_PW,
            payload: false
        });
    }
}
/* 비밀번호 변경 end */
/* 비밀번호 변경 findpw */
function* fetchTryDefaultChangePw(action) {
    try {
        const getResult = yield API.tryDefaultChangePw(action.params);
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        }
        yield put({
            type: ActionTypes.SUCCEEDED_DEFAULT_CHANGE_PW,
            payload: getResult.result
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_DEFAULT_CHANGE_PW,
            payload: false
        });
    }
}
/* 비밀번호 변경 end */

/* 비밀번호 체크 */
function* fetchTryPasswordCheck(action) {
    try {
        const getResult = yield API.tryPasswordCheck(action.params);
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        }
        yield put({
            type: ActionTypes.SUCCEEDED_PASSWORD_CHECK,
            payload: getResult
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_PASSWORD_CHECK,
            payload: false
        });
    }
}
/* 비밀번호 체크 end */

/* 로그인 */
function* fetchTryLoginUser(action) {
    try {
        const getResult = yield API.tryLoginUser(action.params);
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        }
        yield put({
            type: ActionTypes.SUCCEEDED_LOGIN_USER,
            payload: getResult.result
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_LOGIN_USER,
            payload: false
        });
    }
}
/* 로그인 end */
/* 내정보 */
function* fetchGetUserMyinfo(action) {
    try {
        const getResult = yield API.getUserMyinfo(action.params);
        if(!getResult.status) {
            cookies.remove('__token');
            cookies.remove('__useyn');
            return false;
        }
        yield put({
            type: ActionTypes.SUCCEEDED_USER_MYINFO,
            payload: getResult.result
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_USER_MYINFO,
            payload: false
        });
    }
}
/* 내정보 end */
/* 포인트 충전,사용 내역 */
function* fetchGetUserMypoint(action) {
    try {
        const getResult = yield API.getUserMypoint(action.params);
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        }
        yield put({
            type: ActionTypes.SUCCEEDED_USER_MYPOINT,
            payload: getResult.result
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_USER_MYPOINT,
            payload: false
        });
    }
}
/* 포인트 충전,사용 내역 end */

/* 1:1 문의 리스트 */
function* fetchGetOneononeList(action) {
    try {
        const getResult = yield API.getOneononeList(action.params);
        if(!getResult.status) {
            alert(getResult.message);
            return false;
        }
        yield put({
            type: ActionTypes.SUCCEEDED_ONE_INQUIRT,
            payload: getResult.result
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_ONE_INQUIRT,
            payload: false
        });
    }
}
/* 1:1 문의 리스트 end */


/**
 *
 * @param {*} action
 * 자동차 메뉴
 */
/* section1 */
function* fetchGetCarSection1(action) {
    try {
        const getResult = yield API.getCarSection1(action.payload);
        yield put({
            type: ActionTypes.SUCCEEDED_CAR_SECTION1,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_CAR_SECTION1,
            payload: false
        });
    }
}
/* section2 */
function* fetchGetCarSection2(action) {
    try {
        const getResult = yield API.getCarSection2(action.payload);
        yield put({
            type: ActionTypes.SUCCEEDED_CAR_SECTION2,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_CAR_SECTION2,
            payload: false
        });
    }
}
/* section3 */
function* fetchGetCarSection3(action) {
    try {
        const getResult = yield API.getCarSection3(action.payload);
        yield put({
            type: ActionTypes.SUCCEEDED_CAR_SECTION3,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_CAR_SECTION3,
            payload: false
        });
    }
}
/* section4 */
function* fetchGetCarSection4(action) {
    try {
        const getResult = yield API.getCarSection4(action.payload);
        yield put({
            type: ActionTypes.SUCCEEDED_CAR_SECTION4,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_CAR_SECTION4,
            payload: false
        });
    }
}
/* section3 */
function* fetchGetCarSection5(action) {
    try {
        const getResult = yield API.getCarSection5(action.payload);
        yield put({
            type: ActionTypes.SUCCEEDED_CAR_SECTION5,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_CAR_SECTION5,
            payload: false
        });
    }
}
/* section benner */
function* fetchGetCarBenner(action) {
    try {
        const getResult = yield API.getCarBanner(action.payload);
        yield put({
            type: ActionTypes.SUCCEEDED_CAR_SECTION_BENNER,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_CAR_SECTION_BENNER,
            payload: false
        });
    }
}

/**
 *
 * @param {*} action
 * 날씨 박스
 */

/* detail */
function* fetchGetWeatherDetail(action) {
    try {
        const getResult = yield API.getWeatherDetail(action.citycode);
        yield put({
            type: ActionTypes.SUCCEEDED_WEATHER_DETAIL,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_WEATHER_DETAIL,
            payload: false
        });
    }
}
/* detail end */

function* fetchGetFeedWeather(action) {
    try {
        const getResult = yield API.getFeedWeather(action.citycode);
        yield put({
            type: ActionTypes.SUCCEEDED_FEED_WEATHER,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_FEED_WEATHER,
            payload: false
        });
    }
}

/* week */
function* fetchGetWeatherWeek(action) {
    try {
        const getResult = yield API.getWeatherWeek(action.citycode);
        yield put({
            type: ActionTypes.SUCCEEDED_WEATHER_WEEK,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_WEATHER_WEEK,
            payload: false
        });
    }
}

/* hour */
function* fetchGetWeatherHour(action) {
    try {
        const getResult = yield API.getWeatherHour(action.citycode);
        yield put({
            type: ActionTypes.SUCCEEDED_WEATHER_HOUR,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_WEATHER_HOUR,
            payload: false
        });
    }
}

/* search address */
function* fetchGetWeatherAddressResult(action) {
    try {
        const getResult = yield API.getWeatherAddressResult(action.keyword);
        yield put({
            type: ActionTypes.SUCCEEDED_WEATHER_ADDRESS_RESULT,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_WEATHER_ADDRESS_RESULT,
            payload: false
        });
    }
}

/* search history */
function* fetchGetWeatherHistoryList(action) {
    try {
        const getResult = yield API.getWeatherHistoryList(action.histories);
        yield put({
            type: ActionTypes.SUCCEEDED_WEATHER_HISTORY_LIST,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_WEATHER_HISTORY_LIST,
            payload: false
        });
    }
}
/* new work end */

/**
 *
 * @param {*} action
 * 피드 상단 박스
 */
function* fetchGetFeedTopList(action) {
    try {
        const getResult = yield API.getFeedTopList(action.payload);
        yield put({
            type: ActionTypes.SUCCEEDED_FEED_TOP_LIST,
            payload: getResult.items
        });
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_FEED_TOP_LIST,
            payload: false
        });
    }
}


function* fetchGetFeedTopPhotoList(action) {
    try {
        const getResult = yield API.getFeedTopPhotoList();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_FEED_TOP_PHOTO_LIST,
                payload: getResult.items
            });
        } else {
            // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});

            yield put({
                type: ActionTypes.FAILED_FEED_TOP_PHOTO_LIST,
                payload: false
            });
        }
    } catch(e) {
        yield put({
            type: ActionTypes.FAILED_FEED_TOP_PHOTO_LIST,
            payload: false
        });
    }
}

/**
 *
 * @param {*} action
 * 피드 상단 포토 박스
 */
function* fetchGetFeedCenterBoxList(action) {
    try {
        const getResult = yield API.getFeedCenterBoxList(action.category);
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_FEED_CENTER_BOX_LIST,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_FEED_CENTER_BOX_LIST,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_FEED_CENTER_BOX_LIST,
            payload: false
        });
    }
}


/**
 *
 * 피드 중간 게임 MD
 */
function* fetchGetFeedCenterGameMDBoxList() {
    try {
        const getResult = yield API.getFeedCenterGameMdBoxList();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_FEED_CENTER_GAME_MD_BOX_LIST,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_FEED_CENTER_GAME_MD_BOX_LIST,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_FEED_CENTER_GAME_MD_BOX_LIST,
            payload: false
        });
    }
}

/**
 *
 * 피드 중간 GameN
 */
function* fetchGetFeedCenterGameNBoxList() {
    try {
        const getResult = yield API.getFeedCenterGameNBoxList();

        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_FEED_CENTER_GAME_N_BOX_LIST,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_FEED_CENTER_GAME_N_BOX_LIST,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_FEED_CENTER_GAME_N_BOX_LIST,
            payload: false
        });
    }
}



/**
 *
 * 피드 투데이 트렌드
 */
function* fetchGetFeedTodayTrendBoxList() {
    try {
        const getResult = yield API.getFeedTodayTrendBoxList();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_FEED_TODAY_TREND_BOX_LIST,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_FEED_TODAY_TREND_BOX_LIST,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_FEED_TODAY_TREND_BOX_LIST,
            payload: false
        });
    }
}

/**
 * 피드 투데이 트랜드 아래 박스
 */
function* fetchGetFeedTodayFootBoxList() {
    try {
        const getResult = yield API.getFeedTodayTrendFootBoxList();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_FEED_TODAY_TREND_FOOT_BOX_LIST,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_FEED_TODAY_TREND_FOOT_BOX_LIST,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_FEED_TODAY_TREND_FOOT_BOX_LIST,
            payload: false
        });
    }
}

// -------------------------------------------------------

/**
 * 뉴스 메인 상단 속보
 */
function* fetchGetNewsMainTopBulletin() {
    try {
        const getResult = yield API.getNewsMainTopBulletin();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_NEWS_MAIN_TOP_BULLETIN,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_NEWS_MAIN_TOP_BULLETIN,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_NEWS_MAIN_TOP_BULLETIN,
            payload: false
        });
    }
}

/**
 *
 * 뉴스 메인 상단 박스
 */
function* fetchGetNewsMainTopBox() {
    try {
        const getResult = yield API.getNewsMainTopBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_NEWS_MAIN_TOP_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_NEWS_MAIN_TOP_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_NEWS_MAIN_TOP_BOX,
            payload: false
        });
    }
}

/**
 * 뉴스 메인 상단 포토 박스
 */
function* fetchGetNewsMainPhotoBox() {
    try {
        const getResult = yield API.getNewsMainTopPhotoBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_NEWS_MAIN_TOP_PHOTO_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_NEWS_MAIN_TOP_PHOTO_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_NEWS_MAIN_TOP_PHOTO_BOX,
            payload: false
        });
    }
}


/**
 * 뉴스 메인 가운데 뉴스 순위(?)
 */
function* fetchGetNewsMainCenterBox(payload) {
    try {
        const getResult = yield API.getNewsMainCenterNewsBox(payload.category);
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_NEWS_MAIN_CENTER_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_NEWS_MAIN_CENTER_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_NEWS_MAIN_CENTER_BOX,
            payload: false
        });
    }
}



/**
 * 뉴스 메인 헤드라인 박스
 */
function* fetchGetNewsMainheadLineBox() {
    try {
        const getResult = yield API.getNewsMainHeadLineNewsBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_NEWS_MAIN_CENTER_HEADLINE,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_NEWS_MAIN_CENTER_HEADLINE,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_NEWS_MAIN_CENTER_HEADLINE,
            payload: false
        });
    }
}

/**
 * 뉴스 메인 주요뉴스
 */
function* fetchGetNewsMainTodayNewsBox() {
    try {
        const getResult = yield API.getNewsMainTodayNewsBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_NEWS_MAIN_TODAY_NEWS,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_NEWS_MAIN_TODAY_NEWS,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_NEWS_MAIN_TODAY_NEWS,
            payload: false
        });
    }
}

// -------------------------------------------------------

/**
 * 쇼핑 투데이 트렌드
 */
function* fetchGetShoppingTodayTrend() {
    try {
        const getResult = yield API.getShoppingTodayTrend();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_SHOPPING_TODAY_TREND,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_SHOPPING_TODAY_TREND,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_SHOPPING_TODAY_TREND,
            payload: false
        });
    }
}

/**
 * 쇼핑 투데이 트렌드 하단
 */
function* fetchGetShoppingTodayTrendFoot() {
    try {
        const getResult = yield API.getShoppingTodayTrendFoot();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_SHOPPING_TODAY_TREND_FOOT,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_SHOPPING_TODAY_TREND_FOOT,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_SHOPPING_TODAY_TREND_FOOT,
            payload: false
        });
    }
}


/**
 * 쇼핑 MD 추천
 */
function* fetchGetShoppingMdRecommend() {
    try {
        const getResult = yield API.getShoppingMDRecommend();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_SHOPPING_MD_RECOMMEND,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_SHOPPING_MD_RECOMMEND,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_SHOPPING_MD_RECOMMEND,
            payload: false
        });
    }
}

/**
 * 쇼핑 베스트 리뷰
 */
function* fetchGetShoppingBestReview() {
    try {
        const getResult = yield API.getShoppingBestReview();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_SHOPPING_BEST_REVIEW,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_SHOPPING_BEST_REVIEW,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_SHOPPING_BEST_REVIEW,
            payload: false
        });
    }
}

/**
 * 쇼핑 베스트 리뷰 하단 박스
 */
function* fetchGetShoppingBestReviewFootBox() {
    try {
        const getResult = yield API.getShoppingBestReviewFootBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_SHOPPING_BEST_REVIEW_FOOT_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_SHOPPING_BEST_REVIEW_FOOT_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_SHOPPING_BEST_REVIEW_FOOT_BOX,
            payload: false
        });
    }
}


/**
 * 쇼핑 패션
 */
function* fetchGetShoppingFashionBox() {
    try {
        const getResult = yield API.getShoppingFashionBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_SHOPPING_FASHION_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_SHOPPING_FASHION_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_SHOPPING_FASHION_BOX,
            payload: false
        });
    }
}


/**
 * 쇼핑 패션 하단
 */
function* fetchGetShoppingFashionBoxFoot() {
    try {
        const getResult = yield API.getShoppingFashionBoxFoot();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_SHOPPING_FASHION_BOX_FOOT,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_SHOPPING_FASHION_BOX_FOOT,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_SHOPPING_FASHION_BOX_FOOT,
            payload: false
        });
    }
}

/**
 * 쇼핑 테크 IT
 */
function* fetchGetShoppingTechItBox() {
    try {
        const getResult = yield API.getShoppingTechItBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_SHOPPING_TECH_IT_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_SHOPPING_TECH_IT_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_SHOPPING_TECH_IT_BOX,
            payload: false
        });
    }
}

/**
 * 쇼핑 추천 앱
 */
function* fetchGetShoppingRecommendApp() {
    try {
        const getResult = yield API.getShoppingRecommendApp();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_SHOPPING_RECOMMEND_APP,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_SHOPPING_RECOMMEND_APP,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_SHOPPING_RECOMMEND_APP,
            payload: false
        });
    }
}


/**
 * 쇼핑 TOP 배너
 */
function* fetchGetShoppingTopBnnerBox() {
    try {
        const getResult = yield API.getShoppingTopBnnerBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_SHOPPING_TOP_BNNER_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_SHOPPING_TOP_BNNER_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_SHOPPING_TOP_BNNER_BOX,
            payload: false
        });
    }
}



/**
 * 쇼핑 FOOT 배너
 */
function* fetchGetShoppingFootBnnerBox() {
    try {
        const getResult = yield API.getShoppingFootBnnerBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_SHOPPING_FOOT_BNNER_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_SHOPPING_FOOT_BNNER_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_SHOPPING_FOOT_BNNER_BOX,
            payload: false
        });
    }
}




// --------------------------------------------------------------------------------------------------------------------------------------------------------------------


/**
 * 게임 상단 MD 추천
 */
function* fetchGetGameMDRecommend() {
    try {
        const getResult = yield API.getGameMdRecommend();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_GAME_MD_RECOMMEND,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_GAME_MD_RECOMMEND,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_GAME_MD_RECOMMEND,
            payload: false
        });
    }
}


/**
 * 게임 새 게임?
 */
function* fetchGetGameNewGameBox() {
    try {
        const getResult = yield API.getGameNewGameBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_GAME_NEW_GAME_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_GAME_NEW_GAME_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_GAME_NEW_GAME_BOX,
            payload: false
        });
    }
}

/**
 * 게임 오피셜
 */
function* fetchGetGameOfficalBox() {
    try {
        const getResult = yield API.getGameOfficalBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_GAME_OFFICAL_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_GAME_OFFICAL_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_GAME_OFFICAL_BOX,
            payload: false
        });
    }
}


/**
 * 게임 뉴스 박스
 */
function* fetchGetGameNewsBox() {
    try {
        const getResult = yield API.getGameNewsBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_NEWS_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_GAME_NEWS_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_GAME_NEWS_BOX,
            payload: false
        });
    }
}


/**
 * 게임 리뷰
 */
function* fetchGetGameReviewBox() {
    try {
        const getResult = yield API.getGameReviewBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_REVIEW_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_GAME_REVIEW_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_GAME_REVIEW_BOX,
            payload: false
        });
    }
}


/**
 * 게임 상단 베너
 */
function* fetchGetGameTopBannerBox() {
    try {
        const getResult = yield API.getGameTopBannerBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_GAME_TOP_BANNER_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_GAME_TOP_BANNER_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_GAME_TOP_BANNER_BOX,
            payload: false
        });
    }
}

/**
 *
 * @param {*} payload
 *
 * 게임 하단 배너
 */
function* fetchGetGameFootBannerBox() {
    try {
        const getResult = yield API.getGameFootBannerBox();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_GAME_FOOT_BANNER_BOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_GAME_FOOT_BANNER_BOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_GAME_FOOT_BANNER_BOX,
            payload: false
        });
    }
}

// ---------------------------------------------------------------------------------------------------------------------
/**
 * 뉴스 서브 상단 포토 뉴스 박스
 */
function* fetchGetNewsSubTopPhotobox(payload) {
    try {
        const getResult = yield API.getNewsSubTopPhotobox(payload.category);
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_NEWS_SUB_TOP_PHOTOBOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_NEWS_SUB_TOP_PHOTOBOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_NEWS_SUB_TOP_PHOTOBOX,
            payload: false
        });
    }
}

/**
 * 뉴스 서브 하단 포토 뉴스 박스
 */
function* fetchGetNewsSubFootCategoryPhotobox(payload) {
    try {
        const getResult = yield API.getNewsSubFootCategoryPhotobox(payload.category);
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_NEWS_FOOT_SUB_CATEGORY_PHOTOBOX,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_NEWS_SUB_FOOT_CATEGORY_PHOTOBOX,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_NEWS_SUB_FOOT_CATEGORY_PHOTOBOX,
            payload: false
        });
    }
}


/**
 * 뉴스 서브 하단 관림 있는 언론사.
 */
function* fetchGetNewsSubNewsProvider() {
    try {
        const getResult = yield API.getNewsSubNewsProvider();
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_NEWS_FOOT_SUB_NEWS_PROVIDER,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_NEWS_SUB_FOOT_NEWS_PROVIDER,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_NEWS_SUB_FOOT_NEWS_PROVIDER,
            payload: false
        });
    }
}

/**
 * 광고/제휴 문의
 */
function* fetchInquiryCreate(action) {
    try {
        const getResult = yield API.tryInquiryCreate(action.payload);
        yield put({
            type: ActionTypes.SUCCEEDED_INQUIRY_CREATE,
            payload:getResult.result
        });
    } catch(e) {
        Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_INQUIRY_CREATE,
            payload:false
        });
    }
}

/**
 * 뉴스 서브 하단 관림 있는 언론사.
 */
function* fetchGetNewsSubContentProvider(payload) {
    try {
        const getResult = yield API.getNewsContentsBox(payload.sid);
        if(Helper.isEmpty(getResult.items) === false) {
            yield put({
                type: ActionTypes.SUCCEEDED_NEWS_SUB_CONTENT,
                payload: getResult.items
            });
        } else {
            yield put({
                type: ActionTypes.FAILED_NEWS_SUB_CONTENT,
                payload: false
            });
        }
    } catch(e) {
        // Helper.globalAlert({text:'정보를 가지고 오지 못했습니다.\n 다시 시도해 주세요.'});
        yield put({
            type: ActionTypes.FAILED_NEWS_SUB_CONTENT,
            payload: false
        });
    }
}






function* actionWatcher() {
    yield takeLatest(ActionTypes.GET_BROWSER_GEOLOCATION, fetchGetBrowserGeolocation);
    yield takeLatest(ActionTypes.GET_GOOGLE_PLACE_LIST, fetchGetGooglePlaceList);

    /* new work */
    /* 인증 */
    yield takeLatest(ActionTypes.TRY_CERTIFICATION, fetchCertification);
    yield takeLatest(ActionTypes.TRY_CONFIRM, fetchConfirm);
    /* 인증 end */
    yield takeLatest(ActionTypes.GET_LOG_PHONE, fetchLogPhoneNum);
    /* Token */
    yield takeLatest(ActionTypes.GET_BROWSER_TOKEN, fetchBrowserToken);
    /* Token end */
    /* Auth */
    yield takeLatest(ActionTypes.TRY_DEFAULT_JOIN_MEMBER, fetchTryDefaultJoinMember);
    yield takeLatest(ActionTypes.TRY_JOIN_MEMBER, fetchTryJoinMember);
    yield takeLatest(ActionTypes.TRY_WITHDRAWAL, fetchTryWithdrawal);

    yield takeLatest(ActionTypes.TRY_USER_CHANGE_PW, fetchTryUserChangePw);
    yield takeLatest(ActionTypes.TRY_DEFAULT_CHANGE_PW, fetchTryDefaultChangePw);
    yield takeLatest(ActionTypes.TRY_PASSWORD_CHECK, fetchTryPasswordCheck);

    yield takeLatest(ActionTypes.TRY_LOGIN_USER, fetchTryLoginUser);
    yield takeLatest(ActionTypes.GET_USER_MYINFO, fetchGetUserMyinfo);
    yield takeLatest(ActionTypes.GET_USER_MYPOINT, fetchGetUserMypoint);

    yield takeLatest(ActionTypes.GET_ONE_INQUIRT, fetchGetOneononeList);
    /* Auth end */

    yield takeLatest(ActionTypes.GET_CAR_SECTION1, fetchGetCarSection1);
    yield takeLatest(ActionTypes.GET_CAR_SECTION2, fetchGetCarSection2);
    yield takeLatest(ActionTypes.GET_CAR_SECTION3, fetchGetCarSection3);
    yield takeLatest(ActionTypes.GET_CAR_SECTION4, fetchGetCarSection4);
    yield takeLatest(ActionTypes.GET_CAR_SECTION5, fetchGetCarSection5);
    yield takeLatest(ActionTypes.GET_CAR_SECTION_BENNER, fetchGetCarBenner);

    yield takeLatest(ActionTypes.GET_WEATHER_DETAIL, fetchGetWeatherDetail);
    yield takeLatest(ActionTypes.GET_FEED_WEATHER, fetchGetFeedWeather);
    yield takeLatest(ActionTypes.GET_WEATHER_WEEK, fetchGetWeatherWeek);
    yield takeLatest(ActionTypes.GET_WEATHER_HOUR, fetchGetWeatherHour);
    yield takeLatest(ActionTypes.GET_WEATHER_ADDRESS_RESULT, fetchGetWeatherAddressResult);
    yield takeLatest(ActionTypes.GET_WEATHER_HISTORY_LIST, fetchGetWeatherHistoryList);
    /* new work end */

    yield takeLatest(ActionTypes.GET_FEED_TOP_LIST, fetchGetFeedTopList);
    yield takeLatest(ActionTypes.GET_FEED_TOP_PHOTO_LIST, fetchGetFeedTopPhotoList);
    yield takeLatest(ActionTypes.GET_FEED_CENTER_BOX_LIST, fetchGetFeedCenterBoxList);
    yield takeLatest(ActionTypes.GET_FEED_CENTER_GAME_MD_BOX_LIST, fetchGetFeedCenterGameMDBoxList);
    yield takeLatest(ActionTypes.GET_FEED_CENTER_GAME_N_BOX_LIST, fetchGetFeedCenterGameNBoxList);
    yield takeLatest(ActionTypes.GET_FEED_TODAY_TREND_BOX_LIST, fetchGetFeedTodayTrendBoxList);
    yield takeLatest(ActionTypes.GET_FEED_TODAY_TREND_FOOT_BOX_LIST, fetchGetFeedTodayFootBoxList);
    yield takeLatest(ActionTypes.GET_FEED_TOP_KEYWORD_LIST, fetchGetFeedTopKeyWordList);

    yield takeLatest(ActionTypes.GET_NEWS_MAIN_TOP_BULLETIN, fetchGetNewsMainTopBulletin);
    yield takeLatest(ActionTypes.GET_NEWS_MAIN_TOP_BOX, fetchGetNewsMainTopBox);
    yield takeLatest(ActionTypes.GET_NEWS_MAIN_TOP_PHOTO_BOX, fetchGetNewsMainPhotoBox);
    yield takeLatest(ActionTypes.GET_NEWS_MAIN_CENTER_BOX, fetchGetNewsMainCenterBox);
    yield takeLatest(ActionTypes.GET_NEWS_MAIN_CENTER_HEADLINE, fetchGetNewsMainheadLineBox);
    yield takeLatest(ActionTypes.GET_NEWS_MAIN_TODAY_NEWS, fetchGetNewsMainTodayNewsBox);


    yield takeLatest(ActionTypes.GET_SHOPPING_TODAY_TREND, fetchGetShoppingTodayTrend);
    yield takeLatest(ActionTypes.GET_SHOPPING_TODAY_TREND_FOOT, fetchGetShoppingTodayTrendFoot);
    yield takeLatest(ActionTypes.GET_SHOPPING_MD_RECOMMEND, fetchGetShoppingMdRecommend);
    yield takeLatest(ActionTypes.GET_SHOPPING_BEST_REVIEW, fetchGetShoppingBestReview);
    yield takeLatest(ActionTypes.GET_SHOPPING_BEST_REVIEW_FOOT_BOX, fetchGetShoppingBestReviewFootBox);
    yield takeLatest(ActionTypes.GET_SHOPPING_FASHION_BOX, fetchGetShoppingFashionBox);
    yield takeLatest(ActionTypes.GET_SHOPPING_FASHION_BOX_FOOT, fetchGetShoppingFashionBoxFoot);
    yield takeLatest(ActionTypes.GET_SHOPPING_TECH_IT_BOX, fetchGetShoppingTechItBox);
    yield takeLatest(ActionTypes.GET_SHOPPING_RECOMMEND_APP, fetchGetShoppingRecommendApp);
    yield takeLatest(ActionTypes.GET_SHOPPING_TOP_BNNER_BOX, fetchGetShoppingTopBnnerBox);
    yield takeLatest(ActionTypes.GET_SHOPPING_FOOT_BNNER_BOX, fetchGetShoppingFootBnnerBox);


    yield takeLatest(ActionTypes.GET_GAME_MD_RECOMMEND, fetchGetGameMDRecommend);
    yield takeLatest(ActionTypes.GET_GAME_NEW_GAME_BOX, fetchGetGameNewGameBox);
    yield takeLatest(ActionTypes.GET_GAME_OFFICAL_BOX, fetchGetGameOfficalBox);
    yield takeLatest(ActionTypes.GET_GAME_NEWS_BOX, fetchGetGameNewsBox);
    yield takeLatest(ActionTypes.GET_GAME_REVIEW_BOX, fetchGetGameReviewBox);
    yield takeLatest(ActionTypes.GET_GAME_TOP_BANNER_BOX, fetchGetGameTopBannerBox);
    yield takeLatest(ActionTypes.GET_GAME_FOOT_BANNER_BOX, fetchGetGameFootBannerBox);


    yield takeLatest(ActionTypes.GET_NEWS_SUB_TOP_PHOTOBOX, fetchGetNewsSubTopPhotobox);
    yield takeLatest(ActionTypes.GET_NEWS_SUB_FOOT_CATEGORY_PHOTOBOX, fetchGetNewsSubFootCategoryPhotobox);
    yield takeLatest(ActionTypes.GET_NEWS_SUB_NEWS_PROVIDER, fetchGetNewsSubNewsProvider);


    yield takeLatest(ActionTypes.TRY_INQUIRY_CREATE, fetchInquiryCreate);


    yield takeLatest(ActionTypes.GET_NEWS_SUB_CONTENT, fetchGetNewsSubContentProvider);





}

export default function* RootSaga() {
    yield all([
        actionWatcher(),
    ]);
}
