import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from 'lib/Helper';
import querySearch from "stringquery";
import { NewsSubMenu } from 'components';
import Dep1ScrollMenu from 'react-horizontal-scrolling-menu';
import Sidebar from "react-sidebar";
import { Cookies } from 'react-cookie';

import { getUserMyinfo, getBrowserToken } from 'store/Actions';

const cookies = new Cookies();

const dep1list = [
    { name: 'feed', text: '홈', state: '/', path: '/' },
    { name: 'news', text: '뉴스', state: '/news', path: '/news' },
    { name: 'game', text: '게임', state: '/game', path: '/game' },
    //{ name: 'car', text: '자동차', state: '/car', path: '/car' },
    { name: 'shopping', text: '쇼핑', state: '/shopping', path: '/shopping' },
    { name: 'map', text: '지도', state: '/map', path: '/map' }
];


export const numberFormat = (inputNumber) => {
   return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: (cookies.get('__token')) ? cookies.get('__token') : '',
            login_yn: (cookies.get('__useyn')) ? cookies.get('__useyn') : 0,
            searchInputValue: '',
            selected: 'feed',
            sidebarOpen: false,
            user_name: '',
            user_id: '',
            user_uid: '',
            user_point: 0,
            now_path: this.props.location.pathname,
            el_root_h: window.innerHeight,
            active_nav: false,
            transition: 0.9,
            width: window.innerWidth,
        }

        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);

        const MenuItem = ({text, state, path, selected}) => {
            return <div className={`dep1NavList-item ${selected ? 'active' : ''}`}>
                <Link to={{ pathname: path, state: state }}>
                    <span>{text}</span>
                </Link>
            </div>;
        };
        const Dep1Menu = (dep1list, selected) =>
            dep1list.map(el => {
                const {
                    name,
                    text,
                    state,
                    path,
                 
                } = el;
                return <MenuItem text={text} key={name} state={state} path={path} selected={selected} />;
            }
        );

        this.menuItems = Dep1Menu(dep1list, this.state.selected);
    }

    userLogout() {
        if(!alert('정말로 로그아웃 하시겠습니까?')) {
            cookies.remove('__token');
            cookies.remove('__useyn');
            window.location.href = '/';
        }
    }

    onSetSidebarOpen(open) {
        /* 임시적으로 메뉴 오픈 하지 않음
        if(open) {
            document.getElementById('root').style.height = this.state.el_root_h+'px';
            document.getElementById('root').style.overflow = 'hidden';
        } else {
            document.getElementById('root').style.height = 'auto';
            document.getElementById('root').style.overflow = 'visible';
        }
        
        this.setState({ sidebarOpen: open });
        */
    }

    onSelect = key => {
        this.setState({ selected: key });
    }
    

    actionDep1Menu = (root) => {
        this.setState({
            now_path: root,
            active_nav: true,
        });
    }

    _handelInputSearch = () => {
        // const searString = (this.state.searchInputValue) ? encodeURIComponent(this.state.searchInputValue).replace(/%20/g,'+') : '';
        const searString = (this.state.searchInputValue) ? encodeURIComponent(this.state.searchInputValue) : '';
        //window.location.href = 'https://www.google.com/search?client=ms-google-coop&q='+searString+'&cx=partner-pub-4764167868603852:1299124368';
        window.open('https://www.google.com/search?client=ms-google-coop&q='+searString+'&cx=partner-pub-4764167868603852:1299124368', '_blank');
        /*this.props.history.push({
            pathname: '/search',
            search: 'q='+searString,
            state: { s:searString }
          })*/
    }

    _handleOnKeyPressItem = (e) => {
        if (e.nativeEvent.key === "Enter") {
            this._handelInputSearch();
        }
    };

    _updateInputValue = (e) => {
        this.setState({
            searchInputValue: e.target.value
        });
    }

    _openOneInquiry = (e) => {
        this.onSetSidebarOpen(false);
        this.setState({
            active_nav: false,
        })
    }

    componentDidMount() {
        document.getElementById('root').style.height = 'auto';
        document.getElementById('root').style.overflow = 'visible';
        if(this.state.token === '') {
            cookies.set('__useyn', 0, { path: '/' });
            this.props.getBrowserToken();
        } else {
            if(this.state.login_yn > 0) {
                const { token } = this.state;
                let parmas = {
                    token: token,
                }
                this.props.getUserMyinfo(parmas);
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if(Helper.isEmpty(nextProps.location.search) === false) {
            const searchQueryString = (Helper.isEmpty(querySearch(nextProps.location.search).q) === false) ? querySearch(nextProps.location.search).q : 'SKT';
            this.setState({
                searchInputValue : decodeURIComponent(searchQueryString)
            });
        }

        if(nextProps.USER_MYINFO.state === true && this.props.USER_MYINFO.state !== nextProps.USER_MYINFO.state) {
            this.setState({
                user_name: nextProps.USER_MYINFO.payload.name,
                user_uid: nextProps.USER_MYINFO.payload.uid,
                user_id: nextProps.USER_MYINFO.payload.id,
                user_point: nextProps.USER_MYINFO.payload.point
            })
        }
        if(nextProps.BROWSER_TOKEN.state === true && this.props.BROWSER_TOKEN.state !== nextProps.BROWSER_TOKEN.state) {
            cookies.set('__token', nextProps.BROWSER_TOKEN.payload.token, { path: '/' });
            this.setState({
                token: nextProps.BROWSER_TOKEN.payload.token,
            })
        }
    }


    componentWillMount() {

        //상단 메뉴바 active 변경
        var pathnames = document.location.pathname;
        var pathnames2 = pathnames.replace("/", "");
        if(pathnames2){
            this.onSelect(pathnames2);
        }
        else{

            this.onSelect("feed");
        }


       if(Helper.isEmpty(this.props.location.state) === false && Helper.isEmpty(this.props.location.state.q) === false) {
            const searchQueryString = (Helper.isEmpty(this.props.location.state.q) === false) ? this.props.location.state.q : 'SKT';
            this.setState({
                searchInputValue : decodeURIComponent(searchQueryString)
            });
        }

        this.setState({width : window.innerWidth});
    }

    render() {


        const headMenuRe={
                padding:"0 0 0 1.5%",
            }
            
        
        try{
            //SK페이징뷰
            window.skTrackPageView();
            
        }catch(e){

        }

        let nowHostName = window.location.hostname;
        // let x = window.location.protocol;
        const { selected } = this.state;
        // Create menu from items
        const Dep1MenuItem = this.menuItems;

        const newsSubYn = this.props.location.pathname.search('/news/sub/');

        let sidebarContent = <div>
            <div className="slideSidebarBox before" style={{ display:(this.state.user_uid === '') ? 'block' : 'none' }}>
                <div className="sidebarHeader">
                    <div>
                        <Link to="/login" onClick={() => this.onSetSidebarOpen(false)}>T-page 로그인</Link>
                    </div>
                    <div>
                        <button onClick={() => this.onSetSidebarOpen(false)}>
                            <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/webtoon/exit.svg'} />
                        </button>
                    </div>
                </div>
                <div className="sidebarBody">
                    <div className="sidebarBodyList">
                        <ul>
                            <li>
                                <a href={process.env.REACT_APP_PRODUCTION_COIN_CARGE_URL+"?userCode="+this.state.token}>포인트 충전하기</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="slideSidebarBox after" style={{ display:(this.state.user_uid === '') ? 'none' : 'block' }}>
                <div className="sidebarHeader">
                    <div>
                        <div>
                            <span>{ (this.state.user_name === '') ? '홍길동' : this.state.user_name }님</span>
                            <span>환영합니다.</span>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => this.onSetSidebarOpen(false)}>
                            <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/webtoon/exit.svg'} />
                        </button>
                    </div>
                </div>
                <div className="sidebarBody">
                    <div className="sidebarBodyInfo">
                        <div>
                            <div>T-page 포인트</div>
                            <div>
                                <div>
                                    <span>{numberFormat(this.state.user_point)}</span>
                                    <span>POINT</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <a href="/point">충전</a>
                        </div>
                    </div>
                    <div className="sidebarBodyList">
                        <ul>
                            <li>
                                <Link to="/mypage">내 정보</Link>
                            </li>
                            <li>
                                <Link to="/tpoint">포인트 충전 및 사용내역</Link>
                            </li>
                            <li>
                                <Link to="/oneonone" onClick={() => this._openOneInquiry()}>1:1 문의하기</Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => this.userLogout()}>로그아웃</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        return (
            <header>                
                {/* <Sidebar
                  sidebar={sidebarContent}
                  open={this.state.sidebarOpen}
                  onSetOpen={this.onSetSidebarOpen}
                  styles={{ sidebar: { background: "white" } }}
                  sidebarClassName="customSlideSideBar"
                >
                </Sidebar> */}
                <div id="header">
                    <h1 style={{background:'#E7193D', marginBottom:'10px', height:'5rem'}} onClick={() => this.onSelect('feed')}>
                        <Link to={{ pathname: '/', state: '' }}>
                            <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/freewifi_logo.png'} alt="FREE WIFI ZONE" style={{width:'15rem'}}/>
                        </Link>
                    </h1>
                    <fieldset className="searchField">
                        <legend>검색영역</legend>
                        <input type="text"
                            onKeyPress = { this._handleOnKeyPressItem }
                            value={this.state.searchInputValue}
                            onChange={this._updateInputValue}
                        />
                        <button className="btnSearch" onClick={this._handelInputSearch}>검색</button>
                    </fieldset>
                    {/* <button type="button" className="btnMenu" onClick={() => this.onSetSidebarOpen(true)}>
                        <i class="ion ion-md-menu d-block"></i>
                    </button> */}

                    <nav className="headNav" style={headMenuRe}>
                        <div className="dep1NavList" >
                        <Dep1ScrollMenu
                            data={Dep1MenuItem}
                            wheel={false}
                            selected={selected}
                            scrollToSelected={true}
                            onSelect={this.onSelect}
                            alignCenter={(this.state.width >= 600)}
                            arrowDisabledClass={true}
                            hideSingleArrow={true}
                            hideArrows={true}
                            transition={+this.state.transition}
                        />
                        </div>
                        { (this.props.location.pathname === '/news/content/' || this.props.location.pathname === '/news' || newsSubYn === 0|| this.props.location.pathname === '/news/sub/:news_cateory') &&
                        <NewsSubMenu />
                        }
                    </nav>
                </div>
            </header>
        );
    }
};

const mapStateToProps = state => ({
    USER_MYINFO: state.USER_MYINFO,
    selected: state.selected,
    BROWSER_TOKEN: state.BROWSER_TOKEN,
});

const mapDispatchToProps = {
    getUserMyinfo,
    getBrowserToken,
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Header));
