import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Header,Footer } from 'components';
import * as Helper from 'lib/Helper';
import { Link } from "react-router-dom";
import { Cookies } from 'react-cookie';

import { getUserMyinfo, tryWithdrawal, tryPasswordCheck } from 'store/Actions';

const cookies = new Cookies();
export class WithdrawalSecondComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: (cookies.get('__token')) ? cookies.get('__token') : '',
            uid: '',
            user_id: '',
            password: '',
            password_check: '',
            password_yn: false,
        };
    }

    handleOnchange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    _WithdrawalAction = () => {
        let {
            token,
            uid,
            user_id,
            password,
            password_check,
            password_yn,
        } = this.state,
        params = {
            token: token.trim(),
            uid: uid.trim(),
            user_id: user_id.trim(),
            password: password.trim(),
            password_check: password_check.trim(),
        }

        if(password.trim() === '') {
            Helper.globalAlert({text:'비밀번호를 입력해주세요.'});
            return false;
        }

        if(password_check.trim() === '') {
            Helper.globalAlert({text:'비밀번호 확인을 입력해주세요.'});
            return false;
        }

        if(password.trim() !== password_check.trim()) {
            Helper.globalAlert({text:'비밀번호가 확인과 일치하지 않습니다.'});
            return false;
        }

        this.props.tryPasswordCheck(params);
    }

    componentDidMount(){
        // console.debug({ name:'componentDidMount'});
        if(this.state.token === '') {
            window.location.href = '/';
        } else {
            const { token } = this.state;
            let parmas = {
                token: token,
            }
            this.props.getUserMyinfo(parmas);
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.debug({ name:'Component WILL RECIEVE PROPS!', nextProps: nextProps})
        if(nextProps.USER_MYINFO.state === true && this.props.USER_MYINFO.state !== nextProps.USER_MYINFO.state) {
            this.setState({
                user_id: nextProps.USER_MYINFO.payload.id,
                uid: nextProps.USER_MYINFO.payload.uid,
            })
        }

        if(nextProps.PASSWORD_CHECK.state === true && this.props.PASSWORD_CHECK.state !== nextProps.PASSWORD_CHECK.state) {
            let {
                token,
                uid,
                user_id,
                password,
                password_check,
                password_yn,
            } = this.state,
            params = {
                token: token.trim(),
                uid: uid.trim(),
                user_id: user_id.trim(),
                password: password.trim(),
                password_check: password_check.trim(),
            };

            const swal = require('sweetalert');
            swal({
                title: "WARNING",
                text: "정말로 탈퇴 하시겠습니까?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    this.props.tryWithdrawal(params);
                } else {
                    return false;
                }
            }); 
        }

        if(nextProps.WITHDRAWAL.state === true && this.props.WITHDRAWAL.state !== nextProps.WITHDRAWAL.state) {
            const swal = require('sweetalert');
            swal({
                text: "탈퇴되었습니다. 지금까지 T-Page를 이용해주셔서 감사합니다.",
                buttons: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    window.location.href = '/';
                } else {
                    window.location.href = '/';
                }
            }); 
        }
        
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.debug({ name:'ShouldComponentUpdate', nextProps: nextProps, nextState:nextState});
        return true;
    }

    componentWillUpdate(nextProps, nextState) {
        // console.debug({ name:'Component WILL UPDATE!' , nextProps: nextProps, nextState:nextState});
    }

    componentDidUpdate(prevProps, prevState) {
        // console.debug({ name:'Component DID UPDATE!' , prevProps: prevProps, prevState:prevState}
    }

    componentDidCatch(error, info) {
        // console.debug({ name:'Component DID Catch!' , error: error, info: info})
        //Handle error.
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        return (
            <div id="wrap">
                <div id="container">
                    <div className="withdrawalPage">
                        <div className="withdrawalPageHeader">
                            <Link to="/mypage">
                                <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/webtoon/before.svg'} />
                            </Link>
                            <div>회원탈퇴</div>
                        </div>
                        <div className="withdrawalPageBody">
                            <h2>
                                <span>비밀번호 재확인</span>
                            </h2>
                            <ul className="withdrawalPasswordForm">
                                <li>
                                    <input type="password" name="password" onChange={this.handleOnchange} placeholder="비밀번호 입력" />
                                </li>
                                <li>
                                    <input type="password" name="password_check" onChange={this.handleOnchange} placeholder="비밀번호 확인" />
                                </li>
                            </ul>
                            <button onClick={ () => {this._WithdrawalAction()}}>회원탈퇴</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => ({
    USER_MYINFO: state.USER_MYINFO,
    WITHDRAWAL: state.WITHDRAWAL,
    PASSWORD_CHECK: state.PASSWORD_CHECK,
});

const mapDispatchToProps = {
    getUserMyinfo,
    tryWithdrawal,
    tryPasswordCheck,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(WithdrawalSecondComponent));
