export default {
    CERTIFICATION : {},
    CONFIRM : {},
    
    LOG_PHONE : {},
    BROWSER_TOKEN : {},
    DEFAULT_JOIN_MEMBER : {},
    JOIN_MEMBER : {},
    WITHDRAWAL : {},

    USER_CHANGE_PW : {},
    DEFAULT_CHANGE_PW: {},
    PASSWORD_CHECK: {},

    ONE_INQUIRT: {},

    LOGIN_USER : {},
    USER_MYINFO : {},
    USER_MYPOINT: {},

    BASE_STORE : {},
    FEED : {},
    NEWS_MAIN : {},
    NEWS_SUB : {},
    GAME : {},
    SHOPPING : {},
    MAP : {},
    SEARCH : {},
    USER : {},
    INQUIRY_CREATE : {},
    WEATHER : {},
    CAR: {},
};
