import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
    testAction,
} from 'store/Actions'

export class MainComponents extends Component {

    render() {
        return (

            <div id="wrap">

                <header id="header">
                    <h1><a href="#asdas"><img src="http://t-page.co.kr/resource/images/img_logo.png" alt="t-page"/></a></h1>
                    <button type="button" className="btnMenu">메뉴</button>
                    <fieldset className="searchField">
                        <legend>검색영역</legend>
                            <input type="text" />
                        <button className="btnSearch">검색</button>
                    </fieldset>
                </header>

                <div id="container">

                    <nav className="navMenu">
                        <ul className="dp1">
                            <li className="navNews"><a href="news.html">뉴스</a></li>
                            <li className="navGame"><a href="game.html">게임</a></li>
                            <li className="navShoping"><a href="shoping.html">쇼핑</a></li>
                            <li className="navMap"><a href="map.html">지도</a></li>
                            <li className="navSearch"><a href="search.html">검색</a></li>
                        </ul>
                    </nav>

                    <div className="dataChart">
                        <ul>
                            <li>
                                <span className="chart"><img src="http://t-page.co.kr/resource/images/sample4.png" alt=""/></span>남은데이터<br /><strong>95.75GB</strong> 남음
                            </li>
                            <li>
                                <span className="chart"><img src="http://t-page.co.kr/resource/images/sample5.png" alt=""/></span>영상,부가전화<br /><strong>5시간 0분</strong> 남음
                            </li>
                            <li>
                                <span className="chart"><img src="http://t-page.co.kr/resource/images/sample6.png" alt=""/></span>SMS/MMS<br /><strong>무제한</strong>
                            </li>
                        </ul>
                    </div>

                    <p className="weatherWrap">
                        <span className="state">
                            <img src="http://t-page.co.kr/resource/images/img_weather.png" alt="구름낌"/>
                        </span>
                        <span className="area">서울</span> 미세먼지 <strong className="dustState">보통</strong>
                        <span className="temp">18.8°</span>
                    </p>

                    <section className="realTimeKeyword">
                        <h2 className="title">실시간 키워드</h2>
                        <ul className="list">
                            <li><a href="asdasd#"><span className="num">1</span>로스트 아크로스트 아크로스트 아크</a></li>
                            <li><a href="asdasd#"><span className="num">2</span>로스트 아크</a></li>
                            <li><a href="asdasd#"><span className="num">3</span>로스트 아크</a></li>
                            <li><a href="asdasd#"><span className="num">4</span>로스트 아크</a></li>
                            <li><a href="asdasd#"><span className="num">5</span>로스트 아크</a></li>
                        </ul>
                        <button type="button" className="btn">펼쳐보기</button>
                    </section>

                    <div className="banner">
                        <a href="asdasd#"><img src="http://t-page.co.kr/resource/images/img_ad.png" alt=""/></a>
                    </div>
                </div>

                <footer id="footer">
                    <div className="privacy">
                        <span><a href="asdasd#">이용약관</a></span>
                        <span><a href="asdasd#">개인정보처리방침</a></span>
                    </div>
                    <small>&copy; MONSTER CUBE Corp.</small>
                </footer>

            </div>

        );
    }
};


const mapStateToProps = state => ({
    base:state.base
});

const mapDispatchToProps = {
    testAction
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MainComponents));

