import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from 'lib/Helper';
import { Cookies } from 'react-cookie';

import { Swiper, Slide } from 'react-dynamic-swiper';
import 'react-dynamic-swiper/lib/styles.css';

import {
    getWeatherDetail,
} from 'store/Actions';

import {
    DetailWeaterBox,
    DetailWeaterHourLine,
    DetailWeaterWeekList,
} from 'elements';

class WeatherDetailComponent extends Component {
    constructor(props) {
        const cookies = new Cookies();
        super(props);

        let history = cookies.get('weatherHistory');
        let cities = [];
        if(history != null) {
            history.map((item, i) => {
                cities = cities.concat(item.mCode);
            })
        }

        this.state = {
            city_code: (cookies.get('cityCode')) ? cookies.get('cityCode') : '4713000000',
            cities: (cities.join() != '') ? cities.join() : '1168000000,4713000000',
            city_idx: (cookies.get('cityIdx')) ? cookies.get('cityIdx') : '0',
        };
    }

    _getWeatherDetail = () => {
        this.props.getWeatherDetail(this.state.cities);
    }

    startPage  = () => {
        this._getWeatherDetail();
    }

    componentWillReceiveProps(nextProps) {
    }

    componentDidUpdate() {
    }

    componentDidMount() {
        this.startPage();
    }
/* 이제 여기 form 만들면 됨 */
    render() {
        return (
            <div id="wrap">
                <div id="container">
                    <div className="weatherDetail">
                        <div className="weatherDetailScroll">
                            <Swiper
                                swiperOptions={{
                                    slidesPerView: 'auto',
                                    initialSlide: this.state.city_idx
                                }}
                                navigation={false}
                                pagination={false}
                            >
                                {this.props.weaterWeekDetail ? this._renderList() : <div></div>}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderList = () => {
        let list = <div></div>;
        const data = this.props.weaterWeekDetail;

        if (data.state){
            list = data.payload.map((item, i) => {
                let cityCode = item.cityCode;
                let now = item.now;
                let week = item.week;
                let hour = item.hour;

                return <Slide key={cityCode}>
                    <div className="weatherNowBox">
                        <DetailWeaterBox
                            WEATHER_DATA={now}
                        />
                    </div>
                    <div className="weatherHourLine">
                        <div>
                            <DetailWeaterHourLine
                                WEATHER_DATA={hour}
                            />
                        </div>
                    </div>
                    <div className="weatherWeekList">
                        <div>
                            <ul>
                                <DetailWeaterWeekList
                                    WEATHER_DATA={week}
                                />
                            </ul>
                        </div>
                    </div>
                </Slide>
            })
        }
        return list;
    }
};

WeatherDetailComponent.defaultProps = {
    weaterWeekDetail: {
        state: false,
        payload : {}
    },
};

const mapStateToProps = state => ({
    weaterWeekDetail: state.WEATHER.weaterWeekDetail,
});

const mapDispatchToProps = {
    getWeatherDetail,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(WeatherDetailComponent));
