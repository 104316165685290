// @flow
import React, { Component } from 'react';
// import { HashRouter } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import Root from 'Root';
import { Provider } from 'react-redux';
import store from './store';
import ReactGA from 'react-ga';
import { CookiesProvider } from 'react-cookie';

ReactGA.initialize('UA-141830007-1');

class App extends Component {
    componentDidMount() {
        // console.debug({ name:'Component DID MOUNT!', state: this.state})
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <CookiesProvider>
                <Provider store = { store }>
                    <BrowserRouter>
                        <Root />
                    </BrowserRouter>
                </Provider>
            </CookiesProvider>
        );
    }
}


export default App;
