import React, { Component } from 'react'
import { Marker } from 'react-google-maps'
import { PlaceInfoWindow } from './PlaceInfoWindow'

export class PlaceMarker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showTooltip: true
        }
    }


    clickTooltip() {
        this.setState({ showTooltip: !this.state.showTooltip })
    }

    closeWindow() {
        this.setState({ showTooltip: false })
    }

    render() {
        const {showTooltip} = this.state
        const {lat, lng, name} = this.props

        // console.debug(this.props);

        // console.debug({
        //     name:'maker',
        //     latitude:parseFloat(lat),
        //     longitude:parseFloat(lng),
        // })

        return(
            <Marker
                position={{
                    lat: parseFloat(lat),
                    lng: parseFloat(lng)
                }}
                onClick={this.clickTooltip.bind(this)}
            >
                {
                    (showTooltip && name) && (
                        <PlaceInfoWindow
                            name={name}
                            closeWindow={this.closeWindow.bind(this)}
                        />
                    )
                }
            </Marker>
        );
    }
}

export default PlaceMarker