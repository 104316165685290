import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Header,Footer } from 'components';
import * as Helper from 'lib/Helper';
import { Cookies } from 'react-cookie';

import {
    getWeatherHistoryList,
} from 'store/Actions';

const cookies = new Cookies();

export class WeatherSearchHistoryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            histories : (cookies.get('weatherHistory')) ? cookies.get('weatherHistory') : '',
        };
    }

    _moveDetail = (mCode, idx) => {
        cookies.set('cityCode', mCode, { path: '/' });
        cookies.set('cityIdx', idx, { path: '/' });
        window.location = '/weather/detail';
    }

    _getWeatherHistoryList = (str) => {
        this.props.getWeatherHistoryList(str);
    }

    startPage  = () => {
        let history = this.state.histories;
        let histories = [];

        history.map((item, i) => {
            histories[item.idx] = item.mCode;
        })

        this._getWeatherHistoryList(JSON.stringify(histories));
    }

    componentWillReceiveProps(nextProps) {
    }

    componentDidUpdate() {
    }

    componentDidMount() {
        this.startPage();
    }

    render() {
        return (
            <div id="wrap">
                <Header/>
                <div id="container">
                    <div className="weatherHistoryList">
                        {this.props.weaterHistoryList ? this._renderList() : <div></div>}
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }

    _renderList = () => {
        let list = <li><a>검색어를 입력해주세요</a></li>;
        const data = this.props.weaterHistoryList;

        if (data.state){
            list = data.payload.map((item, i) => {
                console.log(item.city_str);
                console.log(i);
                return  <div key={item.seq}>
                    <a onClick={() => this._moveDetail(item.mCode, i)}>
                        <div>
                            <div>
                                <p>{item.time}</p>
                                <p>{item.city_str}</p>
                            </div>
                            <div>
                                <p>{item.temp}°</p>
                            </div>
                        </div>
                    </a>
                </div>
            })
        }
        return list;
    }
};

WeatherSearchHistoryComponent.defaultProps = {
    weaterHistoryList: {
        state: false,
        payload : {}
    },
};

const mapStateToProps = state => ({
    weaterHistoryList: state.WEATHER.weaterHistoryList,
});

const mapDispatchToProps = {
    getWeatherHistoryList,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(WeatherSearchHistoryComponent));