import React, { Component } from "react";
// import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import * as API from 'lib/API';

// import * as Helper from 'lib/Helper';
// import { getGooglePlaceData } from 'store/Actions';

// const iframeURL = 'googlesearch.html?q=%EB%B0%95%EC%84%B1%EB%AF%BC';

export class Test extends Component {

    constructor() {
        super();
        this.state = {
            iFrameHeight: '0px'
        }
    }

    render() {
        return (
<div></div>
        );
    }
};

// export default Test;

const mapStateToProps = state => ({
    // GoolePlaceData: state.GoolePlaceData
});

const mapDispatchToProps = {
    // getGooglePlaceData
};



export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Test));