import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from 'lib/Helper';

import {
    getNewsContentProvider,
} from 'store/Actions';

import {
    NewsSubNewsContentBox,
    MobionBanner,
    InterWorksMediaBanner
 } from 'elements';

export class NewsSubComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news_sid : (this.props.match.params.news_sid) ? this.props.match.params.news_sid : '',
        };
    }

    _getNewsContentProvider = (sid) => {
        this.props.getNewsContentProvider(sid);
    }


    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.news_sid !== nextProps.match.params.news_sid) {
            this.setState({
                news_sid:nextProps.match.params.news_sid
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextState.news_sid !== this.state.news_sid) {
            this._getNewsContentProvider(this.state.news_sid);
        }
        return true;
    }

    componentDidUpdate() {
    }

    componentDidMount() {
        this._getNewsContentProvider(this.state.news_sid);
    }


    render() {
        return (
            <div id="wrap">
                <div id="container">
                    <section className="linkList">
                        <div className="newsContent">
                            <ul>
                                <NewsSubNewsContentBox
                                    CONTENTS_LIST={this.props.NewsContentBox}
                                    />
                            </ul>
                        </div>
                    </section>
                    <div className="banner">
                        {/*<MobionBanner
                            BANNER_TYPE="NEWS_TOP"
                        />*/}

                        {/* <InterWorksMediaBanner /> */}
                    </div>
                </div>
            </div>
        );
    }
};

NewsSubComponent.defaultProps = {
    NewsContentBox: {
        state: false,
        payload : {}
    },
};

const mapStateToProps = state => ({
    NewsContentBox : state.NEWS_SUB.NewsContentBox,
});

const mapDispatchToProps = {
    getNewsContentProvider,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsSubComponent));
