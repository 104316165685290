import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Header,Footer } from 'components';
import * as Helper from 'lib/Helper';
import { Link } from "react-router-dom";
import { Cookies } from 'react-cookie';

import { getUserMyinfo, tryUserChangePw } from 'store/Actions';

import {} from 'elements';

const cookies = new Cookies();
export class MypageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: (cookies.get('__token')) ? cookies.get('__token') : '',
            openyn: false,
            user_id: '',
            uid: '',
            password: '',
            change_pwd: '',
            change_pwd_check: '',
        };
    }

    handleOnchange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    _openChangeForm = () => {
        this.setState({
            openyn: true
        })
    }

    _closeChangeForm = () => {
        this.setState({
            openyn: false,
            password: '',
            change_pwd: '',
            change_pwd_check: '',
        })
    }

    _ChangePwAction = () => {
        let {
            token,
            password,
            change_pwd,
            change_pwd_check,
        } = this.state;

        let params = {
            token: token,
            password: password,
            change_pwd: change_pwd,
            change_pwd_check: change_pwd_check,
        }

        this.props.tryUserChangePw(params);
    }

    componentDidMount(){
        // console.debug({ name:'componentDidMount'});
        if(this.state.token === '') {
            window.location.href = '/';
        } else {
            const { token } = this.state;
            let parmas = {
                token: token,
            }
            this.props.getUserMyinfo(parmas);
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.debug({ name:'Component WILL RECIEVE PROPS!', nextProps: nextProps})
        if(nextProps.USER_MYINFO.state === true && this.props.USER_MYINFO.state !== nextProps.USER_MYINFO.state) {
            this.setState({
                user_id: nextProps.USER_MYINFO.payload.id,
                uid: nextProps.USER_MYINFO.payload.uid,
            })
        }
        if(nextProps.USER_CHANGE_PW.state === true && this.props.USER_CHANGE_PW.state !== nextProps.USER_CHANGE_PW.state) {
            Helper.globalAlert({text:'비밀번호가 변경되었습니다.'});
            this._closeChangeForm();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.debug({ name:'ShouldComponentUpdate', nextProps: nextProps, nextState:nextState});
        return true;
    }

    componentWillUpdate(nextProps, nextState) {
        // console.debug({ name:'Component WILL UPDATE!' , nextProps: nextProps, nextState:nextState});
    }

    componentDidUpdate(prevProps, prevState) {
        // console.debug({ name:'Component DID UPDATE!' , prevProps: prevProps, prevState:prevState}
    }

    componentDidCatch(error, info) {
        // console.debug({ name:'Component DID Catch!' , error: error, info: info})
        //Handle error.
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        return (
            <div id="wrap">
                <div id="container">
                    <div className="mypagePage">
                        <div className="mypagePageHeader">
                            <Link to="/">
                                <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/webtoon/before.svg'} />
                            </Link>
                            <div>내 정보</div>
                        </div>
                        <div className="mypagePageBody">
                            <div>
                                <div>
                                    <span>아이디</span>
                                </div>
                                <div>
                                    <span style={{margin:'auto'}}>{ (this.state.user_id === '') ? '홍길동' : this.state.user_id }</span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>비밀번호</span>
                                </div>
                                <div>
                                    <button onClick={ () => {this._openChangeForm()}}>변경하기</button>
                                </div>
                            </div>
                        </div>
                        <div className="mypagePageOpen" style={{display: (this.state.openyn) ? 'block' : 'none'}}>
                            <div>
                                <div>
                                    <div>
                                        <span>현재비밀번호</span>
                                    </div>
                                    <div>
                                        <input type="password" name="password" value={this.state.password} onChange={this.handleOnchange} />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <span>신규비밀번호</span>
                                    </div>
                                    <div>
                                        <input type="password" name="change_pwd" value={this.state.change_pwd} onChange={this.handleOnchange} />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <span>비밀번호확인</span>
                                    </div>
                                    <div>
                                        <input type="password" name="change_pwd_check" value={this.state.change_pwd_check} onChange={this.handleOnchange} />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <button onClick={() => this._ChangePwAction()}>변경</button>
                                        <button onClick={ () => {this._closeChangeForm()}}>취소</button>
                                    </div>
                                </div>
                            </div>
                            <div>* 비밀번호는 6자리 이상입니다.</div>
                        </div>
                        <div className="mypageMemberOut">
                            <Link to="/withdrawal/first">
                                <span>회원탈퇴</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => ({
    USER_MYINFO: state.USER_MYINFO,
    USER_CHANGE_PW: state.USER_CHANGE_PW,
});

const mapDispatchToProps = {
    getUserMyinfo,
    tryUserChangePw,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MypageComponent));
