import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import * as Helper from 'lib/Helper';



export class ToSComponent extends Component {

    
    render() {       
            return (
                <div id="wrap">
                    <div id="container">
                        <div className="tos">
                            <br/>제1조(목적)<br/><br/>

                            이 약관은 주식회사 메타큐브(전자상거래 사업자)가 운영하는 시작페이지 웹서비스 (이하 “Page”으로 한다)에서 제공하는 전자상거래 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 “Page”와 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다. <br/><br/>

                            *PC통신, 스마트폰 앱, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 준용합니다. <br/><br/>


                            제2조(정의) <br/><br/>

                            ① “Page”이란 (주)메타큐브 회사가 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 서비스를 운영하는 사업자의 의미로도 사용합니다. <br/><br/>

                            ② “이용자”란 “Page”에 접속하여 이 약관에 따라 “Page”이 제공하는 서비스를 받는 회) 및 비회원을 말합니다. <br/><br/>

                            ③ ‘회원’이라 함은 “Page”에 회원등록(일반회원 및 seller회원)을 한 자로서, 계속적으로 “Page”이 제공하는 서비스를 이용할 수 있는 자를 말합니다. <br/><br/>


                            제3조 (약관 등의 명시와 설명 및 개정) <br/><br/>

                            ① “Page”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호․모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 Page의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다. <br/><br/>

                            ② “Page”은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회․배송책임․환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다. <br/><br/>

                            ③ “Page”은「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자 서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. <br/><br/>

                            ④ “Page”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 “Page”은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다. <br/><br/>

                            ⑤ “Page”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “Page”에 송신하여 “Page”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다. <br/><br/>

                            ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다. <br/><br/>


                            제4조(서비스의 제공 및 변경) <br/><br/>

                            ① “Page”은 다음과 같은 업무를 수행합니다. <br/><br/>

                            1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결<br/><br/>

                            2. 구매계약이 체결된 재화 또는 용역의 배송<br/><br/>

                            3. 기타 “Page”이 정하는 업무<br/><br/>

                            ② “Page”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다. <br/><br/>

                            ③ “Page”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다. <br/><br/>

                            ④ 전항의 경우 “Page”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “Page”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다. <br/><br/>


                            제5조(서비스의 중단) <br/><br/>

                            ① “Page”은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다. <br/><br/>

                            ② “Page”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “Page”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다. <br/><br/>

                            ③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “Page”은 제8조에 정한 방법으로 이용자에게 통지하고 당초 “Page”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, “Page”이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “Page”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다. <br/><br/>

                            ④ 휴대폰 번호 변경 시 기존 누적된 토큰 및 코인이 유실될 수 있으니 꼭 지갑으로 이동 후 재 인증을 통해 사용해 주시기 바랍니다. <br/><br/>


                            제6조(회원가입) <br/><br/>

                            ① 이용자는 “Page”이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다. <br/><br/>

                            ② “Page”은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다. <br/><br/>

                            1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “Page”의 회원재가입 승낙을 얻은 경우에는 예외로 한다. <br/><br/>

                            2. 등록 내용에 허위, 기재누락, 오기가 있는 경우<br/><br/>

                            3. 기타 회원으로 등록하는 것이 “Page”의 기술상 현저히 지장이 있다고 판단되는 경우<br/><br/>

                            4. 회원가입 및 전자상거래상의 계약에 관한 서비스는 만 14세 이상인자에 한함<br/><br/>

                            ③ 회원가입계약의 성립 시기는 “Page”의 승낙이 회원에게 도달한 시점으로 합니다. <br/><br/>

                            ④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 “Page”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다. <br/><br/>


                            제7조(회원 탈퇴 및 자격 상실 등) <br/><br/>

                            ① 회원은 “Page”에 언제든지 탈퇴를 요청할 수 있으며 “Page”은 즉시 회원탈퇴를 처리합니다. <br/><br/>

                            ② 회원이 다음 각 호의 사유에 해당하는 경우, “Page”은 회원자격을 제한 및 정지시킬 수 있습니다. <br/><br/>

                            1. 가입 신청 시에 허위 내용을 등록한 경우<br/><br/>

                            2. “Page”을 이용하여 구입한 재화 등의 대금, 기타 “Page”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우<br/><br/>

                            3. 다른 사람의 “Page” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우<br/><br/>

                            4. “Page”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br/><br/>

                            ③ “Page”이 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “Page”은 회원자격을 상실시킬 수 있습니다. <br/><br/>

                            ④ “Page”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다. <br/><br/>


                            제8조(회원에 대한 통지) <br/><br/>

                            ① “Page”이 회원에 대한 통지를 하는 경우, 회원이 “Page”과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다. <br/><br/>

                            ② “Page”은 불특정다수 회원에 대한 통지의 경우 1주일이상 “Page” 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다. <br/><br/>


                            제9조(구매신청) <br/><br/>

                            ① “Page”이용자는 “Page”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “Page”은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다. <br/><br/>

                            1. 재화 등의 검색 및 선택<br/><br/>

                            2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력<br/><br/>

                            3. 약관내용, 청약 철회 권이 제한되는 서비스, 배송료 등의 비용부담과 관련한 내용에 대한 확인<br/><br/>

                            4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)<br/><br/>

                            5. 재화등의 구매신청 및 이에 관한 확인 또는 “Page”의 확인에 대한 동의<br/><br/>

                            6. 결제방법의 선택<br/><br/>

                            ② “Page”이 제3자에게 구매자 개인정보를 제공•위탁할 필요가 있는 경우 실제 구매신청 시 구매자의 동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이 때 “Page”은 제공되는 개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용 목적 및 보유‧이용 기간 등을 구매자에게 명시하여야 합니다. 다만 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 제25조 제1항에 의한 개인정보 취급위탁의 경우 등 관련 법령에 달리 정함이 있는 경우에는 그에 따릅니다. <br/><br/>


                            제10조 (계약의 성립) <br/><br/>

                            ① “Page”은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다. <br/><br/>
                            
                            1. 신청 내용에 허위, 기재누락, 오기가 있는 경우<br/><br/>

                            2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우<br/><br/>

                            3. 기타 구매신청에 승낙하는 것이 “Page” 기술상 현저히 지장이 있다고 판단하는 경우<br/><br/>

                            ② “Page”의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다. <br/><br/>

                            ③ “Page”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다. <br/><br/>


                            제11조(지급방법) <br/><br/>

                            “Page”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, “Page”은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다. <br/><br/>

                            ① 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체<br/><br/>

                            ② 선불카드, 직불카드, 신용카드 등의 각종 카드 결제<br/><br/>

                            ③ 온라인무통장입금<br/><br/>

                            ④ 전자화폐에 의한 결제<br/><br/>

                            ⑤ 수령 시 대금지급<br/><br/>
     
                            ⑥ 마일리지 등 “Page”이 지급한 적립금에 의한 결제<br/><br/>

                            ⑦ “Page”과 계약을 맺었거나 “Page”이 인정한 상품권에 의한 결제<br/><br/>

                            ⑧ 기타 전자적 지급 방법에 의한 대금 지급 등<br/><br/>


                            제12조(적립금) <br/><br/>

                            ① “Page”은 회원의 구매활동, 이벤트 참여 등에 따라 회원에게 일정한 적립금을 부여할 수 있습니다. <br/><br/>

                            ② 회원은 적립금을 “Page”에서 상품 등의 구매 시 결제 수단으로 사용할 수 있으며, “Page”은 적립금의 적립기준, 사용방법, 사용기간 및 제한에 대한 사항을 사이트에 별도로 게시하거나 통지합니다. 적립금의 사용조건에 관한 사항은 “Page”의 정책에 따라 달라질 수 있습니다. <br/><br/>

                            ③ 적립금은 현금으로 환급될 수 없습니다. <br/><br/>

                            ④ 회원은 적립금을 제3자에게 또는 다른 아이디로 양도할 수 없으며, 유상으로 거래하거나 현금으로 전환할 수 없습니다. <br/><br/>

                            ⑤ “Page”은 회원이 “Page”이 승인하지 않은 방법 또는 허위 정보 제공 등의 부정한 방법으로 적립금을 획득하거나 부정한 목적이나 용도로 적립금을 사용하는 경우 적립금의 사용을 제한하거나 적립금을 사용한 구매신청을 취소하거나 회원 자격을 정지할 수 있습니다. <br/><br/>

                            ⑥ 회원 탈퇴시 미사용한 적립금은 즉시 소멸되며, 탈퇴 후 재가입하더라도 소멸된 적립금은 복구되지 아니합니다. <br/><br/>

                            제13조(할인쿠폰) <br/><br/>

                            ① 할인쿠폰은 회원에게 무상으로 발행되는 것으로 “Page”은 회원이 할인쿠폰을 사이트에서 상품 구매 시 적용할 수 있도록 그 사용대상, 사용방법, 사용기간, 구매가 할인액 등을 정할 수 있습니다. 할인쿠폰의 종류 또는 내용은 “Page”의 정책에 따라 달라질 수 있습니다. <br/><br/>

                            ② “Page”은 할인쿠폰의 사용대상, 사용방법, 사용기간, 할인금액 등을 사이트에 별도로 표시하거나 통지합니다. <br/><br/>

                            ③ 할인쿠폰은 현금으로 환급될 수 없으며, 할인쿠폰의 사용기간이 만료되거나 구매 취소 시 또는 이용계약이 종료되면 소멸됩니다. <br/><br/>

                            ④ “회원은 할인쿠폰을 제3자에게 또는 다른 아이디로 양도할 수 없으며, 유상으로 거래하거나 현금으로 전환할 수 없습니다. <br/><br/>

                            ⑤ “Page”은 회원이 “Page”이 승인하지 않은 방법으로 할인쿠폰을 획득하거나 부정한 목적이나 용도로 할인쿠폰을 사용하는 경우 할인쿠폰의 사용을 제한하거나 할인쿠폰을 사용한 구매신청을 취소하거나 회원 자격을 정지할 수 있습니다. <br/><br/>

                            ⑥ 회원 탈퇴 시 “Page”으로부터 발급받은 할인쿠폰 중 미사용한 할인쿠폰은 즉시 소멸되며, 탈퇴 후 재가입하더라도 소멸된 할인쿠폰은 복구되지 아니합니다. <br/><br/>


                            제14조(수신확인통지․구매신청 변경 및 취소) <br/><br/>

                            ① “Page”은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다. <br/><br/>

                            ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 “Page”은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다. <br/><br/>


                            제15조(재화 등의 공급) <br/><br/>

                            ① “Page”은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, “Page”이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 이때 “Page”은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다. <br/><br/>

                            ② “Page”은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 “Page”이 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 “Page”이 고의․과실이 없음을 입증한 경우에는 그러하지 아니합니다. <br/><br/>


                            제16조(환급) <br/><br/>

                            “Page”은 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다. <br/><br/>

                        </div>
                    </div>
                </div>
            );
        
        
    }
};

export default withRouter(connect(

)(ToSComponent));