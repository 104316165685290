import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Cookies } from 'react-cookie';
import * as Helper from "lib/Helper";

import {} from 'store/Actions';

import {} from 'elements';

const cookies = new Cookies();

export class PointComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: (cookies.get('__token')) ? cookies.get('__token') : '',
            login_yn: (cookies.get('__useyn')) ? cookies.get('__useyn') : 0,
            user_id: '01048446721',
            agree_ck_service : false,
            agree_ck_pri : false,            
            agree_ck : false,
        };
    }


    toggleChange = (e) => {
        if (e.target.name=='agree_ck'){
            document.getElementById("agree_ck_service").checked = e.target.checked;
            document.getElementById("agree_ck_pri").checked = e.target.checked;
        }
    }

    checkAgree = (e) => {

        const charge_url = process.env.REACT_APP_PRODUCTION_COIN_CARGE_URL+"?userCode="+this.state.token;
        if ( document.getElementById("agree_ck_service").checked && document.getElementById("agree_ck_pri").checked){
            window.location.href = charge_url;
        }else{
            Helper.globalAlert({text:'동의 하지 않는 약관이 있습니다.'});
            return false;
        }
    }


    componentDidMount(){


    }

    componentWillReceiveProps(nextProps) {
        // console.debug({ name:'Component WILL RECIEVE PROPS!', nextProps: nextProps})
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.debug({ name:'ShouldComponentUpdate', nextProps: nextProps, nextState:nextState});
        return true;
    }

    componentWillUpdate(nextProps, nextState) {
        // console.debug({ name:'Component WILL UPDATE!' , nextProps: nextProps, nextState:nextState});
    }

    componentDidUpdate(prevProps, prevState) {
        // console.debug({ name:'Component DID UPDATE!' , prevProps: prevProps, prevState:prevState}
    }

    componentDidCatch(error, info) {
        // console.debug({ name:'Component DID Catch!' , error: error, info: info})
        //Handle error.
    }

    componentWillUnmount() {

    }

    render() {

        
        return (
            <div id="wrap">
                <div id="cardPointBlock">
                    <div>
                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/cardPoint_img01.jpg'} alt="잠자고있는 신용카드포인트를 한번에" />
                    </div>	
                    
                    <div class="user_check">
                        <div>
                            <label htmlFor="agree_ck_service">
                                <input id="agree_ck_service" name="agree_ck_service" type="checkbox" onChange = {this.toggleChange}/>
                                서비스 이용약관 동의
                            </label>
                            <a href="/tos">
                                <span>(약관보기)</span>
                            </a>
                        </div>
                        <div>
                            <label htmlFor="agree_ck_pri">
                                <input id="agree_ck_pri" name="agree_ck_pri" type="checkbox"  onChange = {this.toggleChange}/>
                                개인정보 수집 이용약관 동의
                            </label>
                            <a href="/privacypolicy">
                                <span>(약관보기)</span>
                            </a>
                        </div>
                        <div>
                            <label htmlFor="agree_ck">
                                <input id="agree_ck" name="agree_ck" type="checkbox"  onChange = {this.toggleChange}/>
                                전체 동의
                            </label>				
                        </div>
                    </div>

                    <div>
                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/cardPoint_btn.gif'} alt="포인트전환하기" onClick={this.checkAgree}/>
                    </div>
                    
                    <div class="w_txt">
                        <h4>[주의사항]</h4>
                        <ul>
                            <li>전환 신청 후 고객님의 카드포인트는 페이지 포인트로 실시간 전환되며 이후 취소는 불가능 합니다.</li>
                            <li>포인트 전환 시 'PAGE'에 자동 가입되며 비밀번호 설정 후 서비스 이용이 가능합니다.</li>
                            <li>페이지 포인트로 전환된 상세 내역은 내 정보의 포인트 충전 및 사용내역에서 확인 가능합니다.</li>
                            <li>포인트의 사용유효기간은 포인트 전환일로부터 1년이 경과되는 시점까지 이며 그 이후로는 자동 소멸됩니다.</li>
                            <li>포인트와 관련된 자세한 문의는 1:1문의하기에서 가능합니다. <a href="/inquiry" class="q_go">(1:1문의하기 바로가기)</a></li>
                        </ul>
                    </div>
                </div>	
            </div>
        );
    }
};

PointComponent.defaultProps = {
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PointComponent));
