import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from "lib/Helper";
import { getUserMyinfo, tryInquiryCreate, getOneononeList } from 'store/Actions';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export class OneinquiryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: (cookies.get('__token')) ? cookies.get('__token') : '',
            board : 2,
            type : 3,
            uid : '',
            subject : '',
            email : '',
            tel : '',
            content : '',
            agree_ck : false,
            trans: 1,
        }
    }
    
    inquiryAction = () => {
        const {
            board,
            type,
            uid,
            subject,
            email,
            tel,
            content,
        } = this.state;

        const inquirePayload = {
            board: board,
            type: type,
            uid: uid,
            subject:subject,
            email:email,
            tel:tel,
            content:content,
        };

        if(this.CheckPayloadData(inquirePayload)){
            this.props.tryInquiryCreate(JSON.stringify(inquirePayload));
        }
    }

    isEmail = email => {
        const emailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return emailRegex.test(email);
    };

    CheckPayloadData = (regPayload) => {
        if(regPayload.subject === ''){
            Helper.globalAlert({text:'이름을 입력해주십시오.'});
            return false;
        }
        if(regPayload.email === ''){
            Helper.globalAlert({text:'이메일을 입력해주십시오.'});
            return false;
        }

        if(Helper.isEmail(regPayload.email)===false){
            Helper.globalAlert({text:'유효하지 않은 이메일 주소입니다.'});
            return false;
        }
        if(regPayload.tel === ''){
            Helper.globalAlert({text:'전화번호를 입력해주십시오.'});
            return false;
        }
        if(regPayload.content === ''){
            Helper.globalAlert({text:'문의 내용을 입력해주십시오.'});
            return false;
        }
        if(this.state.agree_ck === false){
            Helper.globalAlert({text:'개인정보 수집・이용에 동의해주십시오.'});
            return false;
        }
        return true;
    }

    handleOnchangeItem = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name] : value
        });
    }

    toggleChange = (e) => {
        this.setState({
            agree_ck: !this.state.agree_ck // flip boolean value
        });
    }

    _transFormOrList = (val) => {
        if(val === this.state.trans) {
            return false;
        }
        if(val === 1) {
        }
        this.setState({
            trans: val // flip boolean value
        });
    }

    componentDidMount(){
        // console.debug({ name:'componentDidMount'});
        if(this.state.token === '') {
            window.location.href = '/';
        } else {
            const { token } = this.state;
            let params = {
                token: token
            }
            this.props.getUserMyinfo(params);
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.USER_MYINFO.state === true && this.props.USER_MYINFO.state !== nextProps.USER_MYINFO.state) {
            this.setState({
                subject: nextProps.USER_MYINFO.payload.id,
                uid: nextProps.USER_MYINFO.payload.uid,
                tel: nextProps.USER_MYINFO.payload.id,
            });

            let params = {
                uid: nextProps.USER_MYINFO.payload.uid,
            }

            this.props.getOneononeList(params);
        }

        if(nextProps.INQUIRY_CREATE.status === true && this.props.INQUIRY_CREATE.status !== nextProps.INQUIRY_CREATE.status) {
            Helper.globalAlert({text:'문의가 등록되었습니다. \n 최대한 빠른 답변 드리겠습니다. \n 감사합니다.'});
            this.setState({
                email : '',
                content : '',
                agree_ck : false,
            });
            this.forceUpdate();
        }
    }
    
    render() {       
        return (
            <div id="wrap">
                <div id="container">
                    <div className="inquiry">
                        <div className="custom-btn-group">
                            <div className="btn-group btn-group-xl">
                                <button type="button" className={(this.state.trans == 0) ? 'btn btn-secondary active' : 'btn btn-secondary'}  onClick={() => this._transFormOrList(0)}>문의하기</button>
                                <button type="button" className={(this.state.trans == 1) ? 'btn btn-secondary active' : 'btn btn-secondary'}  onClick={() => this._transFormOrList(1)}>답변보기</button>
                            </div>
                        </div>
                        <div className={(this.state.trans == 0) ? 'inquiry-form-place' : 'inquiry-form-place d-none'}>
                            <h2 className="subTitle1 mb-2">1:1 문의하기</h2>
                            <div className="q_wrap">
                                <div className="q_title">
                                    <h4 className="m-0">이름</h4>
                                </div>
                                <div className="q_info">
                                    <input type="text" name="subject" onChange = {this.handleOnchangeItem} value={this.state.subject} />
                                </div>
                            </div>
                            <div className="q_wrap">
                                <div className="q_title">
                                    <h4 className="m-0">메일 주소</h4>
                                </div>
                                <div className="q_info">
                                    <input type="text" name="email" onChange = {this.handleOnchangeItem} value={this.state.email} />
                                </div>
                            </div>
                            <div className="q_wrap">
                                <div className="q_title">
                                    <h4 className="m-0">전화번호</h4>
                                </div>
                                <div className="q_info">
                                    <input type="text" name="tel" onChange = {this.handleOnchangeItem} value={this.state.tel} placeholder="번호만 정확히 입력해주세요." />
                                </div>
                            </div>

                            <div className="q_wrap">
                                <textarea name="content" onChange = {this.handleOnchangeItem} value={this.state.content} placeholder="정확한 상담을 위해 자세한 문의 내용을 작성해 주세요."></textarea>
                            </div>

                            <div className="q_wrap agree">
                                <h5>[필수] 개인정보 수집 및 이용 동의</h5>
                                <h5><small>
                                    * 항목 : 회사명, 전화번호, 담당자명, 이메일, 문의 및 답변 내용<br/>
                                    * 목적 : 광고문의에 대한 답변 및 상담<br/>
                                    * 보유기간 : 6개월 간 보관 후 파기<br/></small>
                                </h5>
                                <input onChange = {this.toggleChange} id="agree_ck" name="agree_ck" type="checkbox" />
                                <label htmlFor="agree_ck" className="form-check-label form-check-label">개인정보 수집・이용에 동의합니다.</label>

                                <input type="button" value="보내기" onClick={this.inquiryAction} />
                            </div>
                        </div>
                        <div className={(this.state.trans == 1) ? 'inquiry-list-place' : 'inquiry-list-place d-none'}>
                            <h2 className="subTitle1">답변 확인하기</h2>
                            <div id="answer_list">
                                {this.props.ONE_INQUIRT.payload ? this._renderList() : <div></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderList = () => {
        let list = <div></div>,
            data = Object.values(this.props.ONE_INQUIRT.payload);

        if (data && data.length > 0){
            list = data.map((item, i) => {
                let comments = item.comment,
                    comment_list = <tr></tr>;

                if(comments.length > 0) {
                    comment_list = comments.map((comment_item, ci) => {
                        return <tr key={ci}>
                            <td className="small">
                                <div>{comment_item.comment}</div>
                                <div className="comment-answer-info">
                                    <span>{comment_item.writer}</span>
                                    <span> / </span>
                                    <span>{comment_item.created_date}</span>
                                </div>
                            </td>
                        </tr>
                    });
                }
                let status = '접수중';
                if(item.status == 1) {
                    status = '접수중';
                } else if(item.status == 2) {
                    status = '처리중';
                } else if(item.status == 3) {
                    status = '답변완료';
                }
                return <div className="card mb-2" key={i}>
                    <div className="card-header bg-secondary">
                        <a className="d-flex justify-content-between text-white" data-toggle="collapse" aria-expanded="false" href={"#answer_list-"+i}>
                            {item.reg_date} / {status}
                            <div className="collapse-icon"></div>
                        </a>
                    </div>
                    <div id={"answer_list-"+i} className="collapse" data-parent="#answer_list">
                        <div className="card-body">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th className="bg-light">문의내용</th>
                                    </tr>
                                    <tr>
                                        <td className="small">
                                            <div>{item.content}</div>
                                            <div className="content-inquiry-info">
                                                <span>{item.email}</span>
                                                <span> / </span>
                                                <span>{item.reg_date}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className={comments.length > 0 ? '' : 'd-none'}>
                                        <th className="bg-light">답변내용</th>
                                    </tr>
                                    {comment_list}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            })
        }
        return list;
    }
};

const mapStateToProps = state => ({
    USER_MYINFO: state.USER_MYINFO,
    INQUIRY_CREATE: state.INQUIRY_CREATE,
    ONE_INQUIRT: state.ONE_INQUIRT,
});

const mapDispatchToProps = {
    getUserMyinfo,
    tryInquiryCreate,
    getOneononeList,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(OneinquiryComponent));


