import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Header,Footer } from 'components';

export class HomeComponent extends Component {

    render() {
        return (
            <div id="wrap">

<Header/>

                <div id="container">

                    <section className="realTimeKeyword">
                        <h2 className="title">실시간 키워드</h2>
                        <ul className="list">
                            <li><a href="link"><span className="num">1</span>로스트 아크로스트 아크로스트 아크</a></li>
                            <li><a href="link"><span className="num">2</span>로스트 아크</a></li>
                            <li><a href="link"><span className="num">3</span>로스트 아크</a></li>
                            <li><a href="link"><span className="num">4</span>로스트 아크</a></li>
                            <li><a href="link"><span className="num">5</span>로스트 아크</a></li>
                        </ul>
                        <button type="button" className="btn">펼쳐보기</button>
                    </section>

                    <div className="dataChart">
                        <ul>
                            <li>
                                <span className="chart"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample4.png'} alt=""/></span>남은데이터<br /><strong>95.75GB</strong> 남음
                            </li>
                            <li>
                                <span className="chart"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample5.png'} alt=""/></span>영상,부가전화<br /><strong>5시간 0분</strong> 남음
                            </li>
                            <li>
                                <span className="chart"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample6.png'} alt=""/></span>SMS/MMS<br /><strong>무제한</strong>
                            </li>
                        </ul>
                    </div>

                    <div className="linkList">
                        <ul className="txtType">
                            <li><a href="link">정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토</a></li>
                            <li><a href="link">정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토</a></li>
                            <li><a href="link">정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토</a></li>
                            <li><a href="link">정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토</a></li>
                            <li><a href="link">정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토</a></li>
                        </ul>
                        <ul className="thumbType">
                            <li>
                                <a href="link">
                                    <span className="thumb">
                                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample7.jpg'} alt=""/>
                                    </span>
                                    <span className="txt">슈가, 류현진 만났다 슈가, 류현진 만났다</span>
                                </a>
                            </li>
                            <li>
                                <a href="link">
                                    <span className="thumb"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample7.jpg'} alt=""/></span>
                                    <span className="txt">슈가, 류현진 만났다</span>
                                </a>
                            </li>
                            <li>
                                <a href="link">
                                    <span className="thumb"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample7.jpg'} alt=""/></span>
                                    <span className="txt">슈가, 류현진 만났다</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="banner">
                        <a href="link"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/img_ad.png'} alt=""/></a>
                    </div>

                    <p className="weatherWrap">
                        <span className="state">
                            <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/img_weather.png'} alt="구름낌"/>
                        </span>
                        <span className="area">서울</span> 미세먼지 <strong className="dustState">보통</strong>
                        <span className="temp">18.8°</span>
                    </p>

                    <div className="tabMenu">
                        <ul className="menuLeng4">
                            <li className="active"><a href="link">정치</a></li>
                            <li><a href="link">연예</a></li>
                            <li><a href="link">스포츠</a></li>
                            <li><a href="link">야구</a></li>
                        </ul>
                    </div>

                    <div className="linkList">
                        <ul className="txtType">
                            <li><a href="link">정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토</a></li>
                            <li><a href="link">정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토</a></li>
                            <li><a href="link">정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토</a></li>
                            <li><a href="link">정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토</a></li>
                            <li><a href="link">정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토정부, 대북 식량지원 추진 공식화... 방식 규모 등 검토</a></li>
                        </ul>
                    </div>

                    <section className="linkList">
                        <h2 className="subTitle1">게임MD추천 <span>직접해본 게임만 추천!</span></h2>
                        <div className="gridType">
                            <ul>
                                <li>
                                    <a href="link">
                                        <span className="thumb"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/img_slide2.jpg'} alt=""/></span>
                                        <span className="txt">제목제목제목제목제목제목제 목제목제목제목제목제목제목제목제목제목제목제목제목제목</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="link">
                                        <span className="thumb">
                                            <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/img_slide2.jpg'} alt=""/>
                                        </span>
                                        <span className="txt">제목제목제목제목제목제목제 목제목제목제목제목제목제목제목제목제목제목제목제목제목</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="link">
                                        <span className="thumb">
                                            <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/img_slide2.jpg'} alt=""/>
                                        </span>
                                        <span className="txt">제목제목제목제목제목제목제 목제목제목제목제목제목제목제목제목제목제목제목제목제목</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="link">
                                        <span className="thumb">
                                            <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/img_slide2.jpg'} alt=""/>
                                        </span>
                                        <span className="txt">제목제목제목제목제목제목제 목제목제목제목제목제목제목제목제목제목제목제목제목제목</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="listNav">
                            <button type="button" className="btnPrev">이전</button>
                            <span className="pagination">
                                <strong>1</strong> / <span>4</span>
                            </span>
                            <button type="button" className="btnNext">다음</button>
                        </div>
                    </section>

                    <div className="banner">
                        <a href="link"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/img_ad.png'} alt=""/></a>
                    </div>

                    <section className="linkList">
                        <h2 className="subTitle1">TODAY TREND <span>오늘 제일 핫한 트랜드한 쇼핑 아이템</span></h2>
                        <ul className="thumbBigType">
                            <li>
                                <a href="link">
                                    <span className="thumb">
                                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample9.jpg'} alt=""/>
                                    </span>
                                    <span className="txt"><b>05.09 질레트 감사제</b>최대 35% 파격가/ KBO면도기 100% 증정</span>
                                </a>
                            </li>
                            <li>
                                <a href="link">
                                    <span className="thumb">
                                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample10.jpg'} alt=""/>
                                    </span>
                                    <span className="txt">틈새킬러</span>
                                </a>
                            </li>
                            <li>
                                <a href="link">
                                    <span className="thumb">
                                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample10.jpg'} alt=""/>
                                    </span>
                                    <span className="txt">틈새킬러</span>
                                </a>
                            </li>
                        </ul>
                        <ul className="thumbType">
                            <li>
                                <a href="link">
                                    <span className="thumb"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample11.jpg'} alt=""/></span>
                                    <span className="txt">고속도로서 승용차 트럭추돌고속도로서 승용차 트럭추돌</span>
                                </a>
                            </li>
                            <li>
                                <a href="link">
                                    <span className="thumb"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample11.jpg'} alt=""/></span>
                                    <span className="txt">고속도로서 승용차 트럭추돌</span>
                                </a>
                            </li>
                            <li>
                                <a href="link">
                                    <span className="thumb"><img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/sample11.jpg'} alt=""/></span>
                                    <span className="txt">고속도로서 승용차 트럭추돌</span>
                                </a>
                            </li>
                        </ul>
                    </section>

                </div>

                <Footer/>

            </div>
        );
    }
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeComponent));