/* eslint-disable no-undef */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from 'lib/Helper';

import {
    getNewsMainTopBulletin,
    getNewsSubTopPhotobox,
    getNewsSubFootCategoryPhotobox,
    getNewsSubNewsProvider
} from 'store/Actions';

import {
    NewsMainBulletin,
    NewsSubNewsListPhotoBox,
    // NewsSubProviderBox,
    SyrupTableBanner,
    MobionBanner,
    NewsSubProviderBoxHardCoding,
    InterWorksMediaBanner
 } from 'elements';

export class NewsSubComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news_category : (this.props.match.params.news_cateory) ? this.props.match.params.news_cateory : 1001,
            sub_box_category : 1001
        };
    }

    _getTopBulletin = () => {
        this.props.getNewsMainTopBulletin();
    }

    _getNewsSubTopPhotobox = (category) => {
        this.props.getNewsSubTopPhotobox(category);
    }

    _getNewsSubFootCategoryPhotobox = (category) => {
        this.props.getNewsSubFootCategoryPhotobox(category);
    }



    _getNewsSubNewsProvider = () => {
        this.props.getNewsSubNewsProvider();
    }


    _getSubNewsBannerOne = () => {

        var obj = $("#subnewsBanner01"),
        obj_width = obj.width(),
        obj_height = obj_width * 0.3125;
        obj.css({'width':'100%', 'height':obj_height});

        var subnewsBanner01 = new ASUMSDK();
    

        
        var subnewsBanner01conf = {
            targetId : 'subnewsBanner01',
            adUnitId : '505b47b1-7567-4c15-a5a9-73efe378158e',
            callback : {
                onAdLoaded : function(isDefaultAd){
                //    if(isDefaultAd==true){
                //         $("#subnewsBanner01").css('height','auto');
                //         $("#subnewsBanner01").html('<a href="https://stockletter.kr/event/register01?utm_source=asum&utm_medium=banner&utm_campaign=noad" target="_blank"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
                //     }
                },
                onAdFailed : function(error){
                    $("#subnewsBanner01").css('height','auto');
                   // $("#subnewsBanner01").html('<a href="https://stockletter.kr/event/register04" target="_blank"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
                },
    
                onAdClicked : function(url){
                        return false;
                },
            }
    
        };

        subnewsBanner01.loadAd(subnewsBanner01conf);
        
    }

    _getSubNewsBannerTwo = () => {

        var obj = $("#subnewsBanner02"),
        obj_width = obj.width(),
        obj_height = obj_width * 0.3125;
        obj.css({'width':'100%', 'height':obj_height});

        var subnewsBanner02 = new ASUMSDK();
    
            
        
        var subnewsBanner02conf = {
            targetId : 'subnewsBanner02',
            adUnitId : '505b47b1-7567-4c15-a5a9-73efe378158e',
            callback : {
                onAdLoaded : function(isDefaultAd){
                    $("#subnewsBanner02").html('');
                },
                onAdFailed : function(error){
                    $("#subnewsBanner02").css('height','auto');
                },
    
                onAdClicked : function(url){
                        return false;
                },
            }
    
        };

        subnewsBanner02.loadAd(subnewsBanner02conf);
        
    }
    _getSubNewsBannerThree = () => {

        var obj = $("#subnewsBanner03"),
        obj_width = obj.width(),
        obj_height = obj_width * 0.3125;
        obj.css({'width':'100%', 'height':obj_height});

        var subnewsBanner03 = new ASUMSDK();
    
            
        
        var subnewsBanner03conf = {
            targetId : 'subnewsBanner03',
            adUnitId : '505b47b1-7567-4c15-a5a9-73efe378158e',
            callback : {
                onAdLoaded : function(isDefaultAd){
                    $("#subnewsBanner03").html('');
                },
                onAdFailed : function(error){
                    $("#subnewsBanner03").css('height','auto');
                },
    
                onAdClicked : function(url){
                        return false;
                },
            }
    
        };

        subnewsBanner03.loadAd(subnewsBanner03conf);
        
    }



    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.news_cateory !== nextProps.match.params.news_cateory) {
            this.setState({
                news_category:nextProps.match.params.news_cateory
            });
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextState.news_category !== this.state.news_category) {
            this._getNewsSubTopPhotobox(Helper.getCodeToNewsCategoryName(nextState.news_category));
        }

        if(nextState.sub_box_category !== this.state.sub_box_category) {
            this._getNewsSubFootCategoryPhotobox(Helper.getCodeToNewsCategoryName(nextState.sub_box_category));
        }
        return true;
    }

    componentDidUpdate() {
    }

    componentDidMount() {
        this._getTopBulletin();

        this._getNewsSubTopPhotobox(Helper.getCodeToNewsCategoryName(this.state.news_category));
        this._getNewsSubFootCategoryPhotobox(Helper.getCodeToNewsCategoryName(this.state.news_category));

        // this._getNewsSubNewsProvider();

        //banner
        this._getSubNewsBannerOne();
        //this._getSubNewsBannerTwo();
        //this._getSubNewsBannerThree();
    }

    _handleNewsFootCategoryClick = (category) => {

        if(this.state.sub_box_category !== category) {
            this.setState({
                sub_box_category: category
            });
        }
    }



    render() {
        return (
            <div id="wrap">

                <div id="container">

                    <div id="subnewsBanner01" style={{position:'relative'}}>
                            <MobionBanner
                                BANNER_TYPE="NEWS_TOP"
                            />
                    </div>

                    <NewsMainBulletin
                            CONTENTS_LIST={this.props.topBulletin}
                        />

                    <section className="linkList">
                        <div className="newsType2">
                            <ul>
                                <NewsSubNewsListPhotoBox
                                    CONTENTS_LIST={this.props.topPhotoBox}
                                    />
                            </ul>
                        </div>
                    </section>

                    <div className="banner">
                        <MobionBanner
                            BANNER_TYPE="NEWS_MIDDLE"
                        />
                    </div>

                    <div className="tabMenu">
                        <ul className="menuLeng3">
                            <li><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleNewsFootCategoryClick('1002')}  }>정치</Link></li>
                            <li><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleNewsFootCategoryClick('1001')}  }>경제</Link></li>
                            <li><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleNewsFootCategoryClick('1003')}  }>사회</Link></li>
                            <li><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleNewsFootCategoryClick('1009')}  }>생활</Link></li>
                            <li><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleNewsFootCategoryClick('1010')}  }>세계</Link></li>
                            <li><Link to="/" onClick={ (e) => {e.preventDefault(); this._handleNewsFootCategoryClick('1006')}  }>IT</Link></li>
                        </ul>
                    </div>

                    <section className="linkList">
                        <div className="newsType2">
                            <ul>
                            <NewsSubNewsListPhotoBox
                                    CONTENTS_LIST={this.props.footPhotoBox}
                                    />
                            </ul>
                        </div>
                    </section>

                    <div className="banner">
                        <MobionBanner
                            BANNER_TYPE="NEWS_BOTTOM"
                        />
                    </div>

                    <section className="section">
                        <h2 className="subTitle1">관심있는 언론사</h2>
                        <ul className="pressList">
                            <NewsSubProviderBoxHardCoding
                                // CONTENTS_LIST={this.props.footNewsProvider}
                            />
                        </ul>
                    </section>

                    <div className="banner">
                        <MobionBanner
                            BANNER_TYPE="NEWS_BOTTOM"
                        />
                    </div>
                </div>

            </div>
        );
    }
};

NewsSubComponent.defaultProps = {
    topBulletin: {
        state: false,
        payload : {}
    },
    topPhotoBox: {
        state: false,
        payload : {}
    },
    footPhotoBox: {
        state: false,
        payload : {}
    },
    footNewsProvider: {
        state: false,
        payload : {}
    },
};

const mapStateToProps = state => ({
    topBulletin : state.NEWS_MAIN.topBulletin,
    topPhotoBox : state.NEWS_SUB.topPhotoBox,
    footPhotoBox : state.NEWS_SUB.footPhotoBox,
    footNewsProvider : state.NEWS_SUB.footNewsProvider,
});

const mapDispatchToProps = {
    getNewsMainTopBulletin,
    getNewsSubTopPhotobox,
    getNewsSubFootCategoryPhotobox,
    getNewsSubNewsProvider,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsSubComponent));
