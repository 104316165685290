import React from "react";
import { Link } from "react-router-dom";
import * as Helper from 'lib/Helper';
import * as GlobalLink from 'lib/GlobalLink'



export const DefaultBannerBox = ({
    CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {
        const item = Helper.shuffle(CONTENTS_LIST.payload);

        return (
            <div>
                <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item[0].land_url))}  }>
                    <img src={item[0].img_path} alt=""/>
                </Link>
            </div>
        );
    } else {
        return (
            <div>
            </div>
        );
    }
};

export const GameBannerBox = ({
    CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }


    if(CONTENTS_LIST.state === true) {
        const item = Helper.shuffle(CONTENTS_LIST.payload);

        return (
            <div className="banner">
                <div>
                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item[0].land_url))}  }>
                        <img src={item[0].img_path} alt=""/>
                    </Link>
                </div>
            </div>
        );
    } else {
        return (
            <div className="banner">

            </div>
        );
    }
};

export const GameFootBannerBox = ({
    CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }


    if(CONTENTS_LIST.state === true) {
        const item = Helper.shuffle(CONTENTS_LIST.payload);

        return (
            <li className="adWrap">
                <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item[0].land_url))}  }>
                    <strong className="tit">{item[0].title}</strong>
                    <span className="ad">AD</span>
                    <span className="img"><img src={item[0].img_path} alt=""/></span>
                    <span className="bot"><span className="txt">{item[0].summary}</span><span className="btn">게임하기</span></span>
                </Link>
            </li>
        );
    } else {
        return (
            <li className="adWrap">

            </li>
        );
    }
};

export const SyrupTableBanner = () => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    const arrayImageURL = [
        process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/banner/2019060701.jpg',
        process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/banner/2019060702.jpg',
        process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/banner/2019060703.jpg',
        process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/banner/2019060704.jpg',
    ]

    const bannerImage = Helper.shuffle(arrayImageURL);

    return (
        <div>
            <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent('https://play.google.com/store/apps/details?id=com.skt.tmaphot'))}  }>
                <img src={bannerImage[0]} alt=""/>
            </Link>
        </div>
    );
};

export const InterWorksMediaBanner = () => {
    return (
            <div className="iwm_aip" data-ad="{'id':'4B50B179' , 'selector':''}" data-ad-center style={{'display':'inline','width':'100%','height':'100px'}}></div>
    );

};

export const InterWorksMediaBannerGame = () => {
    return (
        <div className="banner">
            <div className="iwm_aip" data-ad="{'id':'728DE1F3' , 'selector':''}" data-ad-center style={{'display':'inline-block','width':'100%'}}></div>
            </div>
      );
};

export const MediaCategoryBannerTop = () => {
    return (
        <iframe name="ifrad" width="100%" height="480" id="ifrad" src="//www.mediacategory.com/servlet/adbnMobileBanner?from=&s=67596&bntype=52&cntad=3&cntsr=12&iwh=320_480" 
                frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no" >
        </iframe>
      );
};

export const MediaCategoryBannerBottom = () => {
    return (
        <iframe name="ifrad" width="100%" height="480" id="ifrad" src="//www.mediacategory.com/servlet/adbnMobileBanner?from=&s=67597&bntype=52&cntad=3&cntsr=12&iwh=320_480" 
                frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no" >            
        </iframe>
      );
};

