import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import querySearch from "stringquery";
import GoogleSearchIframe from './GoogleSearch/GoogleSearchIframe';
import * as Helper from 'lib/Helper';
//import FeedKeyWordListCloseType from "./KeywordList/FeedKeyWordListCloseType";
//import FeedKeyWordListOpenType from "./KeywordList/FeedKeyWordListOpenType";

import {
    getFeedTopKeyWordList,
} from 'store/Actions';


export class SearchComponent extends Component {

    constructor() {
        super();
        this.state = {
            iFrameHeight: '0px',
            googleSearchString: '',
            top_keyword_view_open_type : false,
        }
    }

    componentWillReceiveProps(nextProps) {
        if(Helper.isEmpty(nextProps.location.search) === false) {
            const searchQueryString = (Helper.isEmpty(querySearch(nextProps.location.search).q) === false) ? querySearch(nextProps.location.search).q : 'SKT';
            this.setState({
                googleSearchString : searchQueryString
            });
        }
    }

    componentWillMount() {
       if(Helper.isEmpty(this.props.location.state) === false && Helper.isEmpty(this.props.location.state.q) === false) {
            const searchQueryString = (Helper.isEmpty(this.props.location.state.q) === false) ? this.props.location.state.q : 'SKT';
            this.setState({
                googleSearchString : searchQueryString
            });
        }
    }

    _getFeedTopKeyWordList = () => {
        this.props.getFeedTopKeyWordList();
    }

    startPage  = () => {
        this._getFeedTopKeyWordList();
    }

    _handleKeywordOpenCloseButton = () => {
        if(this.state.top_keyword_view_open_type === false ) {
            this.setState({
                top_keyword_view_open_type: true
            });
        } else {
            this.setState({
                top_keyword_view_open_type: false
            });
        }
    }

    componentDidMount() {
        this.startPage();
    }

    render() {
        return (

            <div id="wrap">

                <div id="container">

                    {
                        //<FeedKeyWordListCloseType BUTTON_OPEN_CLICK={this._handleKeywordOpenCloseButton} />
                    }

                    {
                        //this.state.top_keyword_view_open_type && <FeedKeyWordListOpenType BUTTON_OPEN_CLICK={this._handleKeywordOpenCloseButton} />
                    }

                    <section className="linkList">
                    </section>

                    <div className="searchResult">

                        <GoogleSearchIframe
                            searchString={(this.state.googleSearchString === '') ? 'SKT' : this.state.googleSearchString}
                        />

                    </div>

                </div>

            </div>

        );
    }
};

const mapStateToProps = state => ({
    feedTopKeyWordList : state.FEED.feedTopKeyWordList,
});

const mapDispatchToProps = {
    getFeedTopKeyWordList
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchComponent));