/* eslint-disable no-undef */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from 'lib/Helper';

import { getCarSection1, getCarSection2, getCarSection3, getCarSection4, getCarSection5, getCarBanner } from 'store/Actions';

import {
    CarSection1Box,
    CarSection2Box,
    CarSection3Box,
    CarSection4Box,
    CarSection5Box,
    DefaultBannerBox,
    CarSection1BennerBox,
    CarSection4BennerBox,
    MobionBanner,
} from 'elements';

export class CarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            section2_page: 1,
            section2_total_page: 0,
            section3_page: 1,
            section3_total_page: 0,
            carSection5Type: 'review',
        };
    }

    _getCarSection1 = () => {
        let params ={
            params: {
                category: 'SECTION1',
                limit: 20,
            }
        };
        this.props.getCarSection1(params);
    }

    _getCarSection2 = () => {
        let params ={
            params: {
                category: 'SECTION2',
                limit: 20,
            }
        };
        this.props.getCarSection2(params);
    }

    _getCarSection3 = () => {
        let params ={
            params: {
                category: 'SECTION3',
                limit: 20,
            }
        };
        this.props.getCarSection3(params);
    }

    _getCarSection4 = () => {
        let params ={
            params: {
                category: 'SECTION4',
                limit: 20,
            }
        };
        this.props.getCarSection4(params);
    }

    _getCarSection5 = () => {
        let params ={
            params: {
                category: 'SECTION5',
                limit: 20,
            }
        };
        this.props.getCarSection5(params);
    }

    _getCarBenner = () => {
        let params ={
            params: {
                category: 'BANNERS',
                limit: 20,
            }
        };
        this.props.getCarBanner(params);
    }

    _section5Action = (type) => {
        this.setState({
            carSection5Type: type
        })
    }

    _handleClickSection2Pageing = (e) => {
        this.pageingAction2(e);
    }

    _handleClickSection3Pageing = (e) => {
        this.pageingAction3(e);
    }

    pageingAction2= (e) => {
        let nowPage = this.state.section2_page;
        let pageValue;
        const pageType = e.target.getAttribute('page_type');

        if(pageType === '-') {

            if(nowPage-1 === 0) {
                pageValue = this.state.section2_total_page;
            } else {
                pageValue = nowPage-1;
            }
            this.setState({
                section2_page: pageValue
            });

        } else if(pageType === '+') {

            if(nowPage+1 > this.state.section2_total_page) {
                pageValue = 1;
            } else {
                pageValue = nowPage+1;
            }
            this.setState({
                section2_page: pageValue
            });
        }
    }
    pageingAction3= (e) => {
        let nowPage = this.state.section3_page;
        let pageValue;
        const pageType = e.target.getAttribute('page_type');

        if(pageType === '-') {

            if(nowPage-1 === 0) {
                pageValue = this.state.section3_total_page;
            } else {
                pageValue = nowPage-1;
            }
            this.setState({
                section3_page: pageValue
            });

        } else if(pageType === '+') {

            if(nowPage+1 > this.state.section3_total_page) {
                pageValue = 1;
            } else {
                pageValue = nowPage+1;
            }
            this.setState({
                section3_page: pageValue
            });
        }
    }

    _getCarBannerOne = () => {

        var obj = $("#carBanner01"),
        obj_width = obj.width(),
        obj_height = obj_width * 0.3125;
        obj.css({'width':'100%', 'height':obj_height});

        var carBanner01 = new ASUMSDK();
        
        var carBanner01conf = {
            targetId : 'carBanner01',
            adUnitId : '8e785f07-2fbd-479a-a388-d19a88ce3737',
            callback : {
                onAdLoaded : function(isDefaultAd){
                    //   if(isDefaultAd==true){
                    //     $("#subnewsBanner01").css('height','auto');
                    //     $("#subnewsBanner01").html('<a href="https://stockletter.kr/event/register01?utm_source=asum&utm_medium=banner&utm_campaign=noad" target="_blank"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
                    // }
                },
                onAdFailed : function(error){
                    console.log(error);
                    $("#carBanner01").css('height','auto');
                    //$("#carBanner01").html('<a href="https://stockletter.kr/event/register04" target="_blank"><img src="/assets/img/letter-w640h200.png" alt="주식레터"/></a>');
                },
    
                onAdClicked : function(url){
                        return false;
                },
            }
    
        };
        carBanner01.loadAd(carBanner01conf);
        
    }

    componentDidMount(){
        // console.debug({ name:'componentDidMount'});
        this._getCarSection1();
        this._getCarSection2();
        this._getCarSection3();
        this._getCarSection4();
        this._getCarSection5();
        this._getCarBenner();

        //banner
        this._getCarBannerOne();
    }

    componentWillReceiveProps(nextProps) {
        // console.debug({ name:'Component WILL RECIEVE PROPS!', nextProps: nextProps})

        try{

            if(nextProps.carSection2.state === true && this.props.carSection2.payload !== nextProps.carSection2.payload) {
                const mdPayloadCount = nextProps.carSection2.payload.length;

                let totalPage = Helper.getTotalPage((mdPayloadCount - 1), 3);

                this.setState({
                    section2_total_page : totalPage
                });
            }
            if(nextProps.carSection3.state === true && this.props.carSection3.payload !== nextProps.carSection3.payload) {
                const mdPayloadCount = nextProps.carSection3.payload.length;

                let totalPage = Helper.getTotalPage((mdPayloadCount - 1), 4);

                this.setState({
                    section3_total_page : totalPage
                });
            }

        }catch(e){

        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.debug({ name:'ShouldComponentUpdate', nextProps: nextProps, nextState:nextState});
        return true;
    }

    componentWillUpdate(nextProps, nextState) {
        // console.debug({ name:'Component WILL UPDATE!' , nextProps: nextProps, nextState:nextState});
    }

    componentDidUpdate(prevProps, prevState) {
        // console.debug({ name:'Component DID UPDATE!' , prevProps: prevProps, prevState:prevState}
    }

    componentDidCatch(error, info) {
        // console.debug({ name:'Component DID Catch!' , error: error, info: info})
        //Handle error.
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        return (
            <div id="wrap">
                <div id="container" className="carIt">
                    <div id="carBanner01" style={{position:'relative'}}>
                        {/* <CarSection4BennerBox
                            CONTENTS_LIST={this.props.carBenner}
                        /> */}

                            <MobionBanner
                                BANNER_TYPE="NEWS_TOP"
                            />
                    </div>
                    <section className="linkList">
                        <div>
                            <CarSection2Box
                                CONTENTS_LIST={this.props.carSection2}
                                CONTENTS_PAGE={this.state.section2_page}
                            />
                            <div className="listNav">
                                <button type="button" className="btnPrev" onClick={this._handleClickSection2Pageing}
                                        page_type="-">이전
                                </button>
                                <span
                                    className="pagination"><strong>{this.state.section2_page}</strong> /<span>{this.state.section2_total_page}</span></span>
                                <button type="button" className="btnNext" onClick={this._handleClickSection2Pageing}
                                        page_type="+">다음
                                </button>
                            </div>
                        </div>
                    </section>

                
                    <CarSection4BennerBox
                                CONTENTS_LIST={this.props.carSection4}
                            /> 

                    <section className="linkList">
                        <h2 className="subTitle1">자동차 소식</h2>
                        <div className="gridType">
                            <ul>
                                <CarSection3Box
                                    CONTENTS_LIST={this.props.carSection3}
                                    CONTENTS_PAGE={this.state.section3_page}
                                />
                            </ul>
                        </div>
                        <div className="listNav">
                            <button type="button" className="btnPrev" onClick={this._handleClickSection3Pageing}
                                    page_type="-">이전
                            </button>
                            <span
                                className="pagination"><strong>{this.state.section3_page}</strong> /<span>{this.state.section3_total_page}</span></span>
                            <button type="button" className="btnNext" onClick={this._handleClickSection3Pageing}
                                    page_type="+">다음
                            </button>
                        </div>
                    </section>
                    <CarSection4BennerBox
                                CONTENTS_LIST={this.props.carSection4}
                            /> 

                    <div className="radiobMenu">
                        <section className="linkList">
                            <h2 className="subTitle1">많이 본 뉴스</h2>
                        </section>
                        <ul className="menuLeng2">
                            <li className={(this.state.carSection5Type === 'review') ? 'active' : ''}><a onClick={() => this._section5Action('review')}>시승기</a></li>
                            <li className={(this.state.carSection5Type === 'motorShow') ? 'active' : ''}><a onClick={() => this._section5Action('motorShow')}>모터쇼</a></li>
                        </ul>
                    </div>
                    <div className="linkList" style={{padding:"0 1.5rem 0.5rem"}}>
                        <CarSection5Box
                            CONTENTS_LIST={this.props.carSection5}
                            CONTENTS_TYPE={this.state.carSection5Type}
                        />
                    </div>

                </div>
            </div>
        );
    }
};

CarComponent.defaultProps = {
    carSection1: {
        state: false,
        payload : {}
    },
    carSection2: {
        state: false,
        payload : {}
    },
    carSection3: {
        state: false,
        payload : {}
    },
    carSection4: {
        state: false,
        payload : {}
    },
    carSection5: {
        state: false,
        payload : {}
    },
    carBenner: {
        state: false,
        payload : {}
    },
};

const mapStateToProps = state => ({
    carSection1: state.CAR.carSection1,
    carSection2: state.CAR.carSection2,
    carSection3: state.CAR.carSection3,
    carSection4: state.CAR.carSection4,
    carSection5: state.CAR.carSection5,
    carBenner: state.CAR.carBenner,
});

const mapDispatchToProps = {
    getCarSection1,
    getCarSection2,
    getCarSection3,
    getCarSection4,
    getCarSection5,
    getCarBanner,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CarComponent));
