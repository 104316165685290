import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from "lib/Helper";
import { tryInquiryCreate } from 'store/Actions';
// import * as Helper from 'lib/Helper';


export class InquiryComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            board : 1,
            type : '',
            subject : '',
            email : '',
            tel : '',
            content : '',
            agree_ck : false,
        }
    }
    
    inquiryAction = () => {
        const {
            board,
            type,
            subject,
            email,
            tel,
            content,
        } = this.state;

        const inquirePayload = {
            board: board,
            type: type,
            subject:subject,
            email:email,
            tel:tel,
            content:content,
        };

        if(this.CheckPayloadData(inquirePayload)){
            this.props.tryInquiryCreate(JSON.stringify(inquirePayload));
        }
        
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.INQUIRY_CREATE.status === true && this.props.INQUIRY_CREATE.status !== nextProps.INQUIRY_CREATE.status) {
            Helper.globalAlert({text:'문의가 등록되었습니다. \n 최대한 빠른 답변 드리겠습니다. \n 감사합니다.'});
            this.setState({
                email : '',
                content : '',
                agree_ck : false,
            });
            this.forceUpdate();
        }
    }

    isEmail = email => {
        const emailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return emailRegex.test(email);
    };

    CheckPayloadData = (regPayload) => {
        if(regPayload.type === ''){
            Helper.globalAlert({text:'구분 선택해주십시오.'});
            return false;
        }
        if(regPayload.subject === ''){
            Helper.globalAlert({text:'회사명 및 담당자명을 입력해주십시오.'});
            return false;
        }
        if(regPayload.email === ''){
            Helper.globalAlert({text:'이메일을 입력해주십시오.'});
            return false;
        }

        if(Helper.isEmail(regPayload.email)===false){
            Helper.globalAlert({text:'유효하지 않은 이메일 주소입니다.'});
            return false;
        }
        if(regPayload.tel === ''){
            Helper.globalAlert({text:'전화번호를 입력해주십시오.'});
            return false;
        }
        if(regPayload.content === ''){
            Helper.globalAlert({text:'문의 내용을 입력해주십시오.'});
            return false;
        }
        if(this.state.agree_ck === false){
            Helper.globalAlert({text:'개인정보 수집・이용에 동의해주십시오.'});
            return false;
        }
        return true;
    }

    handleOnchangeItem = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name] : value
        });
    }

    toggleChange = (e) => {
        this.setState({
            agree_ck: !this.state.agree_ck // flip boolean value
        });
    }
    
    render() {       
            return (
                <div id="wrap">
                    <div id="container">
                        <div className="inquiry">
                            <h2 className="subTitle1">광고 ・제휴 문의하기</h2>
                            <div className="q_wrap">
                                <div className="q_title">
                                    <h4 className="m-0">구분</h4>
                                </div>
                                <div className="q_info">
                                    <div>
                                        <input id="ad" onChange={this.handleOnchangeItem} name="type" type="radio" value="ad"/>
                                        <label htmlFor="ad" className="form-check-label">광고</label>
                                    </div>
                                    <div>
                                        <input id="alliance" onChange={this.handleOnchangeItem} name="type" type="radio" value="alliance"/>
                                        <label htmlFor="alliance" className="form-check-label">제휴</label>
                                    </div>
                                </div>
                            </div>
                            <div className="q_wrap">
                                <div className="q_title">
                                    <h4 className="m-0" style={{lineHeight:'1.7rem'}}>회사명<br />담당자명</h4>
                                </div>
                                <div className="q_info">
                                    <input type="text" name="subject" onChange = {this.handleOnchangeItem} value={this.state.subject} />
                                </div>
                            </div>
                            <div className="q_wrap">
                                <div className="q_title">
                                    <h4 className="m-0">메일 주소</h4>
                                </div>
                                <div className="q_info">
                                    <input type="text" name="email" onChange = {this.handleOnchangeItem} value={this.state.email} />
                                </div>
                            </div>
                            <div className="q_wrap">
                                <div className="q_title">
                                    <h4 className="m-0">전화번호</h4>
                                </div>
                                <div className="q_info">
                                    <input type="text" name="tel" onChange = {this.handleOnchangeItem} value={this.state.tel} placeholder="- 포함하여 전화번호를 입력해주세요." />
                                </div>
                            </div>

                            <div className="q_wrap">
                                <textarea name="content" onChange = {this.handleOnchangeItem} value={this.state.content} placeholder="정확한 상담을 위해 자세한 문의 내용을 작성해 주세요."></textarea>
                            </div>

                            <div className="q_wrap agree">
                                <h5>[필수] 개인정보 수집 및 이용 동의</h5>
                                <h5><small>
                                    * 항목 : 회사명, 전화번호, 담당자명, 이메일, 문의 및 답변 내용<br/>
                                    * 목적 : 광고문의에 대한 답변 및 상담<br/>
                                    * 보유기간 : 6개월 간 보관 후 파기<br/></small>
                                </h5>
                                
                                <input onChange = {this.toggleChange} id="agree_ck" name="agree_ck" type="checkbox" />
                                <label htmlFor="agree_ck" className="form-check-label form-check-label">개인정보 수집・이용에 동의합니다.</label>

                                <input type="button" value="보내기" onClick={this.inquiryAction} />
                            </div>
                        </div>

                    </div>
                </div>
            );
        
        
    }
};




const mapStateToProps = state => ({
    INQUIRY_CREATE: state.INQUIRY_CREATE,
});

const mapDispatchToProps = {
    tryInquiryCreate
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(InquiryComponent));


