import React from "react";
import { Link } from "react-router-dom";
// import { GlobalLoading } from 'elements';

// export const MapSectionList = ({
//     SECTION_CLICK,
//     SECTION_LIST
// }) => {
//
//     const nameStyle = {
//         lineHeight: '2',
//         borderTop: '.1rem solid #ededed',
//         whiteSpace: 'nowrap',
//         textOverflow: 'ellipsis',
//         overflow: 'hidden'
//     };
//
//     const _handleContentLinkClick = (latitude,longitude,name) => {
//         SECTION_CLICK({
//             latitude:latitude,
//             longitude:longitude,
//             name:name,
//         });
//     }
//
//     // console.debug(SECTION_LIST);
//     if(Array.isArray(SECTION_LIST) === false){
//         return (
//             <GlobalLoading />
//         );
//     } else {
//         return (
//             <div>
//                 {
//                     Array.isArray(SECTION_LIST) === true && (
//
//                             SECTION_LIST.map((item, i) => {
//                             return (
//                                 <li key={i}>
//                                     <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(item.latitude, item.longitude, item.name)}  }>
//                                         <img src={item.icon} alt="" />
//                                         <strong className="name" style={nameStyle}>{item.name}</strong>
//                                         <span className="address" style={nameStyle}>{item.vicinity}</span>
//                                         <span className="range">{item.distance}</span>
//                                     </Link>
//                                 </li>
//                             )
//                         })
//                     )
//                 }
//             </div>
//         );
//     }
//
// };


export const MapSectionList = ({
   SECTION_CLICK,
   SECTION_LIST
}) => {




    const _handleContentLinkClick = (latitude,longitude,name) => {
        SECTION_CLICK({
            latitude:latitude,
            longitude:longitude,
            name:name,
        });
    }

    const renderItem = [];

    Array.isArray(SECTION_LIST) === true && (

        SECTION_LIST.map((item, i) => (
            renderItem.push (
                <li key={i}>
                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(item.latitude, item.longitude, item.name)}  }>
                        <img src={item.icon} alt="" />
                        <strong className="name">{item.name}</strong>
                        <span className="address">{item.vicinity}</span>
                        <span className="range">{item.distance}</span>
                    </Link>
                </li>
            )
        ))
    )

    return (
        renderItem
    );

};
