
export const globalAlert = (d) => {
    const swal = require('sweetalert');
    swal(d.text);
};

export const globalSuccessAlert1 = (d) => {
    const swal = require('sweetalert');
    swal({
        title: "성공",
        text: d.text,
        icon: "success",
        button: "확인",
    });
};

export const globalSuccessAlert2 = (d) => {
    const swal = require('sweetalert');
    swal({
        title: d.title,
        text: d.text,
        icon: "success",
        button: "확인",
    });
};

export const globalaWarningAlert = (d) => {
    const swal = require('sweetalert');
    swal({
        title: "경고",
        text: d.text,
        icon: "warning",
        button: "확인",
    });
};

export const globalErroraAlert = (d) => {
    const swal = require('sweetalert');
    swal({
        title: "에러",
        text: d.text,
        icon: "error",
        button: "확인",
    });
};

export const globalinfoAlert = (d) => {
    const swal = require('sweetalert');
    swal({
        title: "정보",
        text: d.text,
        icon: "info",
        button: "확인",
    });
};


export const isEmpty = function(value) {
    if( value === "" || value === null || value === undefined || ( value !== null && typeof value === "object" && !Object.keys(value).length ) ) {
        return true
    }else{
        return false
    }
}



/*
기본 debug log
*/

export const DEBUG = (e) => {
    console.debug('%c::DEBUG::', 'color: green; font-weight: bold;',e);
};


export const storageManager = {
    set: (key, object) => {
        if(!localStorage) return;
        localStorage[key] = (typeof object) === 'string' ? object : JSON.stringify(object);
    },
    get: (key) => {
        if(!localStorage) return null;

        if(!localStorage[key]) {
            return null;
        }

        try {
            const parsed = JSON.parse(localStorage[key]);
            return parsed;
        } catch(e) {
            return localStorage[key];
        }
    },
    remove: (key) => {
        if(!localStorage) return null;

        if(localStorage[key]) {
            localStorage.removeItem(key);
        }
    }
};


export const numberFormat = (inputNumber) => {
   return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// 0으로 채울때...
export const numberPad = (n, width) => {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}

// http:// 붙이기
export function httpHtml(content) {
    const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;

    return content.replace(reg, "<a href='$1$2'>$1$2</a>");
}

/**
 *
 * 위도 경거 거리.
 *
 * @param {*} lat1
 * @param {*} lng1
 * @param {*} lat2
 * @param {*} lng2
 */
export function getDistanceFromLatLonInKm(lat1,lng1,lat2,lng2) {
    function deg2rad(deg) {
        return deg * (Math.PI/180)
    }

    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lng2-lng1);
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon/2) * Math.sin(dLon/2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c; // Distance in km
    return d;
}

/**
 *
 * @param {*} array
 * @param {*} page_size
 * @param {*} page_number
 *
 * 배열 페이징 처리
 */
export function paginate(array, page_size, page_number) {
    --page_number; // because pages logically start with 1, but technically with 0
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
}

/**
 *
 * @param {*} totalCount
 * @param {*} listCount
 *
 * 전체 페이지수 리턴
 */
export function getTotalPage(totalCount=0, listCount=0) {
    let totalPage = totalCount / listCount;
// console.debug(totalPage);
    if (totalCount % listCount > 0) {
        totalPage++;
    }

    return Math.floor(totalPage);
}

/**
 *
 * @param {*} str
 *
 * html 디코딩 엔티티
 */
export function decodeHTMLEntities (str) {
    if(str && typeof str === 'string') {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
    }

    return str;
}

/**
 *
 * @param {*} str
 * @param {*} org
 * @param {*} dest
 *
 * 임시 \\\' 처리
 */
export function _StrReplaceAll(str, org, dest) {
    return str.split(org).join(dest);
}


/**
 *
 * @param {*} category
 *
 * 기사 코드 ---> string code
 */
export function getCodeToNewsCategoryName(category) {
// console.debug(category);
    let retuenStr = 'main';
// main - 메인(전체) -
// economic -  경제 - 1001
// politics - 정치 - 1002
// society - 사회 - 1003
// entertain - 연예 - 1004
// sports - 스포츠 - 1005
// digital - 아이티 - 1006

// baseball - 야구 - 1007
// soccer - 축구 - 1008
// life - 생활 - 1009
// world - 세계(국제) - 1010

// headline 헤드라인 - 1011
// culture 문화 - 1012
// movie 영상 - 1013
// photo 사진 - 1014
// industry 산업 - 1015
// game 게임 - 1016
// editorial 사설 - 1017
// news_quick 속보 - 1018
// major 주요뉴스 - 1019

    if(category === '1001') { // 경제
        retuenStr = 'economic';
    } else if(category === '1002') { // 정치
        retuenStr = 'politics';
    } else if(category === '1003') { // 사회
        retuenStr = 'society';
    } else if(category === '1004') { // 연예
        retuenStr = 'entertain';
    } else if(category === '1005') { // 스포츠
        retuenStr = 'sports';
    } else if(category === '1006') { // 아이티
        retuenStr = 'digital';
    } else if(category === '1007') { // 야구
        retuenStr = 'baseball';
    } else if(category === '1008') { // 축구
        retuenStr = 'soccer';
    } else if(category === '1009') { // 생활
        retuenStr = 'life';
    } else if(category === '1010') { // 세계
        retuenStr = 'world';
    } else if(category === '1011') { // 헤드라인
        retuenStr = 'headline';
    } else if(category === '1012') { // 문화
        retuenStr = 'culture';
    } else if(category === '1013') { // 영상
        retuenStr = 'movie';
    } else if(category === '1014') { // 사진
        retuenStr = 'photo';
    } else if(category === '1015') { // 산업
        retuenStr = 'industry';
    } else if(category === '1016') { // 게임
        retuenStr = 'game';
    } else if(category === '1017') { // 사설
        retuenStr = 'editorial';
    } else if(category === '1018') { // 속보
        retuenStr = 'news_quick';
    } else if(category === '1019') { // 주요뉴스
        retuenStr = 'major';
    } else {
        retuenStr = 'main';
    }

    // console.debug(category, retuenStr);
    return retuenStr;
}

/**
 *
 * @param {*} delay
 *
 * 텀주기..
 */
export function sleep (delay) {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
}

/**
 *
 * @param {*} array
 *
 * 배열 랜덤...배열..
 */
export function shuffle(array) {
    let counter = array.length;

    // While there are elements in the array
    while (counter > 0) {
        // Pick a random index
        let index = Math.floor(Math.random() * counter);

        // Decrease counter by 1
        counter--;

        // And swap the last element with it
        let temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
    }

    return array;
}

/**
 *
 *
 * @param x
 * @returns {string}
 *
 * 금액에 3자리수 마다 , 찍기
 */
export function numberWithCommas(money) {
    return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 *
 *
 * @param x
 * @returns {string}
 *
 * 이메일 유효성 검사
 */
export const isEmail = email => {
    const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    return emailRegex.test(email);
  };
