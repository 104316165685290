import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Helper from 'lib/Helper';
import { Cookies } from 'react-cookie';
import { Swiper, Slide } from 'react-dynamic-swiper';
import 'react-dynamic-swiper/lib/styles.css';

import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';

import {
    getWeatherAddressResult,
    getWeatherHistoryList,
} from 'store/Actions';

const cookies = new Cookies();

export class WeatherDetailComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword : '',
            histories : (cookies.get('weatherHistory')) ? cookies.get('weatherHistory') : '',
        };
    }

    _getAddress = (e) => {
        this.state.keyword = e.target.value;

        let str = e.target.value;
        let regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;

        if(regExp.test(str)){
            str = str.replace(regExp, "");
        }
        this.props.getWeatherAddressResult(str);
    }

    _ctrHistory = (item) => {
        let getHistory = this.state.histories;

        if(getHistory.length > 4) {
            alert('지역 설정은 최대 5개 입니다.');
            return false;
        }

        if(getHistory === '') {
            item.idx = 0;
            this.setState({
                histories: [item]
            });
            cookies.set('weatherHistory', JSON.stringify([item]), { path: '/' });
        } else {
            /*getHistory.map((hitem, hi) => {
                if(hitem.mCode === item.mCode) {
                    getHistory.splice(hi, 1);
                }
            })*/
            /*getHistory.map((hitem, hi) => {
                hitem.idx = hi;
            })*/
            // getHistory = getHistory.concat(item);

            /*this.setState({
                histories: getHistory.map(
                    info => item.mCode === info.mCode
                    ? { ...info, ...item }
                    : info
                )
            })*/

            getHistory = getHistory.filter(info => info.mCode !== item.mCode);
            getHistory = getHistory.concat(item);
            item.idx = getHistory.length - 1;

            this.setState({
                histories: getHistory
            })

            cookies.set('weatherHistory', JSON.stringify(getHistory), { path: '/' });
        }

        cookies.set('cityCode', item.mCode, { path: '/' });
    }

    /* history */
    _moveDetail = (mCode, idx) => {
        cookies.set('cityCode', mCode, { path: '/' });
        cookies.set('cityIdx', idx, { path: '/' });
        window.location = '/weather/detail';
    }

    _getWeatherHistoryList = (str) => {
        this.props.getWeatherHistoryList(str);
    }

    loadHistoryPage  = (history) => {
        let histories = [];

        history.map((item, i) => {
            histories[item.idx] = item.mCode;
        })

        this._getWeatherHistoryList(JSON.stringify(histories));
    }

    deleteHistory  = (cityCode) => {
        let histories = this.state.histories,
            mhistories = [];
        histories = histories.filter(his => his.mCode !== cityCode);
        histories = histories.filter(his => his);

        if(histories.length > 0) {
            histories.map((item, i) => {
                item.idx = i;
                mhistories = mhistories.concat(item);
            })
        }

        mhistories = mhistories.filter(his => his);

        this.setState({
            histories: mhistories
        })

        cookies.set('weatherHistory', JSON.stringify(mhistories), { path: '/' });
    }
    /* history end */

    componentWillReceiveProps(nextProps) {
    }

    componentWillUpdate(nextProps, nextState) {
        // console.debug({name: 'Component WILL UPDATE!', nextProps: nextProps, nextState: nextState})
        if(this.state.histories !== nextState.histories) {
            this.loadHistoryPage(nextState.histories);
        }
    }

    componentDidUpdate() {
    }

    componentDidMount() {
        if(this.state.histories !== '') {
            this.loadHistoryPage(this.state.histories);
        }
    }

    render() {
        return (
            <div id="wrap">
                <div id="container">
                    <div className="weatherSearchBox">
                        <div><label>도시, 우편번호 또는 공항 위치 입력</label></div>
                        <div>
                            <span>
                                <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/search.svg'} alt="검색 아이콘"/>
                            </span>
                            <input type="text" value={this.state.keyword} onChange={this._getAddress} name="keyword" />
                            <a href="/weather/detail">취소</a>
                        </div>
                    </div>
                    <div className="weatherSearchResultBox">
                        <ul>
                            {this.props.weaterAddressResult ? this._renderList() : <li><a></a></li>}
                        </ul>
                        
                    </div>
                    <div className="weatherHistoryList">
                        {this.props.weaterHistoryList ? this._renderHistoryList() : <div></div>}
                    </div>
                </div>
            </div>
        );
    }

    _renderList = () => {
        let list = <li><a>검색어를 입력해주세요</a></li>;
        const data = this.props.weaterAddressResult;

        if (data.state){
            list = data.payload.map((item, i) => {
                return  <li key={i}>
                    <a onClick={() => this._ctrHistory(item)}>{item.city}</a>
                </li>
            })
        }
        return list;
    }
    _renderHistoryList = () => {
        let list = <li><a></a></li>;
        const data = this.props.weaterHistoryList;

        if (data.state){
            if(typeof data.payload !== 'undefined') {
                list = data.payload.map((item, i) => {
                    return  <div key={item.seq}>
                        <a className="historyBox" onClick={() => this._moveDetail(item.mCode, i)}>
                            <div>
                                <div>
                                    <p>{item.time}</p>
                                    <p>{item.city_str}</p>
                                </div>
                                <div>
                                    <p>{item.temp}°</p>
                                </div>
                            </div>
                        </a>
                        <button className="deleteHistoryList" onClick={() => this.deleteHistory(item.cityCode)}>
                            <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/x.svg'} alt="날씨 히스토리 삭제"/>
                        </button>
                    </div>
                })
            }
        }
        return list;
    }
};

WeatherDetailComponent.defaultProps = {
    weaterAddressResult: {
        state: false,
        payload : {}
    },
    weaterHistoryList: {
        state: false,
        payload : {}
    },
};

const mapStateToProps = state => ({
    weaterAddressResult: state.WEATHER.weaterAddressResult,
    weaterHistoryList: state.WEATHER.weaterHistoryList,
});

const mapDispatchToProps = {
    getWeatherAddressResult,
    getWeatherHistoryList,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(WeatherDetailComponent));
