import React, { Component } from 'react'
import { withGoogleMap, GoogleMap } from 'react-google-maps'
import { PlaceMarker } from './PlaceMarker'

const AirbnbMap = withGoogleMap(props => (
    <GoogleMap
        defaultCenter={props.center}
        center={props.center}
        defaultZoom={props.zoom}>
        {props.places}
    </GoogleMap>
));


const stateInitialize = {
    state: '',
};

export class GoogleMapComponent extends Component {
    constructor(props) {
        super(props)

        this.state = stateInitialize;
    }

    render() {
        // const {lat, lng} = this.state;
        // const places = [<PlaceMarker key={1} lat={lat} lng={lng} price={20} name={"Hotel"} description={"Hotel desc"} />]
// console.debug(this.props.MapInfo);

        const {latitude, longitude, name} = this.props.MapInfo;
        const places = <PlaceMarker lat={latitude} lng={longitude} price={18} name={name}/>

        // console.debug({
        //     name:'map',
        //     latitude:parseFloat(latitude),
        //     longitude:parseFloat(longitude),
        // })

        if(latitude) {
            return (
                <div style={{width: `100%`, height: `400px`}}>
                    <AirbnbMap
                        center={{
                            lat: parseFloat(latitude),
                            lng: parseFloat(longitude)
                        }}
                        places={places}
                        zoom={18}
                        containerElement={
                            <div style={{ height: `100%` }} />
                        }
                        mapElement={
                            <div style={{ height: `100%` }} />
                        }
                    />
                </div>
            );
        } else {
            return (
                <div style={{width: `100%`, height: `400px`}}>

                </div>
            );
        }


    }
}

export default GoogleMapComponent;