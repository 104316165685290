import React from "react";
import { Link } from "react-router-dom";
import * as Helper from 'lib/Helper'
import * as GlobalLink from 'lib/GlobalLink'
// import { GlobalLoading } from 'elements';


export const DefaultNewsListBox = ({
    CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }
    if(CONTENTS_LIST.state === true) {
        return (
            <div>
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        return (
                            // <li key={i}><Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.link))}  }>{item.title}</Link></li>
                            <li key={i}><Link to={{ pathname: '/news/content/'+item.sid }} >{item.title}</Link></li>
                        );
                    })
                }
            </div>
        );
    } else {
        return (
            <div>
            </div>
            // <GlobalLoading />
        );
    }


};

export const DefaultNewsRankBox = ({
    CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    return (
        <div>
            {
                ( CONTENTS_LIST.state === true ) && CONTENTS_LIST.payload.map((item, i) => {
                    return (
                        <li key={i}><Link to={{ pathname: '/news/content/'+item.sid }} ><span className="num">{i+1}</span>{item.title}</Link></li>
                    );
                })
            }
        </div>
    );
};


export const NewsCPMainNewListBox = ({
    CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    return (
        <div>
            {
                ( CONTENTS_LIST.state === true ) && CONTENTS_LIST.payload.map((item, i) => {
                    return (
                        <li key={i}>
                            <Link to={{ pathname: '/news/content/'+item.sid }} >
                                <span className="txt">{item.title}</span>
                                <span className="source" style={{'fontSize':'0.8em'}}>{item.provider_company}</span>
                            </Link>
                        </li>
                    );
                })
            }
        </div>
    );
};

// export const ShoppingBestReviewBox = ({
//     CONTENTS_LIST
// }) => {
//
//     const _handleContentLinkClick = (contents_link) => {
//         GlobalLink.SiteLogCheckURL({
//             url:contents_link
//         });
//     }
//
//     return (
//         <div>
//             {
//                 ( CONTENTS_LIST.state === true ) && CONTENTS_LIST.payload.map((item, i) => {
//                     return (
//                         <li key={i}>
//                             <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}  }>
//                                     <span className="ftColor1">{item.title_b}</span>{item.title_1}
//                             </Link>
//                         </li>
//                     );
//                 })
//             }
//         </div>
//     );
// };

export const ShoppingBestReviewBox = ({
  CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    if(CONTENTS_LIST.state === true) {

        return (
            <div>
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        if(i > 2) {
                            return (
                                <li key={i}>
                                    <Link to="/" onClick={(e) => {
                                        e.preventDefault();
                                        _handleContentLinkClick(encodeURIComponent(item.land_url))
                                    }}>
                                        <span className="ftColor1">{item.title_b}</span>{item.title_1}
                                    </Link>
                                </li>
                            );
                        } else {
                            return ('');
                        }
                    })



                }
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};



export const GameReviewBox = ({
    CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }

    return (
        <div>
            {
                ( CONTENTS_LIST.state === true ) && CONTENTS_LIST.payload.map((item, i) => {
                    return (
                        <li key={i}><Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.land_url))}  }><span className="txt">{Helper._StrReplaceAll(item.title,'\\\'','\'')}</span></Link></li>
                    );
                })
            }
        </div>
    );
};



export const NewsSubProviderBox = ({
    CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }
    if(CONTENTS_LIST.state === true) {
        return (
            <div>
                {
                    CONTENTS_LIST.payload.map((item, i) => {
                        return (
                            <li key={i}>
                                <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent(item.provider_link))}  }><img src={item.provider_icon} alt=""/>{item.provider_company}</Link>
                            </li>
                        );
                    })
                }
            </div>
        );
    } else {
        return (
            <div>
            </div>
            // <GlobalLoading />
        );
    }


};

export const NewsSubProviderBoxHardCoding = ({
   CONTENTS_LIST
}) => {

    const _handleContentLinkClick = (contents_link) => {
        GlobalLink.SiteLogCheckURL({
            url:contents_link
        });
    }
    // if(CONTENTS_LIST.state === true) {
        return (
            <div>
                <li>
                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent('http://www.khan.co.kr'))}  }>
                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/news/press/new_01.jpg'} alt="" />
                        경향신문
                    </Link>
                </li>
                <li>
                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent('http://www.hankyung.com'))}  }>
                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/news/press/new_02.jpg'} alt="" />
                        한국경제
                    </Link>
                </li>
                <li>
                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent('http://www.hani.co.kr'))}  }>
                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/news/press/new_03.jpg'} alt="" />
                        한겨레
                    </Link>
                </li>
                <li>
                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent('http://www.chosun.com'))}  }>
                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/news/press/new_04.jpg'} alt="" />
                        조선일보
                    </Link>
                </li>
                <li>
                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent('http://www.mt.co.kr'))}  }>
                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/news/press/new_05.jpg'} alt="" />
                        머니투데이
                    </Link>
                </li>
                <li>
                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent('http://www.hankyung.com'))}  }>
                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/news/press/new_06.jpg'} alt="" />
                        메일경제
                    </Link>
                </li>
                <li>
                    <Link to="/" onClick={ (e) => {e.preventDefault(); _handleContentLinkClick(encodeURIComponent('http://www.donga.com'))}  }>
                        <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/news/press/new_07.jpg'} alt="" />
                        동아일보
                    </Link>
                </li>
            </div>
        );
    // } else {
    //     return (
            /*<div>*/
            // </div>
            // <GlobalLoading />
        // );
    // }


};