import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import GoogleMapComponent from './GoogleMap/GoogleMapComponent';

import { getBrowserGeolocation, getGooglePlaceList } from 'store/Actions';
import {
    MapSectionList,
    // SyrupTableBanner,
    MobionBanner,
    InterWorksMediaBanner
} from 'elements';

const stateInitialize = {
    placeCategory: 'restaurant'
};

const mylocationImageStyle = {
    maxWidth: '2rem',
    position: 'relative',
    top: '0.7rem',
    left: '0.5rem',
}


const initDefaultMapInfo = {
    latitude: 37.49117428450753, 
    longitude: 127.0353309903365,
    name: '메타 큐브',
}

export class MapComponent extends Component {

    constructor(props) {

        super(props);

        this.state = stateInitialize;
    }

    _setLocationToStore= (location) => {
        this.props.getBrowserGeolocation({
            latitude: location.latitude,
            longitude: location.longitude,
            name: location.name,
        });
    }

    _setDefaultLocationToStore= () => {
        this.props.getBrowserGeolocation(initDefaultMapInfo);
    }

    findMyLocation = async () => {
        if (navigator.geolocation) {
            var getPosition = function (options) {
                return new Promise(function (resolve, reject) {
                    navigator.geolocation.getCurrentPosition(resolve, reject, options);
                });
            }

            getPosition().then((position) => {
                this._setLocationToStore({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    name: '',
                });
            }).catch((err) => {
                this._setDefaultLocationToStore();
            });
        } else {
            this._setDefaultLocationToStore();
        }
    }

    _getGooglePlaceList = () => {
        const postPayload = {
            latitude: this.props.nowGeolocation.latitude,
            longitude: this.props.nowGeolocation.longitude,
            placeCategory: this.state.placeCategory
        }

        this.props.getGooglePlaceList(postPayload);
    }
    _handleSectionClick = (e) => {
        // const mapInfo = {
        //     latitude: e.target.parentElement.getAttribute('latitude'),
        //     longitude: e.target.parentElement.getAttribute('longitude'),
        //     name: e.target.parentElement.getAttribute('name')
        // };

        // console.debug(e);

        const mapInfo = {
            latitude: e.latitude,
            longitude: e.longitude,
            name: e.name
        };

        this._setLocationToStore(mapInfo);
        window.scrollTo(0, 0)
    }

    _handelMylocationImageCLick = () => {
        this.findMyLocation();
    }

    _handelPlaceCategoryClick = (e) => {
        const placeCategory = e.target.parentElement.getAttribute('placecategory');

        if(placeCategory === 'more') {

        } else {
            const postPayload = {
                latitude: this.props.nowGeolocation.latitude,
                longitude: this.props.nowGeolocation.longitude,
                placeCategory: placeCategory
            }

            this.props.getGooglePlaceList(postPayload);
        }

        this.setState({
            placeCategory: placeCategory
        });

    }

    componentWillMount() {
        this.findMyLocation();
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        // this.findMyLocation();
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.nowGeolocation !== this.props.nowGeolocation && this.props.nowGeolocation.latitude) {
            this._getGooglePlaceList();
        }
    }

    render() {

        return (
            <div id="wrap">

                <div id="container">
                    <div className="map">

                        <GoogleMapComponent MapInfo = {this.props.nowGeolocation}/>

                        <nav className="contentNav" style={{position:'relative'}}>

                            <ul className="dp1">
                                {/* <li className={this.state.placeCategory === 'restaurant' ? 'active' : ''} onClick={this._handelPlaceCategoryClick} placecategory="restaurant"><Link to="/" onClick={ (e) => e.preventDefault() }>맛집</Link></li> */}
                                <li className={this.state.placeCategory === 'restaurant' ? 'active' : ''} onClick={this._handelMylocationImageCLick} placecategory="restaurant" style={{width: '15%'}}>
                                    <img src={process.env.REACT_APP_PRODUCTION_RESOURCE_URL+'/resource/images/icon/mylocation.png'} alt="mylocation" style={mylocationImageStyle}/>
                                </li>
                                <li className={this.state.placeCategory === 'cafe' ? 'active' : ''} onClick={this._handelPlaceCategoryClick} placecategory="cafe"><Link to="/" onClick={ (e) => e.preventDefault() }>카페</Link></li>
                                <li className={this.state.placeCategory === 'gas_station' ? 'active' : ''} onClick={this._handelPlaceCategoryClick} placecategory="gas_station"><Link to="/" onClick={ (e) => e.preventDefault() }>주유소</Link></li>
                                <li className={this.state.placeCategory === 'parking' ? 'active' : ''} onClick={this._handelPlaceCategoryClick} placecategory="parking"><Link to="/" onClick={ (e) => e.preventDefault() }>주차장</Link></li>
                                <li className={this.state.placeCategory === 'car_wash' ? 'active' : ''} onClick={this._handelPlaceCategoryClick} placecategory="car_washc5"><Link to="/" onClick={ (e) => e.preventDefault() }>세차장</Link></li>
                                <li className={this.state.placeCategory === 'car_repair' ? 'active' : ''} onClick={this._handelPlaceCategoryClick} placecategory="car_repair"><Link to="/" onClick={ (e) => e.preventDefault() }>정비소</Link></li>
                            </ul>

                        </nav>
                    </div>

                    {/* <div className="banner"> */}
                        {/*<MobionBanner
                            BANNER_TYPE="MAP"
                        />*/}
                        {/* <InterWorksMediaBanner /> */}
                    {/* </div> */}

                    <div className="section">
                        <ul className="mapList">
                        {
                            this.props.googlePlaceList && (
                            <MapSectionList
                                SECTION_CLICK={this._handleSectionClick}
                                SECTION_LIST={this.props.googlePlaceList}
                            />
                            )
                        }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
};

MapComponent.defaultProps = {
    nowGeolocation: initDefaultMapInfo,
    googlePlaceList : {}
};

const mapStateToProps = state => ({
    nowGeolocation : state.MAP.nowGeolocation,
    googlePlaceList : state.MAP.googlePlaceList
});

const mapDispatchToProps = {
    getBrowserGeolocation,
    getGooglePlaceList
};



export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MapComponent));
