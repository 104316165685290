import { combineReducers } from 'redux'
import initialReduxState from './initialReduxState';
import * as ActionTypes from './ActionTypes';

const MapReducer = (state = initialReduxState.MAP, action) => {
    switch (action.type) {

        case ActionTypes.GET_BROWSER_GEOLOCATION:
            return { ...state, nowGeolocation : { state:false } };
        case ActionTypes.SUCCEEDED_BROWSER_GEOLOCATION:
            return { ...state, nowGeolocation : action.payload };
        case ActionTypes.FAILED_BROWSER_GEOLOCATION:
                return { ...state, nowGeolocation : action.payload };

        case ActionTypes.GET_GOOGLE_PLACE_LIST:
            return { ...state, googlePlaceList : { state:false } };
        case ActionTypes.SUCCEEDED_GOOGLE_PLACE_LIST:
            return { ...state, googlePlaceList : action.payload };
        case ActionTypes.FAILED_GOOGLE_PLACE_LIST:
            return { ...state, googlePlaceList : action.payload };
        default:
            return state;
    }
};

/* new work */
/* 인증번호 */
/* 발송 */
const CertificationReducer = (state = initialReduxState.CERTIFICATION, action) => {
    switch (action.type) {
        case ActionTypes.TRY_CERTIFICATION:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_CERTIFICATION:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_CERTIFICATION:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 확인 */
const ConfirmReducer = (state = initialReduxState.CONFIRM, action) => {
    switch (action.type) {
        case ActionTypes.TRY_CONFIRM:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_CONFIRM:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_CONFIRM:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 인증번호 end */

/* 전화번호 로그서치 */
const LogPhoneNumReducer = (state = initialReduxState.LOG_PHONE, action) => {
    switch (action.type) {
        case ActionTypes.GET_LOG_PHONE:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_LOG_PHONE:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_LOG_PHONE:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 전화번호 로그서치 end */
/* 브라우저 토큰 */
const BrowserTokenReducer = (state = initialReduxState.BROWSER_TOKEN, action) => {
    switch (action.type) {
        case ActionTypes.GET_BROWSER_TOKEN:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_BROWSER_TOKEN:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_BROWSER_TOKEN:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 브라우저 토큰 end */
/* 평범한 회원가입 */
const DefaultJoinMemberReducer = (state = initialReduxState.DEFAULT_JOIN_MEMBER, action) => {
    switch (action.type) {
        case ActionTypes.TRY_DEFAULT_JOIN_MEMBER:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_DEFAULT_JOIN_MEMBER:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_DEFAULT_JOIN_MEMBER:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 평범한 회원가입 end */
/* 회원가입 */
const JoinMemberReducer = (state = initialReduxState.JOIN_MEMBER, action) => {
    switch (action.type) {
        case ActionTypes.TRY_JOIN_MEMBER:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_JOIN_MEMBER:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_JOIN_MEMBER:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 회원가입 end */
/* 회원탈퇴 */
const WithdrawalReducer = (state = initialReduxState.WITHDRAWAL, action) => {
    switch (action.type) {
        case ActionTypes.TRY_WITHDRAWAL:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_WITHDRAWAL:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_WITHDRAWAL:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 회원탈퇴 end */
/* 비밀번호 변경 mypage */
const UserChangePwReducer = (state = initialReduxState.USER_CHANGE_PW, action) => {
    switch (action.type) {
        case ActionTypes.TRY_USER_CHANGE_PW:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_USER_CHANGE_PW:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_USER_CHANGE_PW:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 비밀번호 변경 end */
/* 비밀번호 변경 findpw */
const DefaultChangePwReducer = (state = initialReduxState.DEFAULT_CHANGE_PW, action) => {
    switch (action.type) {
        case ActionTypes.TRY_DEFAULT_CHANGE_PW:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_DEFAULT_CHANGE_PW:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_DEFAULT_CHANGE_PW:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 비밀번호 변경 end */
/* 비밀번호 체크 */
const PasswordCheckReducer = (state = initialReduxState.PASSWORD_CHECK, action) => {
    switch (action.type) {
        case ActionTypes.TRY_PASSWORD_CHECK:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_PASSWORD_CHECK:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_PASSWORD_CHECK:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 비밀번호 체크 end */
/* 로그인 */
const LoginUserReducer = (state = initialReduxState.LOGIN_USER, action) => {
    switch (action.type) {
        case ActionTypes.TRY_LOGIN_USER:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_LOGIN_USER:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_LOGIN_USER:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 로그인 end */
/* 내정보 */
const UserMyinfoReducer = (state = initialReduxState.USER_MYINFO, action) => {
    switch (action.type) {
        case ActionTypes.GET_USER_MYINFO:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_USER_MYINFO:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_USER_MYINFO:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 내정보 end */
/* 포인트 충전,사용 내역 */
const UserMypointReducer = (state = initialReduxState.USER_MYPOINT, action) => {
    switch (action.type) {
        case ActionTypes.GET_USER_MYPOINT:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_USER_MYPOINT:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_USER_MYPOINT:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 포인트 충전,사용 내역 end */

/* 포인트 충전,사용 내역 */
const OneononeListReducer = (state = initialReduxState.ONE_INQUIRT, action) => {
    switch (action.type) {
        case ActionTypes.GET_ONE_INQUIRT:
            return { ...state, state:false, payload: {} };
        case ActionTypes.SUCCEEDED_ONE_INQUIRT:
            return { ...state, state:true, payload: action.payload };
        case ActionTypes.FAILED_ONE_INQUIRT:
            return { ...state, state:false, payload: {} };

        default:
            return state;
    }
};
/* 포인트 충전,사용 내역 end */

const CarReducer = (state = initialReduxState.CAR, action) => {
    switch (action.type) {

        case ActionTypes.GET_CAR_SECTION1:
            return { ...state, carSection1 : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_CAR_SECTION1:
            return { ...state, carSection1 : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_CAR_SECTION1:
            return { ...state, carSection1 : { state:false, payload: {} } };

        case ActionTypes.GET_CAR_SECTION2:
            return { ...state, carSection2 : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_CAR_SECTION2:
            return { ...state, carSection2 : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_CAR_SECTION2:
            return { ...state, carSection2 : { state:false, payload: {} } };

        case ActionTypes.GET_CAR_SECTION3:
            return { ...state, carSection3 : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_CAR_SECTION3:
            return { ...state, carSection3 : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_CAR_SECTION3:
            return { ...state, carSection3 : { state:false, payload: {} } };

        case ActionTypes.GET_CAR_SECTION4:
            return { ...state, carSection4 : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_CAR_SECTION4:
            return { ...state, carSection4 : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_CAR_SECTION4:
            return { ...state, carSection4 : { state:false, payload: {} } };

        case ActionTypes.GET_CAR_SECTION5:
            return { ...state, carSection5 : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_CAR_SECTION5:
            return { ...state, carSection5 : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_CAR_SECTION5:
            return { ...state, carSection5 : { state:false, payload: {} } };

        case ActionTypes.GET_CAR_SECTION_BENNER:
            return { ...state, carBenner : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_CAR_SECTION_BENNER:
            return { ...state, carBenner : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_CAR_SECTION_BENNER:
            return { ...state, carBenner : { state:false, payload: {} } };

        default:
            return state;
    }
};

const WeaterReducer = (state = initialReduxState.WEATHER, action) => {
    switch (action.type) {
        case ActionTypes.GET_WEATHER_DETAIL:
            return { ...state, weaterWeekDetail : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_WEATHER_DETAIL:
            return { ...state, weaterWeekDetail : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_WEATHER_DETAIL:
            return { ...state, weaterWeekDetail : { state:false, payload: {} } };
        //----------------------------------------
        case ActionTypes.GET_WEATHER_WEEK:
            return { ...state, weaterWeek : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_WEATHER_WEEK:
            return { ...state, weaterWeek : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_WEATHER_WEEK:
            return { ...state, weaterWeek : { state:false, payload: {} } };
        //----------------------------------------
        case ActionTypes.GET_WEATHER_HOUR:
            return { ...state, weaterHour : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_WEATHER_HOUR:
            return { ...state, weaterHour : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_WEATHER_HOUR:
            return { ...state, weaterHour : { state:false, payload: {} } };
        //----------------------------------------
        case ActionTypes.GET_WEATHER_ADDRESS_RESULT:
            return { ...state, weaterAddressResult : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_WEATHER_ADDRESS_RESULT:
            return { ...state, weaterAddressResult : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_WEATHER_ADDRESS_RESULT:
            return { ...state, weaterAddressResult : { state:false, payload: {} } };
        //----------------------------------------
        case ActionTypes.GET_WEATHER_HISTORY_LIST:
            return { ...state, weaterHistoryList : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_WEATHER_HISTORY_LIST:
            return { ...state, weaterHistoryList : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_WEATHER_HISTORY_LIST:
            return { ...state, weaterHistoryList : { state:false, payload: {} } };
        //----------------------------------------

        default:
            return state;
    }
};
/* new work end */

const FeedReducer = (state = initialReduxState.FEED, action) => {
    switch (action.type) {
        /* new work */
        case ActionTypes.GET_FEED_WEATHER:
            return { ...state, feedWeaterBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_FEED_WEATHER:
            return { ...state, feedWeaterBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_FEED_WEATHER:
            return { ...state, feedWeaterBox : { state:false, payload: {} } };
        /* new work end */

        case ActionTypes.GET_FEED_TOP_LIST:
            return { ...state, feedTop : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_FEED_TOP_LIST:
            return { ...state, feedTop : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_FEED_TOP_LIST:
            return { ...state, feedTop : { state:false, payload: {} } };

        case ActionTypes.GET_FEED_TOP_PHOTO_LIST:
            return { ...state, feedTopPhoto : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_FEED_TOP_PHOTO_LIST:
            return { ...state, feedTopPhoto : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_FEED_TOP_PHOTO_LIST:
            return { ...state, feedTopPhoto : { state:false, payload: {} } };

        case ActionTypes.GET_FEED_CENTER_BOX_LIST:
            return { ...state, feedBoxCenterBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_FEED_CENTER_BOX_LIST:
            return { ...state, feedBoxCenterBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_FEED_CENTER_BOX_LIST:
            return { ...state, feedBoxCenterBox : { state:false, payload: {} } };

        case ActionTypes.GET_FEED_CENTER_GAME_MD_BOX_LIST:
            return { ...state, feedCenterGameMdBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_FEED_CENTER_GAME_MD_BOX_LIST:
            return { ...state, feedCenterGameMdBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_FEED_CENTER_GAME_MD_BOX_LIST:
            return { ...state, feedCenterGameMdBox : { state:false, payload: {} } };

        case ActionTypes.GET_FEED_TODAY_TREND_BOX_LIST:
            return { ...state, feedTodayTrendBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_FEED_TODAY_TREND_BOX_LIST:
            return { ...state, feedTodayTrendBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_FEED_TODAY_TREND_BOX_LIST:
            return { ...state, feedTodayTrendBox : { state:false, payload: {} } };

        case ActionTypes.GET_FEED_TODAY_TREND_FOOT_BOX_LIST:
            return { ...state, feedTodayFootBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_FEED_TODAY_TREND_FOOT_BOX_LIST:
            return { ...state, feedTodayFootBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_FEED_TODAY_TREND_FOOT_BOX_LIST:
            return { ...state, feedTodayFootBox : { state:false, payload: {} } };

        case ActionTypes.GET_FEED_TOP_KEYWORD_LIST:
            return { ...state, feedTopKeyWordList : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_FEED_TOP_KEYWORD_LIST:
            return { ...state, feedTopKeyWordList : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_FEED_TOP_KEYWORD_LIST:
            return { ...state, feedTopKeyWordList : { state:false, payload: {} } };

       case ActionTypes.GET_FEED_CENTER_GAME_N_BOX_LIST:
           return { ...state, feedCenterGameNBox : { state:false, payload: {} } };
       case ActionTypes.SUCCEEDED_FEED_CENTER_GAME_N_BOX_LIST:
           return { ...state, feedCenterGameNBox : { state:true, payload: action.payload } };
       case ActionTypes.FAILED_FEED_CENTER_GAME_N_BOX_LIST:
           return { ...state, feedCenterGameNBox : { state:false, payload: {} } };

        default:
            return state;
    }
};


const NewsMainReducer = (state = initialReduxState.NEWS_MAIN, action) => {
    switch (action.type) {
        case ActionTypes.GET_NEWS_MAIN_TOP_BULLETIN:
            return { ...state, topBulletin : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_NEWS_MAIN_TOP_BULLETIN:
            return { ...state, topBulletin : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_NEWS_MAIN_TOP_BULLETIN:
            return { ...state, topBulletin : { state:false, payload: {} } };

        case ActionTypes.GET_NEWS_MAIN_TOP_BOX:
            return { ...state, topBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_NEWS_MAIN_TOP_BOX:
            return { ...state, topBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_NEWS_MAIN_TOP_BOX:
            return { ...state, topBox : { state:false, payload: {} } };

        case ActionTypes.GET_NEWS_MAIN_TOP_PHOTO_BOX:
            return { ...state, topPhotoBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_NEWS_MAIN_TOP_PHOTO_BOX:
            return { ...state, topPhotoBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_NEWS_MAIN_TOP_PHOTO_BOX:
            return { ...state, topPhotoBox : { state:false, payload: {} } };

        case ActionTypes.GET_NEWS_MAIN_CENTER_BOX:
            return { ...state, centerNewsBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_NEWS_MAIN_CENTER_BOX:
            return { ...state, centerNewsBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_NEWS_MAIN_CENTER_BOX:
            return { ...state, centerNewsBox : { state:false, payload: {} } };

        case ActionTypes.GET_NEWS_MAIN_CENTER_HEADLINE:
            return { ...state, headLineNewsBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_NEWS_MAIN_CENTER_HEADLINE:
            return { ...state, headLineNewsBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_NEWS_MAIN_CENTER_HEADLINE:
            return { ...state, headLineNewsBox : { state:false, payload: {} } };

        case ActionTypes.GET_NEWS_MAIN_TODAY_NEWS:
            return { ...state, todayNewsBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_NEWS_MAIN_TODAY_NEWS:
            return { ...state, todayNewsBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_NEWS_MAIN_TODAY_NEWS:
            return { ...state, todayNewsBox : { state:false, payload: {} } };

        default:
            return state;
    }
};

const ShoppingReducer = (state = initialReduxState.SHOPPING, action) => {
    switch (action.type) {

        case ActionTypes.GET_SHOPPING_TODAY_TREND:
            return { ...state, todayTrend : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_SHOPPING_TODAY_TREND:
            return { ...state, todayTrend : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_SHOPPING_TODAY_TREND:
            return { ...state, todayTrend : { state:false, payload: {} } };

        case ActionTypes.GET_SHOPPING_TODAY_TREND_FOOT:
            return { ...state, todayTrendFoot : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_SHOPPING_TODAY_TREND_FOOT:
            return { ...state, todayTrendFoot : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_SHOPPING_TODAY_TREND_FOOT:
            return { ...state, todayTrendFoot : { state:false, payload: {} } };


        case ActionTypes.GET_SHOPPING_MD_RECOMMEND:
            return { ...state, mdRecommend : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_SHOPPING_MD_RECOMMEND:
            return { ...state, mdRecommend : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_SHOPPING_MD_RECOMMEND:
            return { ...state, mdRecommend : { state:false, payload: {} } };


        case ActionTypes.GET_SHOPPING_BEST_REVIEW:
            return { ...state, bestReview : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_SHOPPING_BEST_REVIEW:
            return { ...state, bestReview : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_SHOPPING_BEST_REVIEW:
            return { ...state, bestReview : { state:false, payload: {} } };

        case ActionTypes.GET_SHOPPING_BEST_REVIEW_FOOT_BOX:
            return { ...state, bestReviewFootBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_SHOPPING_BEST_REVIEW_FOOT_BOX:
            return { ...state, bestReviewFootBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_SHOPPING_BEST_REVIEW_FOOT_BOX:
            return { ...state, bestReviewFootBox : { state:false, payload: {} } };


        case ActionTypes.GET_SHOPPING_FASHION_BOX:
            return { ...state, fashionBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_SHOPPING_FASHION_BOX:
            return { ...state, fashionBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_SHOPPING_FASHION_BOX:
            return { ...state, fashionBox : { state:false, payload: {} } };

        case ActionTypes.GET_SHOPPING_FASHION_BOX_FOOT:
            return { ...state, fashionBoxFoot : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_SHOPPING_FASHION_BOX_FOOT:
            return { ...state, fashionBoxFoot : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_SHOPPING_FASHION_BOX_FOOT:
            return { ...state, fashionBoxFoot : { state:false, payload: {} } };

        case ActionTypes.GET_SHOPPING_TECH_IT_BOX:
            return { ...state, techItBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_SHOPPING_TECH_IT_BOX:
            return { ...state, techItBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_SHOPPING_TECH_IT_BOX:
            return { ...state, techItBox : { state:false, payload: {} } };

        case ActionTypes.GET_SHOPPING_RECOMMEND_APP:
            return { ...state, recommendApp : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_SHOPPING_RECOMMEND_APP:
            return { ...state, recommendApp : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_SHOPPING_RECOMMEND_APP:
            return { ...state, recommendApp : { state:false, payload: {} } };


        case ActionTypes.GET_SHOPPING_TOP_BNNER_BOX:
            return { ...state, topBannerBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_SHOPPING_TOP_BNNER_BOX:
            return { ...state, topBannerBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_SHOPPING_TOP_BNNER_BOX:
            return { ...state, topBannerBox : { state:false, payload: {} } };


        case ActionTypes.GET_SHOPPING_FOOT_BNNER_BOX:
            return { ...state, footBannerBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_SHOPPING_FOOT_BNNER_BOX:
            return { ...state, footBannerBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_SHOPPING_FOOT_BNNER_BOX:
            return { ...state, footBannerBox : { state:false, payload: {} } };

        default:
            return state;
    }
};


const GameReducer = (state = initialReduxState.GAME, action) => {
    switch (action.type) {

        case ActionTypes.GET_GAME_MD_RECOMMEND:
            return { ...state, topMDRecommend : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_GAME_MD_RECOMMEND:
            return { ...state, topMDRecommend : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_GAME_MD_RECOMMEND:
            return { ...state, topMDRecommend : { state:false, payload: {} } };

        case ActionTypes.GET_GAME_NEW_GAME_BOX:
            return { ...state, newGameBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_GAME_NEW_GAME_BOX:
            return { ...state, newGameBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_GAME_NEW_GAME_BOX:
            return { ...state, newGameBox : { state:false, payload: {} } };

        case ActionTypes.GET_GAME_OFFICAL_BOX:
            return { ...state, officalBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_GAME_OFFICAL_BOX:
            return { ...state, officalBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_GAME_OFFICAL_BOX:
            return { ...state, officalBox : { state:false, payload: {} } };

        case ActionTypes.GET_GAME_NEWS_BOX:
            return { ...state, newsBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_NEWS_BOX:
            return { ...state, newsBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_GAME_NEWS_BOX:
            return { ...state, newsBox : { state:false, payload: {} } };

        case ActionTypes.GET_GAME_REVIEW_BOX:
            return { ...state, reviewBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_REVIEW_BOX:
            return { ...state, reviewBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_GAME_REVIEW_BOX:
            return { ...state, reviewBox : { state:false, payload: {} } };

        case ActionTypes.GET_GAME_TOP_BANNER_BOX:
            return { ...state, topBannerBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_GAME_TOP_BANNER_BOX:
            return { ...state, topBannerBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_GAME_TOP_BANNER_BOX:
            return { ...state, topBannerBox : { state:false, payload: {} } };

        case ActionTypes.GET_GAME_FOOT_BANNER_BOX:
            return { ...state, footBannerBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_GAME_FOOT_BANNER_BOX:
            return { ...state, footBannerBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_GAME_FOOT_BANNER_BOX:
            return { ...state, footBannerBox : { state:false, payload: {} } };

        default:
            return state;
    }
};


const NewsSubReducer = (state = initialReduxState.NEWS_SUB, action) => {
    switch (action.type) {

        case ActionTypes.GET_NEWS_SUB_TOP_PHOTOBOX:
            return { ...state, topPhotoBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_NEWS_SUB_TOP_PHOTOBOX:
            return { ...state, topPhotoBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_NEWS_SUB_TOP_PHOTOBOX:
            return { ...state, topPhotoBox : { state:false, payload: {} } };

        case ActionTypes.GET_NEWS_SUB_FOOT_CATEGORY_PHOTOBOX:
            return { ...state, footPhotoBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_NEWS_FOOT_SUB_CATEGORY_PHOTOBOX:
            return { ...state, footPhotoBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_NEWS_SUB_FOOT_CATEGORY_PHOTOBOX:
            return { ...state, footPhotoBox : { state:false, payload: {} } };

        case ActionTypes.GET_NEWS_SUB_NEWS_PROVIDER:
            return { ...state, footNewsProvider : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_NEWS_FOOT_SUB_NEWS_PROVIDER:
            return { ...state, footNewsProvider : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_NEWS_SUB_FOOT_NEWS_PROVIDER:
            return { ...state, footNewsProvider : { state:false, payload: {} } };

        case ActionTypes.GET_NEWS_SUB_CONTENT:
            return { ...state, NewsContentBox : { state:false, payload: {} } };
        case ActionTypes.SUCCEEDED_NEWS_SUB_CONTENT:
            return { ...state, NewsContentBox : { state:true, payload: action.payload } };
        case ActionTypes.FAILED_NEWS_SUB_CONTENT:
            return { ...state, NewsContentBox : { state:false, payload: {} } };


        default:
            return state;
    }
};


const InquiryCreateReducer = (state = initialReduxState.INQUIRY_CREATE, action) => {
    switch (action.type) {
        case ActionTypes.TRY_INQUIRY_CREATE:
            return { ...state, status: false  };
        case ActionTypes.SUCCEEDED_INQUIRY_CREATE:
            return { ...state, status : true, data : action.payload };
        case ActionTypes.FAILED_INQUIRY_CREATE:
            return { ...state, status : false };
        default:
            return state;
    }
};


const RootReducer = combineReducers({
    NEWS_SUB: NewsSubReducer,
    MAP : MapReducer,
    FEED : FeedReducer,
    NEWS_MAIN: NewsMainReducer,
    SHOPPING: ShoppingReducer,
    GAME: GameReducer,
    INQUIRY_CREATE: InquiryCreateReducer,
    WEATHER : WeaterReducer,
    CAR: CarReducer,


    CERTIFICATION: CertificationReducer,
    CONFIRM: ConfirmReducer,

    LOG_PHONE: LogPhoneNumReducer,
    BROWSER_TOKEN: BrowserTokenReducer,
    DEFAULT_JOIN_MEMBER: DefaultJoinMemberReducer,
    JOIN_MEMBER: JoinMemberReducer,
    WITHDRAWAL: WithdrawalReducer,

    USER_CHANGE_PW: UserChangePwReducer,
    DEFAULT_CHANGE_PW: DefaultChangePwReducer,
    PASSWORD_CHECK: PasswordCheckReducer,

    LOGIN_USER: LoginUserReducer,
    USER_MYINFO: UserMyinfoReducer,
    USER_MYPOINT: UserMypointReducer,

    ONE_INQUIRT: OneononeListReducer,
});
export default RootReducer;
